import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import moment from 'moment';
import { PROPERTY_STATUS_TEXT, STORAGE_KEYS } from '@/utils/consts';
import every from 'lodash/every';

export function userIsSuperAdmin(user) {
    return user?.roles?.includes('ROLE_SUPER_ADMIN') || false;
}

export function userIsCompanyAdmin(user) {
    return user?.roles?.includes('ROLE_COMPANY_ADMIN') || false;
}

export function clean(obj) {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
}

export function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

//diffs two objects
export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }

    return changes(object, base);
}


export function sortPropertyInterestsByArchived(a, b) {
    let aArchived = a.archived;
    let bArchived = b.archived;

    if (!aArchived && bArchived) {
        return -1;
    }
    if (!bArchived && aArchived) {
        return 1;
    }

    // a must be equal to b
    return 0;
}


export function sortByCreatedAt(a, b) {
    const fCreatedAt = moment(a?.created_at || 0).unix();
    const sCreatedAt = moment(b?.created_at || 0).unix();

    return sCreatedAt - fCreatedAt;
}

export function sortByLastMessageCreatedAt(firstLead, secondLead) {
    const fCreatedAt = moment(firstLead.last_message?.created_at || firstLead.created_at || 0).unix();
    const sCreatedAt = moment(secondLead.last_message?.created_at || secondLead.created_at || 0).unix();

    return sCreatedAt - fCreatedAt;
}


export function getFullNameFromParts(customer) {
    return [customer.first_name, customer.middle_name, customer.last_name].filter(v => !!v).join(' ');
}

export function getDeepProp(obj, props) {
    return props.split('.').reduce(function (acc, p) {
        if (acc == null) return;
        return acc[p];
    }, obj);
}

export function splitNameIntoParts(name) {
    const parts = name.trim().split(' ');

    switch (parts.length) {
        case 0:
            return {
                first_name: null,
                middle_name: null,
                last_name: null,
            };
        case 1: {
            let first_name = parts[0].trim();

            return {
                first_name: first_name !== '' ? first_name : null,
                middle_name: null,
                last_name: null,
            };
        }
        case 2: {
            let first_name = parts[0].trim();
            let last_name = parts[1].trim();

            return {
                first_name: first_name !== '' ? first_name : null,
                middle_name: null,
                last_name: last_name !== '' ? last_name : null,
            };
        }
        default: {
            let first_name = parts[0].trim();
            let middle_name = parts.slice(1, parts.length - 1).join(' ').trim();
            let last_name = parts[parts.length - 1].trim();

            return {
                first_name: first_name !== '' ? first_name : null,
                middle_name: middle_name !== '' ? middle_name : null,
                last_name: last_name !== '' ? last_name : null,
            };
        }
    }
}

export function filterProperties(property, text) {
    const needle = text.toLowerCase();

    if (property.name.toLowerCase().includes(needle)) {
        return true;
    }

    if (property.keyword?.name?.toLowerCase().includes(needle)) {
        return true;
    }

    if (mergeName(property.agent).toLowerCase().includes(needle)) {
        return true;
    }

    if (PROPERTY_STATUS_TEXT[getActivePropertyStatus(property.status)]?.toLowerCase().includes(needle)) {
        return true;
    }

    if (property.note?.toLowerCase().includes(needle)) {
        return true;
    }

    return false;
}


export function showAreYouSureSwal(swal, options) {
    const defaultOptions = {
        title: 'Are you sure?',
        text: 'Are you sure you want to do this ?',
    };

    const actualOptions = Object.assign(defaultOptions, options);

    return swal({
        title: actualOptions.title,
        text: actualOptions.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F64B5D',
        cancelButtonColor: '#d8d8d8',
        confirmButtonText: 'Confirm',
        reverseButtons: true,
    });
}

export function isEmptyObject(obj) {
    if (!obj) return true;

    return JSON.stringify(obj) === '{}';
}

export function mergeName(user) {
    return `${user.first_name} ${user.last_name}`;
}

export function tryToParseImpersonationFromLocalStorage() {
    const value = localStorage.getItem(STORAGE_KEYS.IMPERSONATE);

    if (value) {
        return JSON.parse(value);
    }

    return null;
}

export function isRouteValidForUser(user, route) {
    if (route.meta && Object.keys(route.meta).length > 0) {
        // we have checks to do
        let checks = [];

        if (route.meta.requiresSuperAdmin) {
            // we have to be super admin
            checks.push(userIsSuperAdmin(user));
        }

        if (route.meta.requiresCompanyAdmin) {
            checks.push(userIsCompanyAdmin(user));
        }

        return every(checks);
    } else {
        // nothing to check, render it
        return true;
    }
}

export function getActivePropertyStatus(statuses) {
    return statuses?.[statuses.length - 1]?.status;
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
