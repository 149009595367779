<template>
    <transition appear
                :name="isOutgoing ? 'slide-msg-left' : 'slide-msg-right'">
        <div class="message-container" v-bind:class="{
            incoming: isIncoming,
            outgoing: isOutgoing,
            system: isSystem
        }">
            <div v-if="isIncoming" class="message-creator">
                {{ selectedLead | mergeName }}
            </div>
            <div v-if="isOutgoing" class="message-creator">
                {{ messageAgent | mergeName }}
            </div>
            <div v-if="isSystem" class="message-creator">
                SYSTEM
            </div>
            <div class="message-bubble" v-bind:class="{
                incoming: isIncoming,
                outgoing: isOutgoing,
                error: isError,
                system: isSystem,
                'has-text': hasText,
                'has-attachment': hasAttachment
            }">

                <div class="attachment-preview" v-if="hasAttachment">
                    <div class="image-preview" v-if="isImgAttach">
                        <img v-viewer="{rotatable: false, scalable: false}" :src="attachUrl"/>
                    </div>
                    <div class="video-preview" v-if="isVideoAttach">
                        <video controls>
                            <source :src="attachUrl"/>
                        </video>
                    </div>
                    <div class="pdf-preview" v-if="isPdfAttach">
                        <a :href="attachUrl" target="_blank" class="pdf-icon">
                            <i class="icon icon-pdf_light"/>
                        </a>
                        <div class="attachment-url">
                            <p>{{ attachmentFilename }}</p>
                        </div>
                    </div>
                </div>

                <div class="message-content">
                    <span v-linkified>{{ message.text }}</span>
                </div>
            </div>
            <div class="message-metadata-container">
                <span v-if="shouldShowStatus" class="message-status" :class="{error: isError}">
                    {{ message.status }}
                </span>
                <span v-if="shouldShowStatus" class="message-dash">
                    -
                </span>
                <span class="message-sent-at">
                    {{ message.created_at | date_short_if_today }}
                </span>
            </div>
        </div>
    </transition>
</template>

<script>
import { storage_url } from '@/api/apiCall';

export default {
    name: 'Message',
    props: {
        message: Object,
    },
    computed: {
        messageAgent() {
            return this.message.agent || {};
        },
        shouldShowStatus() {
            return this.isOutgoing || this.isSystem;
        },
        isIncoming() {
            return this.message.direction === 'INBOUND' && !this.isSystem;
        },
        isOutgoing() {
            return this.message.direction === 'OUTBOUND' && !this.isSystem;
        },
        isSystem() {
            return this.message.type === 'system';
        },
        isError() {
            return this.message.status === 'failed' || this.message.status === 'undelivered';
        },
        selectedLead() {
            return this.$store.getters.selectedLead;
        },
        user() {
            return this.$store.state.login.user;
        },
        hasAttachment() {
            return this.message.attachment !== null;
        },
        hasText() {
            return this.message.text !== null && this.message.text.length > 0;
        },
        attachmentFilename() {
            return this.message.attachment?.original?.replace('/content/', '');
        },
        isVideoAttach() {
            return this.message.attachment?.type === 'video';
        },
        isPdfAttach() {
            return this.message.attachment?.type === 'pdf';
        },
        isImgAttach() {
            return this.message.attachment?.type === 'image';
        },
        attachUrl() {
            return `${storage_url}${this.message.attachment.original}`;
        },
    },
    mounted() {
        this.$el.parentNode.parentNode.scrollTop = this.$el.parentNode.parentNode.scrollHeight;
    },
};
</script>

<style scoped lang="scss">
.message-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    margin-top: 8px;
    min-height: 60px;
    flex-shrink: 0;
    height: fit-content;

    .message-creator {
        font-size: 12px;
        color: #8F9BB3;
    }

    &.system {
        .message-creator {
            color: #f5b971;
            font-weight: 600;
        }
    }

    .message-metadata-container {
        display: flex;
        align-items: center;
        margin-top: 6px;

        .message-status {
            &.error {
                font-weight: 500;
                color: indianred;
            }
        }

        .message-dash,
        .message-status,
        .message-sent-at {
            font-size: 12px;
            font-weight: 300;
            color: #8F9BB3;
        }

        .message-status {
            text-transform: uppercase;
        }
    }

    &.incoming {
        align-items: flex-start;
        margin-left: 22px;

        .message-metadata-container {
            flex-direction: row-reverse;
        }
    }

    &.system,
    &.outgoing {
        align-items: flex-end;
        margin-right: 22px;
    }

    .message-bubble {
        max-width: 60%;
        font-size: 14px;
        border-radius: 10px;
        width: fit-content;
        position: relative;
        align-items: center;
        overflow: hidden;
        word-break: break-word;

        &.has-attachment:not(.has-text) {
            .message-content {
                display: none;
            }
        }

        &.has-attachment {
            .message-content {
                padding-top: 10px;
            }
        }

        .message-content {
            padding: 16px 20px;
        }

        .attachment-preview {
            .image-preview {
                img {
                    min-height: 128px;
                    max-height: 128px;
                    display: block;
                    max-width: 100%;
                    cursor: pointer;
                }
            }

            .video-preview {
                video {
                    min-height: 128px;
                    max-height: 350px;
                    display: block;
                    min-width: 100%;
                    object-fit: cover;
                    max-width: 100%;
                    cursor: pointer;
                }
            }

            .pdf-preview {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-top: 16px;

                .pdf-icon {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 42px;
                }

                .attachment-url {
                    padding: 0px 20px;
                    padding-bottom: 16px;

                }
            }
        }

        &.incoming {
            background-color: #E5E5EA;
            color: #31394D;
            border-bottom-left-radius: 0;
        }

        &.system {
            border: 1px solid #f5b971;
            color: #4a5d8b;
        }

        &.outgoing {
            background-color: #13A68A;
            color: #fff;
            border-bottom-right-radius: 0;
        }

        &.error {
            background-color: indianred;
        }
    }
}
</style>
