<template>
    <div @click="onClick" class="lead-list-item" v-bind:class="{unread: lead.has_unread_messages, active: isActive}">
        <div>
            <rounded-initials :has-check="lead.has_unread_messages"
                              :multi-select-view="isMultiSelected"
                              :opt="lead.opt_status"
                              :customer="lead"/>
        </div>
        <div class="name-message-container">
             <span @click.stop="onMarkLeadImportant" class="important-check" :class="{active: lead.is_important}">
                 <i class="icon" :class="{'icon-star_filed': lead.is_important, 'icon-star': !lead.is_important}"/>
             </span>
            <span v-if="Boolean(lead.first_name) ||  Boolean(lead.middle_name) || Boolean(lead.last_name)">
                <span class="name-part" v-if="Boolean(lead.first_name)">
                    {{ lead.first_name }}
                </span>
                <span class="name-part" v-if="Boolean(lead.middle_name)">
                    {{ lead.middle_name }}
                </span>
                <span class="name-part" v-if="Boolean(lead.last_name)">
                    {{ lead.last_name }}
                </span>
            </span>
            <span v-else>
                <span class="name-part">
                    {{ lead.phone | phone }}
                </span>
            </span>

            <div>
                <span class="last-message" v-if="lead.last_message">
                    {{ lead.last_message.text }}
                </span>
            </div>
        </div>
        <div class="more-updated-at-container" @click.stop="">
            <div class="agent-name-updated-at-text">
                <div class="agent-name-container">
                    <span class="agent-name">{{ lead.agent | mergeName }}</span>
                </div>
                <div>
                    <span class="updated-at-text">
                        <span v-if="lead.last_message">
                            {{ lead.last_message.created_at | date_short_if_today }}
                        </span>
                        <span v-else>
                            {{ lead.created_at | date_short_if_today }}
                        </span>
                    </span>
                </div>
            </div>
            <el-dropdown trigger="click" @command="onDropdownCommand">
                    <span class="more-menu-btn">
                        <i class="icon icon-menudots_vertical"/>
                    </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="toggle-important">
                        <span v-if="!lead.is_important">Mark Important</span>
                        <span v-else>Mark Unimportant</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="!isOptedOut" command="opt-out">
                        <span>Opt out</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="!lead.is_archived" command="archive">
                        <span>Archive</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-else command="unarchive">
                        <span>Unarchive</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="mark-as-unread">
                        <span>Mark as unread</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import RoundedInitials from '../../../../../../components/RoundedInitials/RoundedInitials';
import {
    ARCHIVE_LEAD_ACTION,
    MARK_LEAD_AS_IMPORTANT_ACTION, MARK_LEAD_AS_UNREAD,
    OPT_OUT_LEAD_ACTION,
    UNARCHIVE_LEAD_ACTION,
} from '@/store/actions/leads';
import { mapActions, mapGetters } from 'vuex';
import { LEAD_OPT_STATUS } from '@/utils/consts';

export default {
    name: 'LeadListItem',
    components: { RoundedInitials },
    props: {
        lead: Object,
    },
    computed: {
        ...mapGetters([
            'isLeadsMultiselectActive',
            'multiSelectedLeadsIds',
        ]),
        isOptedOut() {
            return this.lead.opt_status === LEAD_OPT_STATUS.OUT;
        },
        selectedLeadId() {
            return this.$store.state.leads.selected.id;
        },
        isMultiSelected() {
            return this.isLeadsMultiselectActive && this.multiSelectedLeadsIds.includes(this.lead.id);
        },
        isActive() {
            return this.isMultiSelected || this.selectedLeadId === this.lead.id;
        },
    },
    methods: {
        ...mapActions({
            archiveLead: ARCHIVE_LEAD_ACTION,
            unarchiveLead: UNARCHIVE_LEAD_ACTION,
            markLeadAsImportant: MARK_LEAD_AS_IMPORTANT_ACTION,
            markLeadAsUnread: MARK_LEAD_AS_UNREAD,
            optOutLeadAction: OPT_OUT_LEAD_ACTION,
        }),
        onClick() {
            this.$emit('on-click', this.lead.id);
        },
        onDropdownCommand(command) {
            switch (command) {
                case 'toggle-important': {
                    this.onMarkLeadImportant();
                    break;
                }
                case 'mark-as-unread': {
                    this.onMarkAsUnread();
                    break;
                }
                case 'unarchive': {
                    this.onUnarchiveLead();
                    break;
                }
                case 'archive': {
                    this.onArchiveLead();
                    break;
                }
                case 'opt-out': {
                    this.onOptOut();
                    break;
                }
            }
        },
        onMarkLeadImportant() {
            this.markLeadAsImportant(this.lead.id)
                .catch(() => {
                    this.$swal({
                        text: 'Error',
                        icon: 'error',
                    });
                });
        },
        onMarkAsUnread() {
            this.markLeadAsUnread(this.lead.id)
                .catch(() => {
                    this.$swal({
                        text: 'Error',
                        icon: 'error',
                    });
                });
        },
        onUnarchiveLead() {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes, unarchive it!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    this.unarchiveLead(this.lead.id)
                        .then(() => {
                            this.$swal({
                                text: 'Success',
                                icon: 'success',
                                timer: 1000,
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: false,
                            });
                        })
                        .catch(() => {
                            this.$swal({
                                text: 'Error',
                                icon: 'error',
                            });
                        });
                }
            });
        },
        onArchiveLead() {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes, archive it!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    this.archiveLead(this.lead.id)
                        .then(() => {
                            this.$swal({
                                text: 'Success',
                                icon: 'success',
                                timer: 1000,
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: false,
                            });
                        })
                        .catch(() => {
                            this.$swal({
                                text: 'Error',
                                icon: 'error',
                            });
                        });
                }
            });
        },
        onOptOut() {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes, opt out!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    this.optOutLeadAction(this.lead.id)
                        .then(() => {
                            this.$swal({
                                text: 'Success',
                                icon: 'success',
                                timer: 1000,
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: false,
                            });
                        })
                        .catch(() => {
                            this.$swal({
                                text: 'Error',
                                icon: 'error',
                            });
                        });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.theme-light {
    .lead-list-item {
        &:hover,
        &.active {
            background-color: #E5E9F2;
        }

        .name-part {
            color: #545F69;
        }

        .name-message-container {
            .last-message {
                color: #748AA1;
            }
        }

        .more-updated-at-container {
            .updated-at-text,
            .more-menu-btn {
                color: #748AA1;
            }
        }
    }
}

.lead-list-item {
    padding-left: 24px;
    padding-right: 16px;
    height: 72px;
    display: flex;
    align-items: center;
    transition: background-color 250ms ease-in-out;
    cursor: pointer;
    flex: none;

    &.unread {
        .name-message-container {
            .name-part {
                font-weight: 700;
            }
        }
    }

    &.active {
        background-color: #1E2234;
    }

    &:hover {
        background-color: #1E2234;
    }

    .name-message-container {
        position: relative;
        flex: 1;
        overflow: hidden;
        padding-left: 22px;
        padding-right: 16px;
        font-size: 14px;

        .important-check {
            position: absolute;
            left: 4px;
            top: 1px;
            color: #748AA1;
            transition: all 300ms ease-in-out;

            i {
                transition: all 300ms ease-in-out;
            }

            &.active {
                i {
                    font-size: 14px;
                    color: #FEC400;
                }
            }
        }

        .name-part {
            font-size: 15px;
        }

        .last-message {
            display: block;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
            color: #ffffff;
            opacity: 0.5;
            max-width: 400px;
        }
    }

    .more-updated-at-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        user-select: none;

        .agent-name-updated-at-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            margin-right: 4px;

            .agent-name-container {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                text-align: right;

                .agent-name {
                    text-align: right;
                    font-size: 12px;
                    color: #F4F7FC;
                }
            }
        }

        .more-menu-btn {
            text-align: right;
            color: #FFFFFF;
            opacity: 0.5;
            height: 20px;
            font-size: 18px;
            align-items: center;
            justify-content: center;
            display: flex;
            font-weight: 400;
        }

        .updated-at-text {
            font-size: 14px;
            color: #ffffff;
            opacity: 0.5;
        }
    }
}
</style>
