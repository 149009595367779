<template>
    <div class="companies-table-container">
        <div v-if="!async">
            <el-table :data="companies">
                <el-table-column width="350" prop="name" label="Name"/>
                <el-table-column width="350" prop="address" label="Address">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.address }}
                        </span>
                        <span>
                            {{ scope.row.city }}
                        </span>
                        <span>
                            {{ scope.row.postal_code }}
                        </span>
                        <span>
                            {{ scope.row.state }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="Phone" width="200">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.phone | phone }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="Actions">
                    <template slot-scope="scope">
                        <edit-company-dialog :company="scope.row"/>

                        <generate-keywords-dialog :company-id="scope.row.id"/>

                        <el-button style="margin-left: 8px"
                                   @click="deleteCompany(scope.row)"
                                   type="text">
                            <i class="icon icon-actions_delete"/>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="async-container" v-else>
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>
import { DELETE_COMPANY_ACTION, FETCH_ALL_COMPANIES_ACTION } from '@/store/actions/companies';
import { mapActions } from 'vuex';
import EditCompanyDialog from '../EditCompanyDialog/EditCompanyDialog';
import GenerateKeywordsDialog from "@/views/Companies/components/GenerateKeywordsDialog/GenerateKeywordsDialog";

export default {
    name: 'CompaniesTable',
    components: { GenerateKeywordsDialog, EditCompanyDialog },
    methods: {
        ...mapActions({
            fetchCompanies: FETCH_ALL_COMPANIES_ACTION,
            deleteCompanyAction: DELETE_COMPANY_ACTION,
        }),
        deleteCompany(company) {
            this.$swal({
                title: 'Are you sure?',
                text: 'This action is not reversable',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    this.deleteCompanyAction({ companyId: company.id })
                        .then(() => {
                            this.$swal({
                                text: 'Success',
                                icon: 'success',
                            });
                        })
                        .catch(() => {
                            this.$swal({
                                text: 'Error',
                                icon: 'error',
                            });
                        });
                }
            });
        },
    },
    mounted() {
        if (!this.hasCompanies) {
            this.fetchCompanies();
        }
    },
    computed: {
        async() {
            return this.$store.state.companies.async;
        },
        hasCompanies() {
            return this.$store.getters.hasCompanies;
        },
        companies() {
            return this.$store.getters.allCompanies;
        },
    },
};
</script>

<style lang="scss" scoped>
.companies-table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #12131F;
    padding: 24px;
    min-height: 0;
    overflow-y: auto;

    .async-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #F3F4F7;
    }
}
</style>
