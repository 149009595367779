<template>
    <div>
        <el-dropdown @command="handleImpersonateAction" v-if="isCompanyAdmin" trigger="click">
            <div class="name">
                <p>{{ activeUser | mergeName }}
                    <el-icon name="caret-bottom"/>
                </p>
                <p class="original-user" v-if="isImpersonationActive">{{ me | mergeName }}</p>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="user in imitableUsers" :key="user.email" :command="user">
                    {{ `${user.first_name} ${user.last_name}` }}
                </el-dropdown-item>
                <span class="no-agents-for-impersonation" v-if="!canImpersonate">
                    No Agents available for impersonation
                </span>
                <el-dropdown-item v-if="isImpersonationActive" command="exit">Admin View</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <div class="name" v-else>
            {{ me | mergeName }}
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { IMPERSONATE_USER } from '@/store/actions/login';

export default {
    name: 'Impersonation',
    methods: {
        handleImpersonateAction(user) {
            if (user === 'exit') {
                user = null;
            }
            this.impersonateUser(user);
        },
        ...mapActions({
            impersonateUser: IMPERSONATE_USER,
        }),
    },
    computed: {
        ...mapGetters([
            'isImpersonationActive',
            'isCompanyAdmin',
            'imitableUsers',
            'impersonatedUser',
            'me',
        ]),
        activeUser() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.me;
        },
        canImpersonate() {
            return (this.imitableUsers || []).length > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.name {
    color: #ffffff;
    margin-right: 16px;
    font-size: 14px;
    transition: all 300ms ease-in-out;
    font-weight: 500;

    &.active {
        color: #13A68A;
    }
}

.no-agents-for-impersonation {
    color: #FFFFFF;
    padding: 16px;
    font-size: 13px;
}

p.original-user {
    font-size: 12px;
    text-align: center;
}
</style>
