import { notifyMe } from '@/utils/notifications';
import { BROWSER_NOTIFICATION } from '@/store/actions/socket_events';
import { TOGGLE_PROFILE_DROPDOWN, SET_PROFILE_DROPDOWN } from "@/store/actions/ui";

export default {
    state: () => ({
        profileDropdownOpened: false
    }),
    mutations: {
        [TOGGLE_PROFILE_DROPDOWN](state) {
            state.profileDropdownOpened = !state.profileDropdownOpened;
        },
        [SET_PROFILE_DROPDOWN](state, open) {
            state.profileDropdownOpened = open;
        }
    },
    actions: {
        [BROWSER_NOTIFICATION](_, data) {
            const jsonData = JSON.parse(data);

            notifyMe(jsonData.notification);
        }
    },
    getters: {
        profileDropdownOpened(state) {
            return state.profileDropdownOpened;
        }
    },
};
