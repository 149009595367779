<template>
    <div class="keywords-table-container">
        <div v-if="!async">
            <custom-table :data="keywords"
                          class="keywords-table"
                          :fields="fields">
                <template v-slot:createdat="props">
                    <span>{{ props.item.created_at | moment('MM-DD-YYYY') }}</span>
                </template>
                <template v-slot:agent="props">
                    <span>{{ props.item.agent | mergeName }}</span>
                </template>
                <template v-slot:actions="props">
                    <div style="display: flex; align-items: start">
                        <el-button v-tooltip="'Generate Landing URL'"
                                   class="landing-url-button"
                                   type="text"
                                   @click.stop="generateLandingPageUrl(props.item)">
                            <i class="icon el-icon-link"/>
                        </el-button>
                        <edit-keyword :keyword="props.item" :company-id="props.item.company.id"/>
                        <assign-keyword-to-agent :keyword="props.item" :company-id="props.item.company.id"/>
                    </div>
                </template>
            </custom-table>
        </div>
        <div class="async-container" v-else>
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { FETCH_ALL_KEYWORDS_ACTION } from '@/store/actions/keywords';
import CustomTable from '@/components/CustomTable/CustomTable';
import { dateTimeSort, stringSort } from '@/utils/sorts';
import AssignKeywordToAgent from '@/views/Keywords/AssignKeywordToAgent/AssignKeywordToAgent';
import EditKeyword from "@/views/Keywords/EditKeyword/EditKeyword";
import Vue from "vue";

export default {
    name: 'KeywordsTable',
    components: { EditKeyword, AssignKeywordToAgent, CustomTable },
    data() {
        return {
            fields: [{
                name: 'created_at',
                title: 'Date Created',
                slot: 'createdat',
                width: '12%',
                sort: (a, b, direction) => dateTimeSort(a.created_at, b.created_at, direction),
            }, {
                name: 'status',
                title: 'Status',
                sort: (a, b, direction) => stringSort(a.status, b.status, direction),
            }, {
                name: 'name',
                title: 'Name',
                sort: (a, b, direction) => stringSort(a.name, b.name, direction),
            }, {
                name: 'company',
                title: 'Company',
                accessor: 'company.name',
                sort: (a, b, direction) => stringSort(a.company.name, b.company.name, direction),
            }, {
                name: 'agent',
                title: 'Agent',
                slot: 'agent',
            }, {
                title: 'Actions',
                slot: 'actions',
            }],
        };
    },
    methods: {
        ...mapActions({
            fetchKeywords: FETCH_ALL_KEYWORDS_ACTION,
        }),
        generateLandingPageUrl(keyword) {

            const landingUrl = `${window.location.origin}/landing/keyword/${keyword?.name}`

            navigator.clipboard.writeText(landingUrl);

            Vue.$toast.open({
                message: 'URL copied to clipboard.',
                type: 'success',
                duration: 2000,
                position: 'top-right',
            });
        }
    },
    mounted() {
        this.fetchKeywords();
    },
    computed: {
        async() {
            return this.$store.state.keywords.async;
        },
        keywords() {
            return this.$store.getters.allKeywords;
        },
    },
};
</script>

<style lang="scss">
.theme-dark {
}

.keywords-table-container {

    .keywords-table {
        .table-row {
            padding: 4px;
        }
    }

}
</style>

<style lang="scss" scoped>
.keywords-table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #12131F;
    padding: 24px;
    min-height: 0;
    overflow-y: auto;

    .async-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #F3F4F7;
    }

    .table-row {
        padding: 10px 0;
    }

    .landing-url-button {
        font-size: 16px;
    }
}
</style>
