import { apiCall, endpoint, properties, property_interest } from '../apiCall';

export async function fetchPropertiesPage(page = 1) {
    let url = new URL(properties);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', 30);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function addNewProperty(property) {
    let url = new URL(properties);

    return apiCall({
        url: url.href,
        method: 'POST',
        jsonData: {
            ...property,
        },
    }).then(r => r.json());
}

export async function generateDataForPropertyUrl(propertyUrl) {
    let url = new URL(`${properties}/generate_data_by_url`);

    return apiCall({
        url: url.href,
        method: 'POST',
        jsonData: {
            url: propertyUrl,
        },
    }).then(r => r.json());
}

export async function deleteProperty(propertyId) {
    let url = new URL(`${properties}/${propertyId}`);

    return apiCall({
        url: url.href,
        method: 'DELETE',
    });
}

export async function editProperty(propertyId, data) {
    let url = new URL(`${properties}/${propertyId}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: data,
    }).then(r => r.json());
}

export async function changePropertyAgent(propertyId, agentId) {
    let url = new URL(`${properties}/${propertyId}/change_agent`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: { agent_id: agentId },
    }).then(r => r.json());
}

export async function getPropertyInterestedLeads(propertyId) {
    let url = new URL(`${properties}/${propertyId}/interested_leads`);

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/json',
        },
    }).then(r => r.json());
}

export async function editPropertyInterest(interestId, data) {
    let url = new URL(`${property_interest}/${interestId}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: data,
    }).then(r => r.json());
}

export async function addPropertyInterest(leadId, propertyId) {
    let url = new URL(property_interest);

    return apiCall({
        url: url.href,
        method: 'POST',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: {
            property: propertyId,
            lead: leadId,
            archived: false,
            collapsed: false,
            note: null,
            initiated_by: 'AGENT',
        },
    }).then(r => r.json());
}

export async function getEnhancedPropertyInterest(interestId) {
    let url = new URL(`${property_interest}/${interestId}/enhanced_property`);

    return apiCall({
        url: url.href,
        method: 'GET',
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function fetchArchivedPropertiesPage(page = 0) {
    let url = new URL(properties);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', 30);
    url.searchParams.append('archived', true);

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function archiveProperty(propertyId, newStatus) {
    let url = new URL(`${properties}/${propertyId}/archive`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        jsonData: {
            new_status: newStatus, // TODO set this properly
        },
    });
}

export async function unarchiveProperty(propertyId, newStatus, keywordId) {
    let url = new URL(`${properties}/${propertyId}/unarchive`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        jsonData: {
            new_status: newStatus,
            keyword_id: keywordId
        },
    });
}

export async function assignKeywordToProperty(propertyId, keywordId) {
    let url = new URL(`${properties}/${propertyId}/assign_keyword`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: { keyword_id: keywordId },
    }).then(r => r.json());
}

export async function fetchPropertyByShortId(shortId) {
    let url = new URL(`${endpoint}/api/public/properties/get_by_short/${shortId}`);

    return apiCall({
        url: url.href,
        method: 'GET',
    }).then(r => r.json());
}

export async function fetchPropertyByKeywordName(keywordName) {
    let url = new URL(`${endpoint}/api/public/properties/get_by_keyword/${keywordName}`);

    return apiCall({
        url: url.href,
        method: 'GET',
    }).then(r => r.json());
}