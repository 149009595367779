<template>
    <div class="company-user-table-container">
        <el-table ref="companyUserTable" :data="allUsers">
            <el-table-column width="80" prop="enabled" label="Enabled">
                <template slot-scope="scope">
                    <el-switch @change="onEnabledChange(scope.row.id, $event)" :value="scope.row.enabled"/>
                </template>
            </el-table-column>
            <el-table-column prop="id" label="Name">
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.first_name }}
                    </span>
                    <span>
                        {{ scope.row.middle_name }}
                    </span>
                    <span>
                        {{ scope.row.last_name }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="email" label="Email"/>
            <el-table-column prop="phone" label="Phone">
                <template slot-scope="scope">
                    <span v-if="scope.row.phone">{{ scope.row.phone | phone }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Roles" prop="roles">
                <template slot-scope="scope">
                    <div :key="role" v-for="role in scope.row.roles">
                        {{ role | role }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Created At" prop="created_at">
                <template slot-scope="scope">
                    <span>{{ scope.row.created_at | moment('MM/DD/YYYY hh:mm:ss') }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Actions">
                <template slot-scope="scope">
                    <div style="display: flex">
                        <edit-user-dialog-wrapper :user="scope.row"/>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import EditUserDialogWrapper from '../EditUserDialogWrapper/EditUserDialogWrapper';
import { ENABLE_DISABLE_USER_ACTION } from '../../../../store/actions/users';
import { mapActions } from 'vuex';

export default {
    name: 'CompanyUsersTable',
    components: { EditUserDialogWrapper },
    methods: {
        ...mapActions({
            enableDisableUserAction: ENABLE_DISABLE_USER_ACTION,
        }),
        onEnabledChange(userId, enabled) {
            this.enableDisableUserAction({ userId, enabled });
        },
    },
    computed: {
        allUsers() {
            return this.$store.getters.allUsers;
        },
    },
};
</script>

<style lang="scss" scoped>
.company-user-table-container {
    display: flex;
    padding: 24px;
}
</style>
