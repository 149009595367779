<template>
    <div class="details">
        <div class="rounded-phone-address-container">
            <div class="rounded-container"
                 @mouseenter="showEditPencil = true"
                 @mouseleave="showEditPencil = false">

                <rounded-initials :opt="customer.opt_status"
                                  :customer="customer"/>

                <el-button @click="toggleEditMode"
                           :circle="true"
                           type="text"
                           class="edit-mode-toggle"
                           v-tooltip="'Edit Mode'"
                           v-if="showEditPencil">
                    <i class="icon icon-actions_edit"/>
                </el-button>

                <div class="customer-details">
                    <div class="important-check-container" @click="toggleImportant">
                        <i class="icon"
                           :class="{'icon-star': !customer.is_important, 'icon-star_filed': customer.is_important}"/>
                    </div>
                    <div class="detail-field" style="margin-top: 0px; margin-bottom: 0px">
                        <div class="title"
                             v-if="Boolean(customer.first_name) || Boolean(customer.middle_name) || Boolean(customer.last_name)">
                            <span>{{ customer | mergeName }}</span>
                        </div>
                        <div class="title" v-else>
                            Full Name
                        </div>
                        <div class="value">
                            <span>{{ customer.phone | phone }}</span>
                        </div>
                    </div>
                    <div class="customer-important-show-profile">
                        <el-button @click="showProfile" class="show-profile-btn"
                                   :class="{active: isCustomerDetailsExpanded}"
                                   type="text">
                            <span v-if="!isCustomerDetailsExpanded">PROFILE DETAILS</span>
                            <span v-else>HIDE PROFILE DETAILS</span>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="customer-thread-details" :class="{expanded: isCustomerDetailsExpanded}">
            <div class="detail-field" v-if="!isEditMode">
                <div class="title">Full Name</div>
                <div class="value" v-if="Boolean(customer.first_name) && Boolean(customer.last_name)">
                    <span>
                        {{ customer.first_name }}
                    </span>
                    <span>
                        {{ customer.last_name }}
                    </span>
                </div>
                <div class="value" v-else>
                    <span>-</span>
                </div>
            </div>

            <div class="detail-edit-field" v-else>
                <labeled-input :show-label="false" type="text" v-model="form.name" name="Name"/>
            </div>

            <div class="detail-field">
                <div class="title">Phone</div>
                <div class="value" v-if="Boolean(customer.phone)">
                    <span>
                        {{ customer.phone | phone }}
                    </span>
                </div>
                <div class="value" v-else>
                    <span>-</span>
                </div>
            </div>

            <div class="detail-field" v-if="!isEditMode">
                <div class="title">Email</div>
                <div class="value" v-if="Boolean(customer.email)">
                    <a :href="`mailto:${customer.email}`">
                        {{ customer.email }}
                    </a>
                </div>
                <div class="value" v-else>
                    <span>-</span>
                </div>
            </div>

            <div v-else class="detail-edit-field">
                <labeled-input :show-label="false" type="email" v-model="form.email" name="Email"/>
            </div>

            <div class="save-btn-container">
                <el-button class="cancel-btn" @click="cancelClicked" type="text" v-if="isEditMode">
                    CANCEL
                </el-button>
                <el-button @click="toggleEditMode" type="text" v-if="!isEditMode">
                    EDIT
                </el-button>
                <el-button :loading="editAsync" @click="saveEditedLead" type="text" v-else>
                    SAVE
                </el-button>
            </div>

        </div>

        <div class="realestate-interest">
            <div v-if="!interestsAsync" class="interested-in-container">

                <!--NOTE -->
                <div class="customer-note-container">

                    <span v-if="!hasNote && !isNoteEditMode"
                          class="add-note"
                          @click="toggleNoteEditMode(true)">
                        ADD NOTE
                    </span>

                    <span class="note-label" v-if="hasNote || isNoteEditMode">NOTE</span>

                    <div v-if="isNoteEditMode">
                        <textarea rows="3" v-model="form.note" autofocus/>

                        <el-button :loading="editAsync" @click="saveEditedLead" type="text">
                            SAVE
                        </el-button>
                    </div>

                    <div v-if="hasNote && !isNoteEditMode">
                        <div class="note-text">
                            <v-clamp ref="clampRef"
                                     :key="customer.id"
                                     autoresize
                                     @clampchange="onClampChange"
                                     :expanded="false"
                                     :max-lines="2">
                                {{ customer.note }}
                            </v-clamp>
                        </div>

                        <el-button :loading="editAsync" @click="toggleNoteEditMode(true)"
                                   type="text"
                                   class="edit-btn"
                                   :class="{clamped: isNoteClamped}">
                            EDIT
                        </el-button>

                        <i class="icon-down toggle-note-expanded"
                           @click="toggleNoteClamp"
                           v-if="isNoteClamped || isNoteExpanded"
                           :class="{'icon-collapse': isNoteExpanded, 'icon-down': !isNoteExpanded}"/>
                    </div>
                </div>

                <!---->

                <div class="interested-in-text">
                    Interested in
                </div>

                <div class="interested-in-list">
                    <interest-preview :interest="interest"
                                      @on-archive="onArchiveInterest(interest.interest_id)"
                                      @expand-collapse-clicked="!interest.archived ? toggleInterestExpanded(interest.interest_id, !interest.collapsed) : unarchiveInterest(interest.interest_id)"
                                      v-for="interest in interests"
                                      :key="interest.id"/>
                </div>

            </div>
            <div v-else class="async-container">
                <vue-loaders-line-scale/>
            </div>
        </div>
    </div>
</template>

<script>
import RoundedInitials from '../../RoundedInitials/RoundedInitials';
import LabeledInput from '../../LabeledInput/LabeledInput';
import {
    difference,
    getFullNameFromParts,
    splitNameIntoParts,
} from '@/utils/utils';
import { EDIT_LEAD_ACTION, SET_INTEREST_ARCHIVED_ACTION, SET_INTEREST_COLLAPSED_ACTION } from '@/store/actions/leads';
import { mapActions } from 'vuex';
import { MARK_LEAD_AS_IMPORTANT_ACTION } from '@/store/actions/leads';
import InterestPreview from '@/components/ConversationSection/Details/InterestPreview/InterestPreview';
import VClamp from 'vue-clamp';

export default {
    name: 'Details',
    components: {
        InterestPreview,
        LabeledInput,
        RoundedInitials,
        VClamp,
    },
    data() {
        return {
            isEditMode: false,
            isCustomerDetailsExpanded: false,
            showEditPencil: false,
            isNoteEditMode: false,
            isNoteClamped: false,
            isNoteExpanded: false,

            form: {
                email: '',
                name: '',
                note: null,
            },
        };
    },

    computed: {
        editAsync() {
            return this.$store.state.leads.editAsync;
        },
        lead() {
            return this.$store.getters.selectedLead;
        },
        customer() {
            return this.lead;
        },
        interests() {
            return this.$store.getters.selectedLeadInterests;
        },
        interestsAsync() {
            return this.$store.state.leads.selected.interestsAsync;
        },
        interestsErrors() {
            return this.$store.state.leads.selected.interestsErrors;
        },
        hasNote() {
            return Boolean(this.customer.note);
        },
    },

    watch: {
        isNoteEditMode() {
            this.resetForm();
        },
        isEditMode() {
            this.resetForm();
        },
        lead() {
            this.resetForm();
            // reset note state
            this.isNoteExpanded = false;
            this.isNoteEditMode = false;
        },
    },

    methods: {
        onClampChange(c) {
            this.isNoteClamped = c;
        },
        resetForm() {
            this.form = {
                email: this.customer.email,
                name: getFullNameFromParts(this.customer) || '',
                note: this.customer.note,
            };
        },
        cancelClicked() {
            this.isCustomerDetailsExpanded = false;

            // wait until animation of closing is at least a bit done;
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isEditMode = false;
                });
            }, 200);
        },
        ...mapActions({
            markLeadAsImportant: MARK_LEAD_AS_IMPORTANT_ACTION,
            editLead: EDIT_LEAD_ACTION,

            setInterestCollapsedAction: SET_INTEREST_COLLAPSED_ACTION,
            setInterestArchivedAction: SET_INTEREST_ARCHIVED_ACTION,
        }),

        unarchiveInterest(interestId) {
            this.$swal({
                title: 'Are you sure?',
                text: 'Are you sure you want to mark this property as interested again ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    this.setInterestArchivedAction({ interestId, archived: false });
                }
            });
        },

        onArchiveInterest(interestId) {
            this.$swal({
                title: 'Are you sure?',
                text: 'Are you sure you want to mark this property as not interested anymore ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    this.setInterestArchivedAction({ interestId, archived: true });
                }
            });
        },

        disableEditMode() {
            this.isNoteEditMode = false;
            this.isCustomerDetailsExpanded = false;

            // wait until animation of closing is at least a bit done;
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isEditMode = false;
                });
            }, 200);
        },

        toggleEditMode() {
            if (this.isEditMode) {
                this.disableEditMode();
            } else {
                this.isEditMode = true;

                this.$nextTick(() => {
                    this.isCustomerDetailsExpanded = true;
                });
            }
        },

        saveEditedLead() {
            const defaultForm = {
                email: this.customer.email,
                name: getFullNameFromParts(this.customer) || '',
                note: this.customer.note,
            };

            let changed = difference(this.form, defaultForm);

            if (changed.name) {
                changed = { ...splitNameIntoParts(changed.name), ...changed };
            }

            this.editLead({ id: this.lead.id, ...changed }).then(() => {
                this.disableEditMode();
                this.form = {
                    email: '',
                    name: '',
                    note: null,
                };
            });
        },

        toggleInterestExpanded(interestId, collapsed) {
            this.setInterestCollapsedAction({ interestId, collapsed });
        },

        showProfile() {
            this.isEditMode = false;

            this.$nextTick(() => {
                this.isCustomerDetailsExpanded = !this.isCustomerDetailsExpanded;
            });
        },

        toggleImportant() {
            this.markLeadAsImportant(this.lead.id);
        },
        toggleNoteEditMode(toggle) {
            this.isNoteEditMode = toggle;
        },
        toggleNoteClamp() {
            this.$refs.clampRef.toggle();
            this.isNoteExpanded = !this.isNoteExpanded;
        },

    },
};
</script>

<style scoped lang="scss">
.theme-light {
    .details {
        background-color: #F4F7FC;

        .rounded-phone-address-container {
            .phone {
                color: #545F69;
            }
        }

        .customer-thread-details {
            .detail-field {
                .value {
                    color: #545F69;
                }
            }
        }

        .interested-in-container {
            .interested-in {
                color: #748AA1;

                .address-container {
                    color: #748AA1;
                }

                .real-estate-details {
                    .description {
                        color: #748AA1;
                    }
                }
            }
        }
    }
}

.details {
    color: #FFFFFF;
    width: 45%;
    height: 100%;
    background-color: #1E2234;
    display: flex;
    flex-direction: column;

    .detail-edit-field {
        margin-bottom: 3px;
    }

    .save-btn-container {
        text-align: right;

        .cancel-btn {
            color: #748AA1;
        }
    }

    .rounded-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-bottom: 10px;
        padding-right: 16px;
        padding-left: 16px;
        flex: 1;
        width: 100%;

        .customer-details {
            flex: 1;
            padding-left: 12px;
            display: flex;

            .important-check-container {
                cursor: pointer;
                margin-right: 4px;

                i {
                    transition: all 300ms ease-in-out;
                    color: #748AA1;

                    &.icon-star_filed {
                        color: #FEC400;
                    }
                }
            }

            .customer-important-show-profile {
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                flex: 1;
                display: flex;

                .show-profile-btn {
                    font-size: 11px;
                    color: #B8C5D3;
                    padding-top: 0;
                    padding-bottom: 0;
                    transition: all 300ms ease-in-out;

                    &.active,
                    &:hover {
                        color: #13A68A;
                    }
                }

            }

            .detail-field {
                .title {
                    font-size: 14px;
                }
            }
        }

        .edit-mode-toggle {
            position: absolute;
            bottom: 40px;
            left: 40px;
            background-color: #181B29;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 12px;
        }

        .rounded-initials {
            width: 50px;
            height: 50px;
            font-size: 20px;
            cursor: pointer;
        }
    }

    .rounded-phone-address-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 24px;

        font-size: 14px;

        .phone {
            font-weight: 500;
        }

        div:not(.rounded-initials) {
            margin-top: 4px;
            margin-bottom: 4px;
        }
    }

    .detail-field {
        margin-top: 14px;
        margin-bottom: 14px;
        font-size: 14px;

        .title {
            margin-bottom: 4px;
            color: #b5b7ba;
            font-weight: 400;
        }

        .value {
            a {
                color: #ffffff;
                font-weight: 400;
                text-decoration: none;
            }

            color: #ffffff;
            font-weight: 400;
        }
    }

    .customer-thread-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        max-height: 0;
        transition: all 300ms ease-in-out;

        &.expanded {
            max-height: 345px;
            padding: 0px 20px 8px;
        }
    }

    .realestate-interest {
        display: flex;
        flex: 1;
        overflow: auto;
        border-top: 2px solid #4c545d;

        .interested-in-container {
            position: relative;
            padding-top: 16px;
            display: flex;
            flex-direction: column;
            flex: 1;

            .interested-in-list {
                padding-bottom: 16px;
                min-height: 0;
                overflow: auto;

                .interested-in {
                    margin-top: 24px;
                    margin-bottom: 32px;
                    margin-left: 16px;
                    margin-right: 16px;
                }

                flex: 1;
            }

            .interested-in-text {
                font-size: 14px;
                color: #B8C5D3;
                margin-left: 16px;
                margin-right: 16px;
                padding-bottom: 12px;
            }

        }
    }

    .async-container {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .customer-note-container {
        display: flex;
        flex-direction: column;
        word-break: break-all;
        white-space: normal;
        margin: 8px 16px 32px 16px;
        color: #748AA1;
        border-radius: 8px;
        border: 1px solid #748AA1;
        padding: 12px;
        position: relative;
        transition: all 300ms ease-in-out;

        &:hover {
            border-color: #13A68A;
            cursor: pointer;

            .add-note {
                color: #13A68A;
            }
        }

        .el-button {
            padding: 0;
            font-size: 12px;
        }

        .edit-btn {
            margin-top: 10px;

            &.clamped {
                margin-top: 0;
            }
        }

        .add-note {
            font-size: 12px;
        }

        .note-label {
            font-size: 12px;
            position: absolute;
            top: -8px;
            padding-left: 4px;
            padding-right: 4px;
            background-color: #1E2234;
            color: #748AA1;
            transition: all 150ms ease-in-out;
        }

        textarea {
            border: none;
            padding: 0;
            resize: none;
            color: #D8D8D8;
            margin-bottom: 5px;
        }

        .toggle-note-expanded {
            transition: transform 200ms ease-in-out;
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: center;
            position: absolute;
            bottom: -13px;
            background-color: #1E2234;
            right: 13px;
            cursor: pointer;

            &.icon-down {

            }

            &.icon-collapse {
                transform: rotate(180deg);
                color: #13A68A;
            }
        }

        .note-text {
            color: #b5b7ba;
            font-size: 13px;
            position: relative;

            .read-more {
                color: #F4F7FC;
                cursor: pointer;
            }
        }

        .buttons-container {
            margin-top: 8px;
            align-self: flex-end;
            text-align: end;
        }
    }
}
</style>
