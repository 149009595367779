<template>
    <fragment>
        <conversation-section v-if="Boolean(selectedLead) && !isLeadsMultiselectActive"/>
        <multi-conversation-section v-if="isLeadsMultiselectActive"/>
    </fragment>
</template>

<script>
// this has to exist because of a dumb transition gotcha
import ConversationSection from '@/components/ConversationSection/ConversationSection';
import MultiConversationSection from '@/components/MultiConversationSection/MultiConversationSection';
import { mapGetters } from 'vuex';

export default {
    name: 'LeadsConversationSectionWrapper',
    components: { MultiConversationSection, ConversationSection },
    computed: {
        ...mapGetters([
            'selectedLead',
            'isLeadsMultiselectActive',
        ]),
    },
};
</script>

<style scoped>

</style>
