import {
    ADD_NEW_MESSAGE,
    APPEND_MESSAGES_PAGE,
    FETCH_ALL_MESSAGES_ACTION,
    FETCH_MESSAGES_PAGE_ACTION, MESSENGER_SET_CURRENT_ENTITY_ID,
    RESET_MESSENGER_STATE, SEND_CSI_SURVEY_MESSAGE,
    SEND_MESSAGE, SET_ABORT_SIGNAL,
    SET_MESSAGES,
    SET_MESSAGES_ASYNC,
    SET_MESSENGER_SENDING_ASYNC, SET_UPLOAD_PROGRESS, UPDATE_MESSAGE,
} from '../actions/messenger';
import { INBOUND_MESSAGE, MESSAGE_STATUS_UPDATED, OUTBOUND_MESSAGE } from '../actions/socket_events';
import { fetchMessagesPage, sendCsiSurveyMessage, sendMediaMessage, sendMessage } from '@/api/messenger/messenger';
import Vue from 'vue';
import { PING_READ_ENDPOINT_ACTION, SEND_MEDIA_MESSAGE } from '@/store/actions/messenger';
import { markLeadMessageThreadAsRead } from '@/api/messenger/messenger';

const getInitialState = () => ({
    messages: null,
    async: false,
    entityId: null,
    uploadProgress: null,
    abortSignal: null,
    sendingAsync: false,
});

export default {
    state: getInitialState(),
    mutations: {
        [SET_UPLOAD_PROGRESS](state, progress) {
            state.uploadProgress = progress;
        },
        [SET_ABORT_SIGNAL](state, signal) {
            state.abortSignal = signal;
        },
        [SET_MESSAGES](state, { messages }) {
            state.messages = messages;
        },
        [SET_MESSAGES_ASYNC](state, async) {
            state.async = async;
        },
        [ADD_NEW_MESSAGE](state, { message }) {
            if (Array.isArray(state.messages)) {
                const i = state.messages.findIndex(m => m.id === message.id);

                if (i === -1) {
                    state.messages.unshift(message);
                }
            } else {
                state.messages = [message];
            }
        },
        [APPEND_MESSAGES_PAGE](state, { messages: newMessages }) {
            if (Array.isArray(state.messages)) {
                state.messages = state.messages.concat(newMessages);
            } else {
                state.messages = [...newMessages];
            }
        },
        [RESET_MESSENGER_STATE](state) {
            Object.assign(state, getInitialState());
        },
        [SET_MESSENGER_SENDING_ASYNC](state, async) {
            state.sendingAsync = async;
        },
        [UPDATE_MESSAGE](state, { id, ...data }) {
            if (Array.isArray(state.messages)) {
                for (let message of state.messages) {
                    if (message.id === id) {
                        Object.assign(message, data);
                        return;
                    }
                }
            }
        },
        [MESSENGER_SET_CURRENT_ENTITY_ID](state, entityId) {
            state.entityId = entityId;
        },
    },
    actions: {
        async [FETCH_ALL_MESSAGES_ACTION]({ commit, dispatch }, { forEntity, entityId }) {
            let page = 0;

            commit(SET_MESSAGES, []);
            commit(SET_MESSAGES_ASYNC, true);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_MESSAGES_PAGE_ACTION, { page, forEntity, entityId });
            }

            commit(SET_MESSAGES_ASYNC, false);
        },
        async [FETCH_MESSAGES_PAGE_ACTION]({ commit }, { forEntity, entityId, page }) {
            commit(MESSENGER_SET_CURRENT_ENTITY_ID, entityId);

            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': messages,
                } = await fetchMessagesPage(forEntity, entityId, page);

                if (Array.isArray(messages)) {
                    commit(APPEND_MESSAGES_PAGE, { messages });
                }

                return Boolean(pagination['hydra:next']);
            } catch (ex) {
                console.error('Fetch messages', forEntity, entityId, page, ex);
            }
        },
        async [SEND_MESSAGE]({ commit }, { forEntity, entityId, message: text }) {
            commit(SET_MESSENGER_SENDING_ASYNC, true);

            return await sendMessage(forEntity, entityId, text).then((r) => {
                commit(SET_MESSENGER_SENDING_ASYNC, false);
                return r;
            }).catch(async (ex) => {
                console.error('Send message', forEntity, entityId, text, ex);

                const r = await ex.json();

                Vue.$toast.error(r.detail, {
                    position: 'top-right',
                });

                commit(SET_MESSENGER_SENDING_ASYNC, false);
                throw ex;
            });
        },
        async [SEND_CSI_SURVEY_MESSAGE]({ commit }, { forEntity, entityId }) {
            commit(SET_MESSENGER_SENDING_ASYNC, true);

            return await sendCsiSurveyMessage(forEntity, entityId).then((r) => {
                commit(SET_MESSENGER_SENDING_ASYNC, false);
                return r;
            }).catch(async (ex) => {
                console.error('Send message', forEntity, entityId, ex);

                const r = await ex.json();

                Vue.$toast.error(r.detail, {
                    position: 'top-right',
                });

                commit(SET_MESSENGER_SENDING_ASYNC, false);
                throw ex;
            });
        },
        async [SEND_MEDIA_MESSAGE]({ commit }, { forEntity, entityId, message: text, files }) {
            commit(SET_MESSENGER_SENDING_ASYNC, true);

            const abort = new AbortController();

            commit(SET_ABORT_SIGNAL, abort);

            return await sendMediaMessage(forEntity, entityId, text, files, (progress) => {

                commit(SET_UPLOAD_PROGRESS, progress);

            }, abort.signal).then(() => {
                commit(SET_UPLOAD_PROGRESS, null);
                commit(SET_ABORT_SIGNAL, null);
                commit(SET_MESSENGER_SENDING_ASYNC, false);
            }).catch(async (ex) => {
                console.error('Send media message', forEntity, entityId, text, ex);

                if (ex.json) {
                    const r = await ex.json();

                    Vue.$toast.error(r.detail, {
                        position: 'top-right',
                    });
                }

                commit(SET_UPLOAD_PROGRESS, null);
                commit(SET_ABORT_SIGNAL, null);
                commit(SET_MESSENGER_SENDING_ASYNC, false);

                if (ex.message !== 'Aborted') {
                    throw ex;
                }
            });
        },
        [MESSAGE_STATUS_UPDATED]({ commit }, data) {
            const jsonData = JSON.parse(data);

            console.log(MESSAGE_STATUS_UPDATED, jsonData);

            commit(UPDATE_MESSAGE, { id: jsonData.id, status: jsonData.status });
        },
        [INBOUND_MESSAGE]({ state, commit }, data) {
            const jsonData = JSON.parse(data);

            console.log(INBOUND_MESSAGE, jsonData);

            if (jsonData.lead.id === state.entityId) {
                commit(ADD_NEW_MESSAGE, { message: jsonData });
            }
        },
        [OUTBOUND_MESSAGE]({ state, commit }, data) {
            const jsonData = JSON.parse(data);

            console.log(OUTBOUND_MESSAGE, jsonData);

            if (jsonData.lead.id === state.entityId) {
                commit(ADD_NEW_MESSAGE, { message: jsonData });
            }
        },
        async [PING_READ_ENDPOINT_ACTION](_, leadId) {
            await markLeadMessageThreadAsRead(leadId);
        },
    },
    getters: {
        allMessages(state) {
            if (state.messages === null) {
                return null;
            } else {
                return state.messages.slice().reverse();
            }
        },
    },
};
