<template>
    <div v-click-outside="hideSidebar" class="attachment-popup" :class="{open: open}">
        <div class="property-mode" v-if="isPropertyMode">
            <div class="property-list-container" v-if="!async">
                <div class="property-list-header">
                    <span>Send listing URL</span>

                    <div class="close" @click="$emit('hide')">
                        <i class="icon-actions_delete"/>
                    </div>
                </div>

                <div class="properties-list">
                    <div class="property-list-item"
                         v-for="property of properties"
                         :key="property.id"
                         @click="onSelectProperty(property)">
                        <span>{{ property.name }}</span>
                    </div>
                </div>
            </div>

            <div v-else class="async-container">
                <vue-loaders-line-scale color="#12131F"/>
            </div>
        </div>

        <attachment-templates @append-text="$emit('send-template', $event)"
                              @hide="$emit('hide')"
                              v-if="isTemplateMode"/>
    </div>
</template>

<script>

import { ATTACHMENT_POPUP_MODE } from '@/utils/consts';
import ClickOutside from 'vue-click-outside';
import { FETCH_ALL_PROPERTIES_ACTION } from '@/store/actions/properties';
import { mapActions } from 'vuex';
import AttachmentTemplates from '@/components/Messenger/MessageBar/AttachmentPopup/AttachmentTemplates';
import { ADD_PROPERTY_INTEREST_ACTION } from '@/store/actions/leads';
import { showAreYouSureSwal } from '@/utils/utils';

export default {
    name: 'AttachmentPopup',
    components: { AttachmentTemplates },
    props: {
        open: Boolean,
        mode: String,
    },

    computed: {
        isPropertyMode() {
            return this.mode === ATTACHMENT_POPUP_MODE.PROPERTY;
        },

        isTemplateMode() {
            return this.mode === ATTACHMENT_POPUP_MODE.TEMPLATE;
        },

        hasProperties() {
            return this.$store.getters.hasProperties;
        },

        properties() {
            return this.$store.state.properties.properties;
        },

        async() {
            return this.$store.state.properties.async;
        },

        selectedLeadId() {
            return this.$store.state.leads.selected.id;
        },
    },

    mounted() {
        // this is needed actually
        this.popupItem = this.$el;
    },

    methods: {
        ...mapActions({
            fetchAllProperties: FETCH_ALL_PROPERTIES_ACTION,
            addPropertyInterestAction: ADD_PROPERTY_INTEREST_ACTION,
        }),
        onSelectProperty(property) {
            showAreYouSureSwal(this.$swal, {text: `Are you sure you want to send "${property.name}" to customer?`}).then(res => {
                if (res.value) {
                    this.addPropertyInterestAction({ propertyId: property.id, leadId: this.selectedLeadId });

                    this.$emit('send-property', property.url);
                    this.$emit('hide');
                }
            });
        },
        hideSidebar() {
            this.$emit('hide');
        },
    },

    watch: {
        open: function () {
            if (this.mode === ATTACHMENT_POPUP_MODE.PROPERTY) {
                if (!this.hasProperties) {
                    this.fetchAllProperties();
                }
            }
        },
    },


    directives: {
        ClickOutside,
    },
};
</script>

<style lang="scss" scoped>
.attachment-popup {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 100%;
    height: 0vh;
    transition: all 300ms ease-in-out;
    background-color: white;
    overflow: hidden;

    &.open {
        height: 45vh;
    }

    .property-mode {
        display: flex;
        height: 100%;

        .property-list-container {
            padding-top: 20px;
            display: flex;
            flex: 1;
            flex-direction: column;

            .property-list-header {
                font-weight: 500;
                color: #13A68A;
                margin-left: 20px;
                margin-right: 20px;
                padding-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .close {
                    font-size: 18px;
                    cursor: pointer;
                    color: #60666c;
                }
            }

            .properties-list {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: auto;

                .property-list-item {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    margin-left: 20px;
                    margin-right: 20px;
                    transition: all 300ms ease-in-out;
                    cursor: pointer;
                    min-height: 50px;

                    border-bottom: 1px solid #ddd;

                    &:hover {
                        color: #13A68A;
                    }
                }
            }
        }

        .async-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
