<template>
    <span>
        <el-button @click="dialogVisible = true" type="text">
            <i class="icon icon-actions_edit"/>
        </el-button>

        <add-edit-company-dialog @hide="dialogVisible = false" @update:visible="vis => this.dialogVisible = vis" :visible="dialogVisible" :company="company"/>
    </span>
</template>

<script>
import AddEditCompanyDialog from '../AddEditCompanyDialog/AddEditCompanyDialog';

export default {
    name: 'EditCompanyDialog',
    components: { AddEditCompanyDialog },
    props: {
        company: Object,
    },
    data() {
        return {
            dialogVisible: false,
        };
    },
};
</script>
