<template>
    <div class="bugroute">
        bugroute
    </div>
</template>

<script>
export default {
    name: 'BugRoute',

    mounted() {
        throw Error('I\'m a sentry testing bug route');
    },
};
</script>

<style scoped lang="scss">
.bugroute {
    background: red;
}
</style>
