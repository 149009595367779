import { doLogin, doMe } from '@/api/login/login';

import router from '../../router';
import {
    DO_ME_ACTION,
    IMPERSONATE_USER,
    PERFORM_LOGIN_ACTION,
    SET_LOGIN_ASYNC,
    SET_LOGIN_ERRORS,
    SET_USER,
    SET_IMPERSONATE,
    SET_IMITABLE_USERS,
    FETCH_LIST_FOR_IMPERSONATION, UPDATE_IMPERSONATE,
} from '../actions/login';
import { tryToParseImpersonationFromLocalStorage, userIsCompanyAdmin, userIsSuperAdmin } from '@/utils/utils';
import { UPDATE_ME } from '@/store/actions/login';
import { STORAGE_KEYS } from '@/utils/consts';
import { SELECT_LEAD, SET_ARCHIVED_LEADS, SET_LEADS } from '@/store/actions/leads';
import { SET_ARCHIVED_PROPERTIES, SET_PROPERTIES } from '@/store/actions/properties';
import { SET_COMPANY_TEMPLATES, SET_USER_TEMPLATES } from '@/store/actions/templates';
import { SET_KEYWORDS } from '@/store/actions/keywords';
import { fetchUserListForImpersonating } from '@/api/users/users';
import clone from 'clone';
import * as Sentry from '@sentry/vue';

function setSentryUserContext(user) {
    if (user !== null) {
        Sentry.setContext('user', {
            id: user.id,
            email: user.email,
            companyId: user.company?.id,
            companyName: user.company?.name,
            isCompanyAdmin: userIsCompanyAdmin(user),
            isSuperAdmin: userIsSuperAdmin(user),
        });
    } else {
        Sentry.setContext('user', null);
    }
}

function setSentryImpersonateContext(impersonate) {
    if (impersonate !== null) {
        Sentry.setContext('impersonate', {
            active: true,
            impersonateId: impersonate.id,
            impersonateEmail: impersonate.email,
        });
    } else {
        Sentry.setContext('impersonate', {
            active: false,
        });
    }
}

export default {
    state: () => {
        const initialImpersonate = tryToParseImpersonationFromLocalStorage();
        setSentryImpersonateContext(initialImpersonate);

        return {
            user: null,
            async: false,
            errors: null,
            impersonate: initialImpersonate,
            imitableUsers: [],
        };
    },
    mutations: {
        [SET_LOGIN_ASYNC](state, async) {
            state.async = async;
        },
        [SET_LOGIN_ERRORS](state, errors) {
            state.errors = errors;
        },
        [SET_USER](state, user) {
            state.user = user;

            setSentryUserContext(user);
        },
        [UPDATE_ME](state, data) {
            state.user = Object.assign({}, state.user, data);

            setSentryUserContext(state.user);
        },
        [UPDATE_IMPERSONATE](state, data) {
            state.impersonate = Object.assign({}, state.impersonate, data);
            setSentryImpersonateContext(state.impersonate);
            localStorage.setItem(STORAGE_KEYS.IMPERSONATE, JSON.stringify(state.impersonate));
        },
        [SET_IMPERSONATE](state, impersonate) {
            state.impersonate = impersonate;

            setSentryImpersonateContext(impersonate);
        },
        [SET_IMITABLE_USERS](state, users) {
            state.imitableUsers = users;
        },
    },
    actions: {
        async [IMPERSONATE_USER]({ commit, getters }, user) {
            let clonedUser = clone(user);

            if (user?.id === getters.impersonatedUser?.id) {
                // we are already impersonating this user, nothing to do
                return;
            }

            if (clonedUser !== null) {
                clonedUser = await doMe(user?.email);
            }

            commit(SET_IMPERSONATE, clonedUser);
            commit(SELECT_LEAD, '');
            commit(SET_LEADS, null);
            commit(SET_ARCHIVED_LEADS, null);
            commit(SET_PROPERTIES, null);
            commit(SET_ARCHIVED_PROPERTIES, null);
            commit(SET_USER_TEMPLATES, null);
            commit(SET_COMPANY_TEMPLATES, null);
            commit(SET_KEYWORDS, null);

            // save state to localstorage so it will survive reload?
            if (user === null) {
                localStorage.removeItem(STORAGE_KEYS.IMPERSONATE);
            } else {
                localStorage.setItem(STORAGE_KEYS.IMPERSONATE, JSON.stringify(clonedUser));
            }
        },
        async [PERFORM_LOGIN_ACTION]({ commit }, { email, password }) {
            const errors = [];

            if (!email) {
                errors.push('Please enter a email');
            }

            if (!password) {
                errors.push('Please enter a password');
            }

            if (email && !email.includes('@') || email.length === 0) {
                errors.push('Please enter a valid email');
            }

            if (password && password.length < 8) {
                errors.push('Please enter a valid password');
            }

            if (errors.length > 0) {
                commit(SET_LOGIN_ERRORS, errors);
                return;
            }

            commit(SET_LOGIN_ASYNC, true);

            try {
                await doLogin(email, password);

                const me = await doMe();

                commit(SET_LOGIN_ERRORS, null);
                await commit(SET_USER, me);

                if (router.currentRoute.path === '/login') {
                    await router.push('/');
                }
            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            }

            commit(SET_LOGIN_ASYNC, false);
        },

        async [DO_ME_ACTION]({ commit, getters }) {
            commit(SET_LOGIN_ASYNC, true);

            try {
                const me = await doMe();

                if (getters.isImpersonationActive && getters.impersonatedUser) {
                    const impersonateMe = await doMe(getters.impersonatedUser.email);
                    commit(SET_IMPERSONATE, impersonateMe);
                }

                commit(SET_LOGIN_ERRORS, null);
                commit(SET_USER, me);

            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    console.log(ex);
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            }

            commit(SET_LOGIN_ASYNC, false);
        },

        async [FETCH_LIST_FOR_IMPERSONATION]({ commit }) {
            try {
                const response = await fetchUserListForImpersonating();
                commit(SET_IMITABLE_USERS, response['hydra:member']);

            } catch (e) {
                console.error('error while fetching list for impersonation', e);
            }
        },
    },
    getters: {
        isCompanyAdmin(state, getters) {
            return userIsCompanyAdmin(getters.me);
        },
        isSuperAdmin(state, getters) {
            return userIsSuperAdmin(getters.me);
        },
        isImpersonationActive(state) {
            return state.impersonate !== null;
        },
        impersonatedUser(state) {
            return state.impersonate || null;
        },
        companyId(state, getters) {
            return getters.me?.company?.id;
        },
        meId(state, getters) {
            return getters.me?.id;
        },
        me(state) {
            return state?.user;
        },
        imitableUsers(state, getters) {
            if (getters.isImpersonationActive) {
                const u = getters.impersonatedUser;

                return state.imitableUsers.filter(user => user.id !== u.id);
            } else {
                return state.imitableUsers;
            }
        },
    },
};
