const timeout = 5000;
let lastTime = Date.now();

export function registerWakeHandler() {
    setInterval(function () {
        const currentTime = Date.now();
        if (currentTime > (lastTime + timeout + 2000)) {
            window.location.reload();
        }
        lastTime = currentTime;
    }, timeout);
}
