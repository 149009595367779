import Vue from 'vue';

import {
    ADD_NEW_LEAD,
    ADD_PROPERTY_INTEREST_ACTION,
    ADD_SELECTED_LEAD_INTEREST,
    APPEND_ARCHIVED_LEADS_PAGE,
    APPEND_LEADS_PAGE,
    ARCHIVE_LEAD_ACTION,
    EDIT_LEAD_ACTION,
    FETCH_ALL_ARCHIVED_LEADS_ACTION,
    FETCH_ALL_LEADS_ACTION,
    FETCH_ARCHIVED_LEADS_PAGE_ACTION,
    FETCH_LEADS_PAGE_ACTION,
    MARK_LEAD_AS_IMPORTANT_ACTION,
    REMOVE_LEAD,
    SELECT_LEAD,
    MULTI_SELECT_LEAD,
    SELECT_LEAD_ACTION,
    SET_ARCHIVED_LEADS,
    SET_ARCHIVED_LEADS_ASYNC,
    SET_EDIT_LEAD_ASYNC,
    SET_EDIT_LEAD_ERRORS,
    SET_INTEREST_ARCHIVED_ACTION,
    SET_INTEREST_COLLAPSED_ACTION,
    SET_LEADS,
    SET_LEADS_ASYNC,
    SET_LEADS_SEARCH_TEXT,
    SET_SEARCH_LEADS,
    SET_SELECTED_LEAD_INTERESTS,
    SET_SELECTED_LEAD_INTERESTS_ASYNC,
    SET_SELECTED_LEAD_INTERESTS_ERRORS,
    UPDATE_LEAD,
    OPT_OUT_LEAD_ACTION, SET_LEADS_MULTI_SELECT, SET_MULTISELECT_ALL, SET_LEADS_FILTERS, MARK_LEAD_AS_UNREAD,
} from '../actions/leads';
import {
    addManualLead,
    archiveLead,
    editLead,
    fetchArchivedLeadsPage,
    fetchLeadsPage,
    getInterestsForLead,
    markLeadAsImportant, markLeadAsUnread, optOutLead, setInterestArchived, setInterestCollapsed, unarchiveLead,
} from '@/api/leads/leads';
import {
    LEAD_ARCHIVED,
    LEAD_CREATED,
    LEAD_UNARCHIVED,
    LEAD_UPDATED,
    PROPERTY_INTEREST_UPDATED,
} from '../actions/socket_events';
import { SET_MENU_RIGHT_SIDE_NUMBER_FOR_LINK } from '../actions/menu';
import { sortByCreatedAt, sortByLastMessageCreatedAt, sortPropertyInterestsByArchived } from '@/utils/utils';
import {
    ADD_MANUAL_LEAD_ACTION,
    APPEND_SEARCH_LEADS_PAGE,
    FETCH_SINGLE_SELECTED_PROPERTY_INTEREST_ACTION,
    REMOVE_LEAD_FROM_ARCHIVED,
    SEARCH_LEADS_ACTION,
    SET_ADD_LEAD_ASYNC, SET_LEAD_ADD_ERRORS,
    UNARCHIVE_LEAD_ACTION,
    UPDATE_LEAD_ACTION,
    UPDATE_LEAD_INTEREST,
    UPDATE_LEADS_RIGHT_SIDE_NUMBER,
} from '@/store/actions/leads';
import { PROPERTY_INTEREST_CREATED } from '@/store/actions/socket_events';
import { addPropertyInterest, getEnhancedPropertyInterest } from '@/api/properties/properties';
import { PING_READ_ENDPOINT_ACTION } from '@/store/actions/messenger';
import uniqBy from 'lodash/uniqBy';

export default {
    state: () => ({
        leads: null,
        async: false,

        addAsync: false,
        addErrors: null,

        searchText: null,
        searchLeads: null,

        multiSelectActive: false,
        multiSelectIds: null,

        selected: {
            id: '',
            interests: null,
            interestsAsync: false,
            interestsErrors: null,
        },

        filters: {},

        archivedLeads: null,
        archivedAsync: false,

        messages: {},
        messagesAsync: false,

        editAsync: false,
        editErrors: null,
    }),
    mutations: {
        [SET_LEADS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_LEADS](state, leads) {
            state.leads = leads;
        },
        [SET_LEADS_FILTERS](state, filters) {
            state.filters = filters;
        },
        [APPEND_LEADS_PAGE](state, leads, _page) {
            if (state.leads !== null) {
                const intermediateArr = [...state.leads, ...leads];

                state.leads = uniqBy(intermediateArr, (l) => l.id);
            } else {
                state.leads = [...leads];
            }
        },
        [SELECT_LEAD](state, leadId) {
            if (state.selected.id === leadId) {
                state.selected.id = '';
                state.selected.interests = null;
            } else {
                state.selected.id = leadId;
                state.selected.interests = null;
            }
        },
        [MULTI_SELECT_LEAD](state, leadId) {
            let multiSelectIds = state.multiSelectIds ? state.multiSelectIds : [];

            let index = multiSelectIds.findIndex(id => id === leadId);

            if (index > -1) {
                multiSelectIds.splice(index, 1);
            } else {
                multiSelectIds.push(leadId);
            }

            Vue.set(state, 'multiSelectIds', multiSelectIds);
        },
        [UPDATE_LEAD](state, { id, ...data }) {
            for (let lead of state.leads) {
                if (lead.id === id) {
                    Object.assign(lead, data);
                    return;
                }
            }
        },
        [SET_SELECTED_LEAD_INTERESTS](state, interests) {
            state.selected.interests = interests;
        },
        [SET_SELECTED_LEAD_INTERESTS_ASYNC](state, async) {
            state.selected.interestsAsync = async;
        },
        [SET_SELECTED_LEAD_INTERESTS_ERRORS](state, errors) {
            state.selected.interestsErrors = errors;
        },
        [ADD_NEW_LEAD](state, newLead) {
            const index = state.leads.findIndex(lead => lead.id === newLead.id);

            if (index === -1) {
                // we dont have this lead, inject
                state.leads.unshift(newLead);
            }
        },
        [SET_EDIT_LEAD_ASYNC](state, async) {
            state.editAsync = async;
        },
        [SET_EDIT_LEAD_ERRORS](state, errors) {
            state.editErrors = errors;
        },
        [REMOVE_LEAD](state, leadId) {
            if (state.leads !== null) {
                state.leads = state.leads.filter(lead => lead.id !== leadId);
            }
        },
        [REMOVE_LEAD_FROM_ARCHIVED](state, leadId) {
            if (state.archivedLeads !== null) {
                state.archivedLeads = state.archivedLeads.filter(lead => lead.id !== leadId);
            }
        },

        [ADD_SELECTED_LEAD_INTEREST](state, interest) {
            if (state.selected.interests !== null) {
                state.selected.interests.unshift(interest);
            } else {
                state.selected.interests = [interest];
            }
        },

        [APPEND_ARCHIVED_LEADS_PAGE](state, leads, _page) {
            if (state.archivedLeads !== null) {
                state.archivedLeads = state.archivedLeads.concat(leads);
            } else {
                state.archivedLeads = [...leads];
            }
        },

        [APPEND_SEARCH_LEADS_PAGE](state, leads, _page) {
            if (state.searchLeads !== null) {
                state.searchLeads = state.searchLeads.concat(leads);
            } else {
                state.searchLeads = [...leads];
            }
        },

        [SET_ARCHIVED_LEADS_ASYNC](state, async) {
            state.archivedAsync = async;
        },

        [UPDATE_LEAD_INTEREST](state, { interest_id, ...rest }) {
            if (state.selected.interests !== null) {
                for (let interest of state.selected.interests) {
                    if (interest.interest_id === interest_id) {
                        Object.assign(interest, rest);
                        return;
                    }
                }
            }
        },

        [SET_ARCHIVED_LEADS](state, archivedLeads) {
            state.archivedLeads = archivedLeads;
        },

        [SET_SEARCH_LEADS](state, searchLeads) {
            state.searchLeads = searchLeads;
        },

        [SET_LEADS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },

        [SET_ADD_LEAD_ASYNC](state, async) {
            state.addAsync = async;
        },
        [SET_LEAD_ADD_ERRORS](state, errors) {
            state.addErrors = errors;
        },
        [SET_LEADS_MULTI_SELECT](state, multiSelect) {
            if (multiSelect) {
                if (state.selected.id) {
                    state.multiSelectIds = [state.selected.id];
                }
            } else {
                state.multiSelectIds = null;
            }

            state.multiSelectActive = multiSelect;
        },
        [SET_MULTISELECT_ALL](state, { select = true, ids = [] }) {
            if (state.multiSelectActive && select) {
                state.multiSelectIds = [...ids];
            } else {
                state.multiSelectIds = null;
            }
        },
    },
    actions: {
        async [SELECT_LEAD_ACTION]({ getters, commit, dispatch, state }, leadId) {
            const multiSelect = getters.isLeadsMultiselectActive;

            if (!multiSelect && state.selected.id === leadId) {
                if (getters.selectedLead.has_unread_messages) {
                    dispatch(PING_READ_ENDPOINT_ACTION, leadId);
                }
                return;
            }

            commit(multiSelect ? MULTI_SELECT_LEAD : SELECT_LEAD, leadId);

            if (!multiSelect) {
                commit(SET_SELECTED_LEAD_INTERESTS_ASYNC, true);

                try {
                    const { 'hydra:member': interests } = await getInterestsForLead(leadId);
                    if (interests) {
                        commit(SET_SELECTED_LEAD_INTERESTS, interests);
                    }

                    commit(SET_SELECTED_LEAD_INTERESTS_ASYNC, false);
                } catch (ex) {
                    const data = await ex.json();
                    const { 'hydra:description': error } = data;

                    commit(SET_SELECTED_LEAD_INTERESTS_ERRORS, error);
                    commit(SET_SELECTED_LEAD_INTERESTS_ASYNC, false);
                    throw ex;
                }
            }
        },

        async [FETCH_ALL_LEADS_ACTION]({ dispatch, commit }) {
            let page = 0;

            commit(SET_LEADS, []);
            commit(SET_SEARCH_LEADS, null);
            commit(SET_LEADS_ASYNC, true);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_LEADS_PAGE_ACTION, { page });
            }

            dispatch(UPDATE_LEADS_RIGHT_SIDE_NUMBER);

            commit(SET_LEADS_ASYNC, false);
        },

        async [UPDATE_LEADS_RIGHT_SIDE_NUMBER]({ commit, getters }) {
            commit(SET_MENU_RIGHT_SIDE_NUMBER_FOR_LINK, {
                link: '/',
                number: getters.unreadLeads.length,
            });
        },

        async [FETCH_LEADS_PAGE_ACTION]({ commit, state }, { page }) {
            try {
                const searchText = state.searchText;
                const filters = state.filters;

                const {
                    'hydra:view': pagination,
                    'hydra:member': leads,
                } = await fetchLeadsPage(page, searchText, filters);

                if (Array.isArray(leads)) {
                    // good, we have stuff
                    if (searchText === null) {
                        commit(APPEND_LEADS_PAGE, leads, page);
                    } else {
                        commit(APPEND_SEARCH_LEADS_PAGE, leads, page);
                    }
                }

                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Leads Page', ex);
            }
        },

        async [SET_INTEREST_ARCHIVED_ACTION]({ _commit }, { interestId, archived }) {
            try {
                await setInterestArchived(interestId, archived);
            } catch (ex) {
                console.error('Set interest archived', ex);
            }
        },

        async [SET_INTEREST_COLLAPSED_ACTION]({ _commit }, { interestId, collapsed }) {
            try {
                await setInterestCollapsed(interestId, collapsed);
            } catch (ex) {
                console.error('Set interest collapsd', ex);
            }
        },

        [LEAD_UPDATED]({ dispatch }, data) {
            const jsonData = JSON.parse(data);

            dispatch(UPDATE_LEAD_ACTION, jsonData);
        },

        [UPDATE_LEAD_ACTION]({ commit, dispatch }, lead) {
            commit(UPDATE_LEAD, lead);

            dispatch(UPDATE_LEADS_RIGHT_SIDE_NUMBER);
        },

        [LEAD_CREATED]({ commit, dispatch }, data) {
            const jsonData = JSON.parse(data);

            commit(ADD_NEW_LEAD, jsonData);

            dispatch(UPDATE_LEADS_RIGHT_SIDE_NUMBER);
        },

        async [EDIT_LEAD_ACTION]({ commit, dispatch }, { id, ...rest }) {
            commit(SET_EDIT_LEAD_ASYNC, true);

            try {
                const res = await editLead(id, rest);
                dispatch(UPDATE_LEAD_ACTION, res);
            } catch (ex) {
                console.error(ex);
            }

            commit(SET_EDIT_LEAD_ASYNC, false);
        },

        async [ARCHIVE_LEAD_ACTION]({ _commit }, id) {
            try {
                await archiveLead(id);
            } catch (ex) {
                console.error(ex);
                throw ex;
            }
        },

        async [UNARCHIVE_LEAD_ACTION]({ _commit }, id) {
            try {
                await unarchiveLead(id);
            } catch (ex) {
                console.error(ex);
                throw ex;
            }
        },

        async [MARK_LEAD_AS_UNREAD]({ _commit }, id) {
            try {
                await markLeadAsUnread(id);
            } catch (ex) {
                console.error(ex);
                throw ex;
            }
        },

        async [MARK_LEAD_AS_IMPORTANT_ACTION]({ _commit }, id) {
            try {
                await markLeadAsImportant(id);
            } catch (ex) {
                console.error(ex);
                throw ex;
            }
        },

        [LEAD_ARCHIVED]({ commit, dispatch }, data) {
            const jsonData = JSON.parse(data);

            commit(REMOVE_LEAD, jsonData.lead_id);

            dispatch(UPDATE_LEADS_RIGHT_SIDE_NUMBER);
        },

        [PROPERTY_INTEREST_CREATED]({ state, commit }, data) {
            const jsonData = JSON.parse(data);

            console.log(PROPERTY_INTEREST_CREATED, jsonData);

            if (state.selected.id === jsonData.lead_id) {
                // we have this selected, inject interest
                commit(ADD_SELECTED_LEAD_INTEREST, jsonData);
            }
        },

        async [FETCH_ALL_ARCHIVED_LEADS_ACTION]({ commit, dispatch }) {
            let page = 0;

            commit(SET_ARCHIVED_LEADS_ASYNC, true);

            commit(SET_ARCHIVED_LEADS, null);
            commit(SET_SEARCH_LEADS, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_ARCHIVED_LEADS_PAGE_ACTION, { page });
            }

            commit(SET_ARCHIVED_LEADS_ASYNC, false);
        },

        async [FETCH_ARCHIVED_LEADS_PAGE_ACTION]({ commit, state }, { page }) {
            try {
                const searchText = state.searchText;
                const filters = state.filters;

                const {
                    'hydra:view': pagination,
                    'hydra:member': leads,
                } = await fetchArchivedLeadsPage(page, searchText, filters);
                if (Array.isArray(leads)) {
                    // good, we have stuff
                    if (searchText === null) {
                        commit(APPEND_ARCHIVED_LEADS_PAGE, leads, page);
                    } else {
                        commit(APPEND_SEARCH_LEADS_PAGE, leads, page);
                    }
                }

                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Leads Page', ex);
            }
        },

        [PROPERTY_INTEREST_UPDATED]({ commit }, data) {
            const jsonData = JSON.parse(data);

            console.log(PROPERTY_INTEREST_UPDATED, jsonData);

            commit(UPDATE_LEAD_INTEREST, {
                interest_id: jsonData.interest_id,
                archived: jsonData.archived,
                collapsed: jsonData.collapsed,
            });
        },

        [LEAD_UNARCHIVED]({ commit, dispatch }, data) {
            const jsonData = JSON.parse(data);

            commit(ADD_NEW_LEAD, jsonData);
            commit(REMOVE_LEAD_FROM_ARCHIVED, jsonData.id);

            dispatch(UPDATE_LEADS_RIGHT_SIDE_NUMBER);
        },

        async [ADD_PROPERTY_INTEREST_ACTION]({ dispatch }, { propertyId, leadId }) {
            try {
                const res = await addPropertyInterest(leadId, propertyId);

                dispatch(FETCH_SINGLE_SELECTED_PROPERTY_INTEREST_ACTION, res.interest_id);
            } catch (ex) {
                console.log('Error adding property interest');
                console.error(ex);
            }
        },

        async [FETCH_SINGLE_SELECTED_PROPERTY_INTEREST_ACTION]({ commit }, interestId) {
            try {
                const res = await getEnhancedPropertyInterest(interestId);

                commit(ADD_SELECTED_LEAD_INTEREST, res);
            } catch (ex) {
                console.log('Error fetching property interest');
                console.error(ex);
            }
        },

        async [SEARCH_LEADS_ACTION]({ dispatch, commit }, { searchText, isArchive }) {
            if (searchText === null || searchText.length === 0) {
                commit(SET_SEARCH_LEADS, null);
                commit(SET_LEADS_SEARCH_TEXT, null);
            } else {
                commit(SET_LEADS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_LEADS, null);
            }

            if (isArchive) {
                dispatch(FETCH_ALL_ARCHIVED_LEADS_ACTION);
            } else {
                dispatch(FETCH_ALL_LEADS_ACTION);
            }
        },

        async [ADD_MANUAL_LEAD_ACTION]({ commit }, data) {
            commit(SET_ADD_LEAD_ASYNC, true);
            commit(SET_LEAD_ADD_ERRORS, null);

            const errors = [];

            if (!data.phone || data.phone.length < 5) {
                errors.push('A valid phone number is required');
            }

            if (errors.length > 0) {
                commit(SET_ADD_LEAD_ASYNC, false);
                commit(SET_LEAD_ADD_ERRORS, errors);
                throw Error('Validation error');
            }

            try {
                const res = await addManualLead(data);

                commit(ADD_NEW_LEAD, res);
            } catch (ex) {
                console.log('Error fetching property interest', ex);
            }

            commit(SET_ADD_LEAD_ASYNC, false);
        },
        async [OPT_OUT_LEAD_ACTION]({ commit, dispatch }, id) {
            commit(SET_EDIT_LEAD_ASYNC, true);

            try {
                const res = await optOutLead(id);
                dispatch(UPDATE_LEAD_ACTION, res);
            } catch (ex) {
                console.error(ex);
            }

            commit(SET_EDIT_LEAD_ASYNC, false);
        },
    },
    getters: {
        getLeadById: (state) => (id) => {
            const leadIndex = state.leads !== null ? state.leads.findIndex(lead => lead.id === id) : -1;
            const archivedIndex = state.archivedLeads !== null ? state.archivedLeads.findIndex(lead => lead.id === id) : -1;
            const searchIndex = state.searchLeads !== null ? state.searchLeads.findIndex(lead => lead.id === id) : -1;

            if (leadIndex !== -1) {
                return state.leads[leadIndex];
            } else if (archivedIndex !== -1) {
                return state.archivedLeads[archivedIndex];
            } else if (searchIndex !== -1) {
                return state.searchLeads[searchIndex];
            } else {
                return null;
            }
        },
        allLeads(state) {
            if (state.searchLeads !== null) {
                return state.searchLeads;
            }

            if (state.leads !== null) {
                return state.leads.sort(sortByLastMessageCreatedAt);
            } else {
                return [];
            }
        },
        isLeadsMultiselectActive: state => {
            return state.multiSelectActive;
        },
        multiSelectedLeadsIds: (state) => {
            return (state.multiSelectIds || []);
        },
        multiSelectedLeads: (state, getters) => {
            return getters.multiSelectedLeadsIds.map(id => getters.getLeadById(id)).filter(lead => lead !== null);
        },
        selectedLead: (state, getters) => {
            return getters.getLeadById(state.selected.id);
        },
        selectedLeadInterests: state => {
            if (state.selected.interests) {
                return state.selected.interests.sort(sortByCreatedAt).sort(sortPropertyInterestsByArchived);
            } else {
                return [];
            }
        },
        hasLeads(state) {
            return state.leads !== null;
        },
        hasArchivedLeads(state) {
            return state.archivedLeads !== null;
        },
        archivedLeads(state) {
            if (state.searchLeads !== null) {
                return state.searchLeads;
            }

            if (state.archivedLeads !== null) {
                return state.archivedLeads.sort(sortByLastMessageCreatedAt);
            } else {
                return [];
            }
        },
        unreadLeads(state, getters) {
            return getters.allLeads.filter(lead => lead.has_unread_messages === true);
        },
        importantLeads(_state, getters) {
            return getters.allLeads.filter(lead => lead.is_important === true);
        },
    },
};
