<template>
    <div class="media-attachment">
        <div class="delete-btn" @click="$emit('delete')">
            <i class="icon icon-close"/>
        </div>

        <img v-viewer="{rotatable: false, scalable: false}" class="preview" v-if="isImg" :src="url"/>

        <div class="video-preview-container" v-if="isVideo">
            <video class="preview">
                <source :src="url">
            </video>

            <i class="icon icon-play"/>
        </div>
        <div class="pdf-preview" v-if="isPdf">
            <i class="pdf-icon icon icon-pdf_light"/>
            <span class="filename">{{ file.name }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MediaAttachmentPreview',
    props: {
        file: File,
    },

    data() {
        return {
            hover: false,
        };
    },

    computed: {
        isPdf() {
            return this.file.type === 'application/pdf';
        },

        isImg() {
            return this.file.type.startsWith('image/');
        },

        isVideo() {
            return this.file.type.startsWith('video/');
        },

        url() {
            return URL.createObjectURL(this.file);
        },
    },
};
</script>

<style lang="scss" scoped>
.media-attachment {
    width: 56px;
    height: 56px;
    border: 1px solid #7e858d;
    border-radius: 6px;
    position: relative;
    margin-right: 12px;
    margin-top: 12px;

    .video-preview-container {
        height: 100%;

        .icon-play {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            color: #ffffff;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            position: absolute;
        }
    }

    .pdf-preview {
        padding: 2px;
        display: flex;
        flex: 1;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;

        .pdf-icon {
            text-align: center;
            font-size: 20px;
            color: #2a2e36;
        }

        .filename {
            overflow: hidden;
            word-break: break-word;
            white-space: nowrap;
            font-size: 10px;
            color: #2a2e36;
        }
    }

    .delete-btn {
        cursor: pointer;
        top: calc(-50% + 20px);
        right: calc(-50% + 20px);
        position: absolute;
        background-color: #181B29;
        color: #ffffff;
        font-size: 12px;
        border-radius: 52px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        z-index: 100;

        .icon-close {
            cursor: pointer;
        }
    }

    .preview {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
}
</style>
