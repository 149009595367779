<template>
    <div class="messages-settings-tab">
        <div class="async-container" v-if="async">
            <vue-loaders-line-scale/>
        </div>
        <div v-else class="content-container">
            <labeled-text-area name="Welcome text"
                               :error-text="textAreaErrorMessage"
                               v-model="agency_settings_form.property_interest_message"
                               placeholder="Type welcome text here"/>
            <labeled-text-area name="Automated Opt-in message text"
                               :error-text="textAreaErrorMessage"
                               v-model="agency_settings_form.opt_invite_message"
                               placeholder="Type Automated Opt-in message text here"/>

            <div style="margin-top: 16px; margin-bottom: 16px;background-color: #999999; height: 1px;min-height: 1px"/>

            <labeled-text-area name="Before manual property url"
                               v-model="agency_settings_form.before_manual_property_url"
                               placeholder="Before manual property URL"/>
            <labeled-text-area name="After manual property URL"
                               v-model="agency_settings_form.after_manual_property_url"
                               placeholder="After manual property URL"/>

            <div style="margin-top: 16px; margin-bottom: 16px;background-color: #999999; height: 1px;min-height: 1px"/>

            <labeled-text-area name="Custom Url Response Text"
                               v-model="agency_settings_form.generic_url_message"
                               placeholder="Custom Url Response Text"/>

            <div style="margin-top: 16px; margin-bottom: 16px;background-color: #999999; height: 1px;min-height: 1px"/>

            <labeled-text-area name="Landing Page Response Text"
                               v-model="agency_settings_form.landing_page_lead_message"
                               placeholder="Landing Page Response Text"/>

            <el-button :loading="saveAsync"
                       @click="submit"
                       type="text"
                       style="align-self: flex-end">
                SAVE
            </el-button>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in saveErrors" :key="error">{{ error }}</p>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
    FETCH_COMPANY_AGENCY_SETTINGS_ACTION,
    SAVE_AGENCY_SETTINGS_ACTION,
} from '@/store/actions/settings/company_settings';
import { cloneDeep } from 'lodash';
import LabeledTextArea from '@/components/LabeledTextArea/LabeledTextArea';
import { difference } from '@/utils/utils';

export default {
    name: 'MessagesSettings',
    components: { LabeledTextArea },
    methods: {
        ...mapActions({
            fetchCompanyAgencySettings: FETCH_COMPANY_AGENCY_SETTINGS_ACTION,
            saveAgencySettings: SAVE_AGENCY_SETTINGS_ACTION,
        }),
        submit() {

            console.log(this.companyAgencySettings)

            const sendObj = {
                agencySettingsId: this.companyAgencySettings.id,
            };

            let shouldSend = false;

            let agencyDiff = difference(this.agency_settings_form, this.companyAgencySettings);

            if (JSON.stringify(agencyDiff) !== '{}') {
                sendObj.updatedValues = agencyDiff;
                shouldSend = true;
            }

            if (shouldSend) {
                this.saveAgencySettings(sendObj).then(() => {
                    this.$swal({
                        text: 'Success',
                        icon: 'success',
                        timer: 1000,
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false,
                    });
                })
                    .catch(() => {
                        this.$swal({
                            text: 'Error',
                            icon: 'error',
                        });
                    });
            }
        },
    },
    data() {
        return {
            textAreaErrorMessage: null,

            agency_settings_form: {
                opt_invite_message: null,
                property_interest_message: null,
                before_manual_property_url: null,
                after_manual_property_url: null,
                generic_url_message: null,
            },
        };
    },
    watch: {
        agency_settings_form: {
            handler(newForm) {
                let totalChars = 0;

                if (newForm.opt_invite_message) {
                    totalChars += newForm.opt_invite_message.length;
                }

                if (newForm.property_interest_message) {
                    totalChars += newForm.property_interest_message.length;
                }

                if (totalChars > 140) {
                    this.textAreaErrorMessage = 'TEXT IS TOO LONG';
                } else {
                    this.textAreaErrorMessage = null;
                }
            },
            deep: true,
        },
        companyAgencySettings(newSettings) {
            this.agency_settings_form = cloneDeep(newSettings);
        },
    },
    computed: {
        companyAgencySettings() {
            return this.$store.state.company_settings.agencySettings;
        },
        async() {
            return this.$store.state.company_settings.agencySettingsAsync;
        },
        saveAsync() {
            return this.$store.state.company_settings.saveAsync;
        },
        saveErrors() {
            return this.$store.state.company_settings.saveErrors;
        },
        companyId() {
            return this.$store.getters.companyId;
        },
    },
    mounted() {
        this.fetchCompanyAgencySettings(this.companyId);
    },
};
</script>

<style lang="scss" scoped>
.messages-settings-tab {
    display: flex;
    flex: 1;
    flex-direction: row;

    .async-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        width: 25%;
        padding: 20px;
        padding-top: 56px;
    }
}
</style>
