<template>
    <div class="edit-property-interest" :class="{archived: isInterestArchived}">
        <div class="edit-property-interest-header">
            <div class="name" v-if="Boolean(interest.first_name) || Boolean(interest.last_name)">
                {{ interest | mergeName }}
            </div>
            <div class="name" v-else>
                {{ interest.phone | phone }}
            </div>
            <div class="actions" v-if="!isEditMode">
                <div v-if="!hasNote && !isEditMode && !isInterestArchived"
                     v-tooltip="'Add Note'"
                     @click="isEditMode = true"
                     class="action add-action">
                    <i class="icon icon-addnew"/>
                </div>
                <div @click="isEditMode = true"
                     v-if="hasNote && !isInterestArchived"
                     v-tooltip="'Edit'"
                     class="action edit-action">
                    <i class="icon icon-actions_edit"/>
                </div>
                <div v-tooltip="'Archive Interest'"
                     v-if="!isInterestArchived"
                     @click="archiveInterest"
                     class="action delete-action">
                    <i class="icon icon-actions_delete"/>
                </div>
                <div v-tooltip="'Unarchive Interest'"
                     v-else
                     @click="unarchiveInterest"
                     class="action unarchive-action">
                    <i class="icon icon-refresh"/>
                </div>
            </div>
        </div>
        <div v-if="isEditMode" class="edit-property-interest-note-container">
            <textarea rows="5"
                      v-model="form.note"/>


            <div class="buttons-container">
                <el-button class="cancel-btn" @click="cancelEdit" type="text">
                    CANCEL
                </el-button>

                <el-button :loading="saveNoteAsync" @click="saveNote" type="text">
                    SAVE
                </el-button>
            </div>
        </div>
        <div v-if="hasNote && !isEditMode && !isInterestArchived" class="edit-property-interest-note-container">
            <span class="note-text">
                <v-clamp ref="clampRef" autoresize :expanded="false" :max-lines="3">
                    {{ interest.note }}
                </v-clamp>
            </span>
            <div v-if="isNoteClamped" class="note-clamp-toggle" @click="toggleClamp"
                 :class="{expanded: isNoteExpanded}">
                <i class="icon icon-down"/>
            </div>
        </div>
    </div>
</template>

<script>
import { clone } from 'lodash';
import { EDIT_SELECTED_PROPERTY_INTEREST_ACTION } from '@/store/actions/properties';
import { difference, showAreYouSureSwal } from '@/utils/utils';
import { mapActions } from 'vuex';
import { SET_INTEREST_ARCHIVED_ACTION } from '@/store/actions/leads';
import VClamp from 'vue-clamp';

export default {
    name: 'EditPropertyInterest',
    props: {
        interest: Object,
    },
    data() {
        return {
            form: clone(this.interest),
            isEditMode: false,
            isNoteClamped: false,
            isNoteExpanded: null,
        };
    },
    components: {
        VClamp,
    },
    methods: {
        ...mapActions({
            editSelectedPropertyInterest: EDIT_SELECTED_PROPERTY_INTEREST_ACTION,
            setInterestArchivedAction: SET_INTEREST_ARCHIVED_ACTION,
        }),
        cancelEdit() {
            this.form = clone(this.interest);
            this.isEditMode = false;
        },
        saveNote() {
            this.editSelectedPropertyInterest({ id: this.interest.interest_id, ...difference(this.form, this.interest) }).then(() => {
                this.$nextTick(function () {
                    this.form = clone(this.interest);
                    this.isEditMode = false;

                    this.$nextTick(function () {
                        this.updateIsClamped();
                    });
                });
            });
        },
        archiveInterest() {
            showAreYouSureSwal(this.$swal).then(result => {
                if (result.value) {
                    this.setInterestArchivedAction({
                        interestId: this.interest.interest_id,
                        archived: true,
                    });
                }
            });
        },
        unarchiveInterest() {
            showAreYouSureSwal(this.$swal).then(result => {
                if (result.value) {
                    this.setInterestArchivedAction({
                        interestId: this.interest.interest_id,
                        archived: false,
                    });
                }
            });
        },
        toggleClamp() {
            this.$refs.clampRef.toggle();
            this.isNoteExpanded = !this.isNoteExpanded;
        },

        updateIsClamped() {
            if (this.$refs.clampRef) {
                const isClamped = this.$refs.clampRef.isClamped;
                this.isNoteClamped = isClamped;

                if (isClamped) {
                    this.isNoteExpanded = this.$refs.clampRef.expanded;
                }
            }
        },
    },
    mounted() {
        this.updateIsClamped();
    },
    computed: {
        hasNote() {
            return this.interest.note !== null;
        },
        isInterestArchived() {
            return this.interest.archived === true;
        },
        saveNoteAsync() {
            return this.$store.state.properties.selectedPropertyInterestsEditAsync;
        },
    },
};
</script>

<style lang="scss" scoped>
.edit-property-interest {
    padding: 14px 6px;
    margin-left: 23px;
    margin-right: 23px;
    box-shadow: inset 0 -1px 0 0 #b5b7ba;

    &.archived {
        opacity: 0.3;
    }

    .edit-property-interest-header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .name {
            color: #ffffff;
            font-weight: 500;
            font-size: 14px;
        }

        .actions {
            display: flex;

            .action {
                margin-left: 14px;
                cursor: pointer;
            }

            i {
                font-size: 20px;
                color: #b5b7ba;
                transition: all 300ms ease-in-out;

                &:hover {
                    color: #13A68A;
                }
            }
        }
    }

    .edit-property-interest-note-container {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        word-break: break-all;
        white-space: normal;

        .note-clamp-toggle {
            display: flex;
            width: fit-content;
            align-self: flex-end;
            justify-content: flex-end;
            margin-top: 8px;
            font-size: 13px;
            color: #b5b7ba;
            cursor: pointer;
            transition: all 300ms linear;

            &.expanded {
                transform: rotate(180deg);
                color: #13A68A;
            }
        }

        .note-text {
            color: #b5b7ba;
            font-size: 13px;

            .read-more {
                color: #F4F7FC;
                cursor: pointer;
            }
        }

        .buttons-container {
            margin-top: 8px;
            align-self: flex-end;
            text-align: end;

            .cancel-btn {
                color: #b5b7ba;
                transition: all 300ms ease-in-out;

                &:hover {
                    color: #13A68A;
                }
            }
        }

        textarea {
            resize: none;
        }
    }
}
</style>
