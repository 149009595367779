<template>
    <div class="add-manual-lead-modal-container">

        <div class="add-lead-container" @click="modalOpen = true">
            <div class="plus-container">
                <i class="icon icon-plus"/>
            </div>
            <span class="add-lead-text">Add New</span>
        </div>

        <el-dialog title="Add Manual Lead"
                   :visible.sync="modalOpen">
            <labeled-input name="Full Name"
                           v-model="form.fullName"
                           placeholder="Full Name"/>

            <labeled-cleave name="Phone"
                            required
                            :options="{
                                phone: true,
                                phoneRegionCode: 'US',
                            }"
                            v-model="form.phone"
                            placeholder="Phone"/>

            <labeled-input name="Note"
                           v-model="form.note"
                           placeholder="Note"/>

            <!--            <labeled-select name="Property"-->
            <!--                            required-->
            <!--                            v-model="form.propertyId"-->
            <!--                            placeholder="Property">-->
            <!--                <el-option-->
            <!--                    v-for="property in properties"-->
            <!--                    :key="property.id"-->
            <!--                    :label="property.name"-->
            <!--                    :value="property.id">-->
            <!--                </el-option>-->
            <!--            </labeled-select>-->

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
            </div>

            <span class="footer" slot="footer">
                <el-button type="text"
                           class="close-btn"
                           @click="modalOpen = false">
                    Close
                </el-button>
                <el-button :loading="async"
                           type="text"
                           @click="onSubmit">
                    Save
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import LabeledInput from '@/components/LabeledInput/LabeledInput';
import { FETCH_ALL_PROPERTIES_ACTION } from '@/store/actions/properties';
import { mapActions } from 'vuex';
import { splitNameIntoParts } from '@/utils/utils';
import { ADD_MANUAL_LEAD_ACTION } from '@/store/actions/leads';
import { cloneDeep } from 'lodash';
import LabeledCleave from '@/components/LabeledCleave/LabeledCleave';

const getDefaultForm = () => ({
    fullName: '',
    note: '',
    phone: '',
    propertyId: null,
});

export default {
    name: 'AddManualLeadModal',
    components: { LabeledCleave, LabeledInput },
    data() {
        return {
            modalOpen: false,

            form: {
                fullName: '',
                note: '',
                phone: '',
                propertyId: null,
            },
        };
    },
    mounted() {
        if (!this.hasProperties && !this.propertiesAsync) {
            this.fetchAllProperties();
        }
    },
    watch: {
        modalOpen: function (newOpen) {
            if (!newOpen) {
                this.form = getDefaultForm();
            }
        },
    },
    methods: {
        ...mapActions({
            fetchAllProperties: FETCH_ALL_PROPERTIES_ACTION,
            addNewLeadAction: ADD_MANUAL_LEAD_ACTION,
        }),
        onSubmit() {
            const data = cloneDeep(this.form);
            const splitName = splitNameIntoParts(data.fullName);

            if (!data.phone.startsWith('+1')) {
                data.phone = `+1${data.phone}`;
            }

            const sendData = {
                ...splitName,
                phone: data.phone,
                note: data.note,
                // property_id: data.propertyId,
            };

            this.addNewLeadAction(sendData).then(() => {
                this.$nextTick(function () {
                    this.form = getDefaultForm();
                    this.modalOpen = false;
                });
            });
        },
    },
    computed: {
        async() {
            return this.$store.state.leads.addAsync;
        },
        errors() {
            return this.$store.state.leads.addErrors;
        },
        properties() {
            return this.$store.state.properties.properties;
        },
        propertiesAsync() {
            return this.$store.state.properties.async;
        },
        hasProperties() {
            return this.$store.getters.hasProperties;
        },
    },
};
</script>

<style lang="scss" scoped>
.add-manual-lead-modal-container {

    .add-lead-container {
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #44515d;
        cursor: pointer;
        font-weight: 500;

        &:hover {
            color: #13A68A;

            .plus-container {
                border: 1px solid #13A68A;
            }
        }

        .plus-container {
            height: 40px;
            width: 40px;
            border-radius: 50px;
            border: 1px solid #44515d;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .add-lead-text {
            margin-left: 22px;
            font-size: 14px;
            white-space: nowrap;
        }
    }

    .footer {
        text-align: left !important;

        .close-btn {
            margin-right: 18px;
            color: #b5b7ba;
            transition: all 300ms ease-in-out;

            &:hover {
                color: #13A68A;
            }
        }
    }
}
</style>
