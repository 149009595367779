export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_MESSAGES_ASYNC = 'SET_MESSAGES_ASYNC';
export const APPEND_MESSAGES_PAGE = 'APPEND_MESSAGES_PAGE';
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const FETCH_ALL_MESSAGES_ACTION = 'FETCH_ALL_MESSAGES_ACTION';

export const SET_ABORT_SIGNAL = 'SET_ABORT_SIGNAL';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';

export const FETCH_MESSAGES_PAGE_ACTION = 'FETCH_MESSAGES_PAGE_ACTION';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MEDIA_MESSAGE = 'SEND_MEDIA_MESSAGE';
export const SEND_CSI_SURVEY_MESSAGE = 'SEND_CSI_SURVEY_MESSAGE';
export const RESET_MESSENGER_STATE = 'RESET_MESSENGER_STATE';
export const SET_MESSENGER_SENDING_ASYNC = 'SET_MESSENGER_SENDING_ASYNC';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const MESSENGER_SET_CURRENT_ENTITY_ID = 'MESSENGER_SET_CURRENT_ENTITY_ID';
export const PING_READ_ENDPOINT_ACTION = 'PING_READ_ENDPOINT_ACTION';
