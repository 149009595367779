<template>
    <div class="company-settings-tab">
        <div class="async-container" v-if="async">
            <vue-loaders-line-scale/>
        </div>
        <div v-else class="content-container">
            <labeled-input v-model="company.name" placeholder="Type name here" name="Name"/>
            <labeled-input v-model="company.email" placeholder="Type Email here" name="Email"/>

            <labeled-text-area v-model="company.csi_survey_text" placeholder="CSI Survey Text" name="CSI Survey Text"/>

            <el-button :loading="saveAsync"
                       @click="submit"
                       type="text"
                       style="align-self: flex-end">
                SAVE
            </el-button>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in saveErrors" :key="error">{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {
    FETCH_COMPANY_SETTINGS_ACTION, SAVE_COMPANY_SETTINGS_ACTION,
} from '@/store/actions/settings/company_settings';
import { mapActions } from 'vuex';
import LabeledInput from '../../../../components/LabeledInput/LabeledInput';
import { difference } from '@/utils/utils';
import { cloneDeep } from 'lodash';
import LabeledTextArea from '@/components/LabeledTextArea/LabeledTextArea.vue';

export default {
    name: 'CompanySettings',
    components: { LabeledTextArea, LabeledInput },
    data() {
        return {
            company: {
                name: '',
                email: '',
                csi_survey_text: '',
            },
        };
    },
    watch: {
        companySettings(newSettings) {
            this.company = cloneDeep(newSettings);
        },
    },
    methods: {
        ...mapActions({
            fetchCompanySettings: FETCH_COMPANY_SETTINGS_ACTION,
            saveCompanySettingsAction: SAVE_COMPANY_SETTINGS_ACTION,
        }),
        submit() {
            const sendObj = {
                companyId: this.companyId,
            };

            let shouldSend = false;


            let companyDiff = difference(this.company, this.companySettings);

            if (JSON.stringify(companyDiff) !== '{}') {
                sendObj.company = companyDiff;
                shouldSend = true;
            }

            if (shouldSend) {
                this.saveCompanySettingsAction(sendObj);
            }
        },
    },
    mounted() {
        this.fetchCompanySettings(this.companyId);
    },
    computed: {
        companyId() {
            return this.$store.getters.companyId;
        },
        async() {
            return this.$store.state.company_settings.companyAsync;
        },
        saveAsync() {
            return this.$store.state.company_settings.saveAsync;
        },
        saveErrors() {
            return this.$store.state.company_settings.saveErrors;
        },
        companySettings() {
            return this.$store.state.company_settings.company;
        },
    },
};
</script>

<style lang="scss" scoped>
.company-settings-tab {
    display: flex;
    flex: 1;
    flex-direction: row;

    .async-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        width: 25%;
        padding: 20px;
        padding-top: 56px;
    }
}
</style>
