<template>
    <div class="conversation-section">
        <Details/>
        <Messenger for-entity="leads" :entity-id="selectedLeadId"/>
    </div>
</template>

<script>
import Details from './Details/Details';
import Messenger from '../Messenger/Messenger';
import { RESET_MESSENGER_STATE } from '@/store/actions/messenger';
import { mapMutations } from 'vuex';

export default {
    name: 'ConversationSection',
    components: { Messenger, Details },
    methods: {
        ...mapMutations({
            clearMessengerState: RESET_MESSENGER_STATE
        })
    },
    computed: {
        selectedLeadId() {
            return this.$store.state.leads.selected.id;
        },
    },
    watch: {
        selectedLeadId(newLeadId, oldLeadId) {
            if (newLeadId !== oldLeadId) {
                this.clearMessengerState();
            }
        }
    }
};
</script>

<style lang="scss">
.conversation-section {
    display: flex;
    width: 67%;
    height: 100%;
}
</style>
