import Vue from 'vue';

import {
    SET_MENU_IS_COLLAPSED,
    SET_MENU_RIGHT_SIDE_NUMBER_FOR_LINK,
} from '../actions/menu';

export default {
    state: () => ({
        groups: [{
            name: '',
            icon: 'icon icon-menu',
            items: [{
                link: '/',
                name: 'My Leads',
                icon: 'icon icon-mylistings',
            }, {
                link: '/listings',
                name: 'Listings',
                icon: 'icon icon-listings',
            }, {
                link: '/archived-listings',
                name: 'Archived Listings',
                icon: 'icon icon-listings_archive',
            }, {
                link: '/companies',
                name: 'Companies',
                icon: 'icon icon-addnew',
                meta: {
                    requiresSuperAdmin: true,
                },
            }, {
                link: '/users',
                name: 'Users',
                icon: 'icon icon-addnew',
                meta: {
                    requiresSuperAdmin: true,
                },
            }, {
                link: '/keywords',
                name: 'Keywords',
                icon: 'icon icon-addnew',
                meta: {
                    requiresSuperAdmin: true,
                },
            }, {
                link: '/company-users',
                name: 'Users',
                icon: 'icon icon-addnew',
                meta: {
                    requiresCompanyAdmin: true,
                },
            }, {
                link: '/settings',
                name: 'Company Settings',
                icon: 'icon icon-settings',
                meta: {
                    requiresCompanyAdmin: true,
                },
            }, {
                link: '/user-settings',
                name: 'Settings',
                icon: 'icon icon-settings',
            }],
        }],
        isCollapsed: window.matchMedia('(max-width: 1280px)').matches,
    }),
    mutations: {
        [SET_MENU_IS_COLLAPSED](state, isCollapsed) {
            state.isCollapsed = isCollapsed;
        },
        [SET_MENU_RIGHT_SIDE_NUMBER_FOR_LINK](state, { link, number }) {
            for (let group of state.groups) {
                for (let item of group.items) {
                    if (item.link === link) {
                        Vue.set(item, 'rightSideNumber', number);
                        return;
                    }
                }
            }
        },
    },
    actions: {},
    getters: {
        getActiveRouteItem: (state) => (activeItemPath) => {
            return state.groups.map(g => g.items).flat().find(i => i.link === activeItemPath);
        }
    },
};
