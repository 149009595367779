<template>
    <div class="all-properties-tabs-container">
        <add-new-property-tab/>
    </div>
</template>

<script>
import AddNewPropertyTab from './Tabs/AddNewPropertyTab/AddNewPropertyTab';

export default {
    name: 'AllPropertiesTabs',
    components: { AddNewPropertyTab }
};
</script>

<style lang="scss" scoped>

.all-properties-tabs-container {
    background-color: #12131F;
    color: #ffffff;
}

</style>
