import {
    ADD_COMPANY_TEMPLATE,
    ADD_NEW_TEMPLATE_ACTION,
    ADD_USER_TEMPLATE,
    DELETE_USER_TEMPLATE_ACTION,
    EDIT_USER_TEMPLATE_ACTION,
    FETCH_USER_TEMPLATES_ACTION,
    REMOVE_USER_TEMPLATE,
    SET_COMPANY_TEMPLATES,
    SET_COMPANY_TEMPLATES_ASYNC,
    SET_TEMPLATES_ADD_ASYNC,
    SET_TEMPLATES_EDIT_ASYNC,
    SET_USER_TEMPLATES,
    SET_USER_TEMPLATES_ASYNC,
    UPDATE_USER_TEMPLATE,
    UPDATE_COMPANY_TEMPLATE,
    REMOVE_COMPANY_TEMPLATE,
    FETCH_COMPANY_TEMPLATES_ACTION, DELETE_COMPANY_TEMPLATE_ACTION, EDIT_COMPANY_TEMPLATE_ACTION,
} from '@/store/actions/templates';
import {
    addNewCompanyTemplate,
    addNewTemplate,
    deleteTemplate,
    editTemplate,
    fetchCompanyTemplates,
    fetchUserTemplates,
} from '@/api/templates/templates';

export default {
    state: () => ({
        userTemplates: null,
        userTemplatesAsync: false,

        companyTemplates: null,
        companyTemplatesAsync: false,

        addAsync: false,
        editAsync: false,
    }),
    mutations: {

        [SET_COMPANY_TEMPLATES_ASYNC](state, async) {
            state.companyTemplatesAsync = async;
        },
        [SET_COMPANY_TEMPLATES](state, templates) {
            state.companyTemplates = templates;
        },
        [ADD_COMPANY_TEMPLATE](state, template) {
            if (state.companyTemplates !== null) {
                state.companyTemplates.unshift(template);
            }
        },
        [UPDATE_COMPANY_TEMPLATE](state, { id, ...rest }) {
            if (state.companyTemplates !== null) {
                for (let template of state.companyTemplates) {
                    if (template.id === id) {
                        Object.assign(template, rest);
                    }
                }
            }
        },
        [REMOVE_COMPANY_TEMPLATE](state, id) {
            if (state.companyTemplates !== null) {
                state.companyTemplates = state.companyTemplates.filter(template => template.id !== id);
            }
        },

        [SET_USER_TEMPLATES_ASYNC](state, async) {
            state.userTemplatesAsync = async;
        },
        [SET_USER_TEMPLATES](state, templates) {
            state.userTemplates = templates;
        },
        [SET_TEMPLATES_EDIT_ASYNC](state, async) {
            state.editAsync = async;
        },
        [SET_TEMPLATES_ADD_ASYNC](state, async) {
            state.addAsync = async;
        },
        [ADD_USER_TEMPLATE](state, template) {
            if (state.userTemplates !== null) {
                state.userTemplates.unshift(template);
            }
        },
        [UPDATE_USER_TEMPLATE](state, { id, ...rest }) {
            if (state.userTemplates !== null) {
                for (let template of state.userTemplates) {
                    if (template.id === id) {
                        Object.assign(template, rest);
                    }
                }
            }
        },
        [REMOVE_USER_TEMPLATE](state, id) {
            if (state.userTemplates !== null) {
                state.userTemplates = state.userTemplates.filter(template => template.id !== id);
            }
        },
    },
    actions: {
        async [ADD_NEW_TEMPLATE_ACTION]({ commit }, text) {
            commit(SET_TEMPLATES_ADD_ASYNC, true);

            try {
                const res = await addNewTemplate(text);

                commit(ADD_USER_TEMPLATE, res);
            } catch (ex) {
                console.error('add template', ex);
            }

            commit(SET_TEMPLATES_ADD_ASYNC, false);
        },
        async [ADD_COMPANY_TEMPLATE]({ commit }, text) {
            commit(SET_TEMPLATES_ADD_ASYNC, true);

            try {
                const res = await addNewCompanyTemplate(text);

                commit(ADD_COMPANY_TEMPLATE, res);
            } catch (ex) {
                console.error('add template', ex);
            }

            commit(SET_TEMPLATES_ADD_ASYNC, false);
        },
        async [FETCH_COMPANY_TEMPLATES_ACTION]({ commit }) {
            commit(SET_COMPANY_TEMPLATES_ASYNC, true);

            try {
                const res = await fetchCompanyTemplates();

                commit(SET_COMPANY_TEMPLATES, res);
            } catch (ex) {
                console.error('Error fetching company templates', ex);
            }

            commit(SET_COMPANY_TEMPLATES_ASYNC, false);
        },
        async [FETCH_USER_TEMPLATES_ACTION]({ commit }) {
            commit(SET_USER_TEMPLATES_ASYNC, true);

            try {
                const res = await fetchUserTemplates();

                commit(SET_USER_TEMPLATES, res);
            } catch (ex) {
                console.error('Error fetching user templates', ex);
            }

            commit(SET_USER_TEMPLATES_ASYNC, false);
        },
        async [EDIT_COMPANY_TEMPLATE_ACTION]({ commit }, { id, ...data }) {
            commit(SET_TEMPLATES_EDIT_ASYNC, true);

            try {
                const res = await editTemplate(id, data);

                commit(UPDATE_COMPANY_TEMPLATE, res);
            } catch (ex) {
                console.error('Error editing company templates', ex);
            }

            commit(SET_TEMPLATES_EDIT_ASYNC, false);
        },
        async [EDIT_USER_TEMPLATE_ACTION]({ commit }, { id, ...data }) {
            commit(SET_TEMPLATES_EDIT_ASYNC, true);

            try {
                const res = await editTemplate(id, data);

                commit(UPDATE_USER_TEMPLATE, res);
            } catch (ex) {
                console.error('Error editing user templates', ex);
            }

            commit(SET_TEMPLATES_EDIT_ASYNC, false);
        },
        async [DELETE_COMPANY_TEMPLATE_ACTION]({ commit }, id) {
            try {
                await deleteTemplate(id);

                commit(REMOVE_COMPANY_TEMPLATE, id);
            } catch (ex) {
                console.error('error deleting template', id, ex);
            }
        },
        async [DELETE_USER_TEMPLATE_ACTION]({ commit }, id) {
            try {
                await deleteTemplate(id);

                commit(REMOVE_USER_TEMPLATE, id);
            } catch (ex) {
                console.error('error deleting template', id, ex);
            }
        },
    },
    getters: {
        companyTemplates: (state) => {
            return state?.companyTemplates || [];
        },
        userTemplates: (state) => {
            return state?.userTemplates || [];
        },
        hasUserTemplates: (state) => {
            return state?.userTemplates !== null;
        },
        hasCompanyTemplates: (state) => {
            return state?.companyTemplates !== null;
        },
        hasAnyTemplates: (_state, getters) => {
            return getters.hasCompanyTemplates || getters.hasUserTemplates;
        }
    },
};
