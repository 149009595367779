<template>
    <div class="company-users-header">
        <add-user-dialog-wrapper v-if="isSuperAdmin"/>
    </div>
</template>

<script>
import AddUserDialogWrapper from '../AddUserDialogWrapper/AddUserDialogWrapper';
export default {
    name: 'CompanyUsersHeader',
    components: { AddUserDialogWrapper },
    computed: {
        isSuperAdmin() {
            return this.$store.getters.isSuperAdmin;
        }
    }
};
</script>

<style lang="scss" scoped>
.company-users-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #12131F;
    height: 55px;
    padding-left: 24px;
    padding-right: 24px;
}
</style>
