export const SET_LOGIN_ASYNC = 'SET_LOGIN_ASYNC';
export const SET_LOGIN_ERRORS = 'SET_LOGIN_ERRORS';
export const SET_USER = 'SET_USER';

export const PERFORM_LOGIN_ACTION = 'PERFORM_LOGIN_ACTION';
export const DO_ME_ACTION = 'DO_ME_ACTION';
export const UPDATE_ME = 'UPDATE_ME';

export const UPDATE_IMPERSONATE = 'UPDATE_IMPERSONATE';

export const IMPERSONATE_USER = 'IMPERSONATE_USER';
export const SET_IMPERSONATE = 'SET_IMPERSONATE';

export const FETCH_LIST_FOR_IMPERSONATION = 'FETCH_LIST_FOR_IMPERSONATION';
export const SET_IMITABLE_USERS = 'SET_IMITABLE_USERS';
