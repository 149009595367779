<template>
    <div class="page archived-properties">
        <div class="archived-properties-content">
            <div class="search-container">
                <search-bar @change="searchArchivedProperties"/>
            </div>
            <archived-properties-table/>
        </div>
    </div>
</template>

<script>

import ArchivedPropertiesTable from '@/views/ArchivedProperties/ArchivedPropertiesTable/ArchivedPropertiesTable';
import SearchBar from '@/components/SearchBar/SearchBar';
import { SEARCH_PROPERTIES_ACTION } from '@/store/actions/properties';
import { mapActions } from 'vuex';

export default {
    name: 'ArchivedProperties',
    components: { SearchBar, ArchivedPropertiesTable },
    methods: {
        ...mapActions({
            searchProperties: SEARCH_PROPERTIES_ACTION,
        }),
        searchArchivedProperties(text) {
            this.searchProperties({ searchText: text, isArchive: true });
        },
    },
};
</script>

<style lang="scss">
.archived-properties {
    .archived-properties-content {
        .search-container {
            .icon-search {
                font-size: 16px;
                color: #ffffff;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.archived-properties {
    background-color: #1E2234;

    .archived-properties-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .search-container {
            margin: 24px;
            height: 46px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }
}
</style>
