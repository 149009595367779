<template>
    <div class="page add-new-property">
        <div class="add-new-property-content">
            <all-properties-tabs/>
            <all-properties-table/>
        </div>
        <property-sidebar/>
    </div>
</template>

<script>
import AllPropertiesTable from './components/AllPropertiesTable/AllPropertiesTable';
import AllPropertiesTabs from './components/AllPropertiesTabs/AllPropertiesTabs';
import PropertySidebar from '@/views/AddNewProperty/components/PropertySidebar/PropertySidebar';

export default {
    name: 'AddNewListing',
    components: { AllPropertiesTabs, AllPropertiesTable, PropertySidebar },
};
</script>

<style scoped lang="scss">
.add-new-property {
    .add-new-property-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
</style>
