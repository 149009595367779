import {
    ADD_NEW_PROPERTY, APPEND_ARCHIVED_PROPERTIES_PAGE,
    APPEND_NEW_PROPERTY,
    APPEND_PROPERTIES_PAGE, ARCHIVE_PROPERTY_ACTION, ASSIGN_KEYWORD_TO_PROPERTY_ACTION,
    DELETE_PROPERTY,
    EDIT_PROPERTY_ACTION, FETCH_ALL_ARCHIVED_PROPERTIES_ACTION,
    FETCH_ALL_PROPERTIES_ACTION, FETCH_ARCHIVED_PROPERTIES_PAGE_ACTION,
    FETCH_PROPERTIES_PAGE_ACTION,
    FETCH_SELECTED_PROPERTY_INTERESTS,
    REMOVE_PROPERTY, REMOVE_PROPERTY_FROM_ARCHIVED, SEARCH_PROPERTIES_ACTION,
    SET_ADD_PROPERTY_ASYNC, SET_ARCHIVED_PROPERTIES, SET_ARCHIVED_PROPERTIES_ASYNC,
    SET_EDIT_PROPERTY_ASYNC,
    SET_EDIT_PROPERTY_ERRORS,
    SET_EDIT_SELECTED_PROPERTY_INTEREST_ASYNC,
    SET_PROPERTIES,
    SET_PROPERTIES_ASYNC,
    SET_PROPERTIES_ERRORS,
    SET_PROPERTIES_SEARCH_TEXT,
    SET_PROPERTY_SIDEBAR_OPEN,
    SET_SELECTED_PROPERTY,
    SET_SELECTED_PROPERTY_INTERESTS,
    SET_SELECTED_PROPERTY_INTERESTS_ASYNC, UNARCHIVE_PROPERTY_ACTION,
    UPDATE_PROPERTY,
    UPDATE_SELECTED_PROPERTY_INTEREST,
} from '../actions/properties';
import {
    addNewProperty, archiveProperty, assignKeywordToProperty, changePropertyAgent,
    deleteProperty,
    editProperty, editPropertyInterest, fetchArchivedPropertiesPage,
    fetchPropertiesPage,
    getPropertyInterestedLeads, unarchiveProperty,
} from '@/api/properties/properties';
import {
    APPEND_ARCHIVED_PROPERTY, CHANGE_PROPERTY_AGENT_ACTION,
    DESELECT_PROPERTY,
    EDIT_SELECTED_PROPERTY_INTEREST_ACTION,
    SELECT_PROPERTY_ACTION, SET_SEARCH_PROPERTIES,
} from '@/store/actions/properties';
import {
    PROPERTY_AGENT_CHANGED,
    PROPERTY_ARCHIVED,
    PROPERTY_DELETED,
    PROPERTY_INTEREST_UPDATED,
    PROPERTY_UNARCHIVED,
} from '@/store/actions/socket_events';
import { filterProperties } from '@/utils/utils';
import { KEYWORD_STATUS_OPTIONS } from "@/store/modules/keywords";
import { UPDATE_KEYWORD } from "@/store/actions/keywords";
import { PROPERTY_STATUS_OPTIONS, PROPERTY_STATUS_TEXT } from "@/utils/consts";

export default {
    state: () => ({
        properties: null,

        selectedPropertyId: null,
        selectedPropertyInterests: null,
        selectedPropertyInterestsAsync: false,
        selectedPropertyInterestsEditAsync: false,
        propertySidebarOpen: false,

        archivedProperties: null,
        archivedPropertiesAsync: false,

        searchText: null,
        searchProperties: null,

        async: false,
        addAsync: false,
        errors: null,
        editAsync: false,
        editErrors: null,
    }),
    mutations: {
        [SET_PROPERTIES](state, properties) {
            state.properties = properties;
        },
        [SET_PROPERTIES_ASYNC](state, async) {
            state.async = async;
        },
        [APPEND_PROPERTIES_PAGE](state, properties, _page) {
            if (state.properties !== null) {
                state.properties = state.properties.concat(properties);
            } else {
                state.properties = [...properties];
            }
        },
        [SET_PROPERTIES_ERRORS](state, errors) {
            state.errors = errors;
        },
        [APPEND_NEW_PROPERTY](state, property) {
            if (state.properties !== null) {
                state.properties.unshift(property);
            } else {
                state.properties = [property];
            }
        },
        [SET_ADD_PROPERTY_ASYNC](state, async) {
            state.addAsync = async;
        },
        [REMOVE_PROPERTY](state, propertyId) {
            if (state.properties !== null) {
                state.properties = state.properties.filter(property => property.id !== propertyId);
            }
        },
        [SET_EDIT_PROPERTY_ASYNC](state, async) {
            state.editAsync = async;
        },
        [SET_EDIT_PROPERTY_ERRORS](state, errors) {
            state.editErrors = errors;
        },
        [UPDATE_PROPERTY](state, { id, ...data }) {
            for (let property of state.properties) {
                if (property.id === id) {
                    Object.assign(property, data);
                    return;
                }
            }
        },
        [SET_PROPERTY_SIDEBAR_OPEN](state, open) {
            state.propertySidebarOpen = open;
        },
        [SET_SELECTED_PROPERTY_INTERESTS_ASYNC](state, async) {
            state.selectedPropertyInterestsAsync = async;
        },
        [SET_SELECTED_PROPERTY](state, selectedId) {
            if (state.selectedPropertyId === selectedId) {
                state.selectedPropertyId = null;
                state.selectedPropertyInterests = null;
            } else {
                state.selectedPropertyId = selectedId;
            }
        },
        [DESELECT_PROPERTY](state) {
            state.selectedPropertyId = null;
            state.selectedPropertyInterests = null;
        },
        [SET_SELECTED_PROPERTY_INTERESTS](state, interests) {
            state.selectedPropertyInterests = interests;
        },
        [SET_EDIT_SELECTED_PROPERTY_INTEREST_ASYNC](state, async) {
            state.selectedPropertyInterestsEditAsync = async;
        },
        [UPDATE_SELECTED_PROPERTY_INTEREST](state, { interest_id, ...updates }) {
            if (state.selectedPropertyInterests !== null) {
                for (let interest of state.selectedPropertyInterests) {
                    if (interest.interest_id === interest_id) {
                        Object.assign(interest, updates);
                        return;
                    }
                }
            }
        },

        [SET_SEARCH_PROPERTIES](state, searchProperties) {
            state.searchProperties = searchProperties;
        },

        [SET_PROPERTIES_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },

        [REMOVE_PROPERTY_FROM_ARCHIVED](state, propertyId) {
            if (state.archivedProperties !== null) {
                state.archivedProperties = state.archivedProperties.filter(property => property.id !== propertyId);
            }
        },

        [SET_ARCHIVED_PROPERTIES_ASYNC](state, async) {
            state.archivedPropertiesAsync = async;
        },

        [SET_ARCHIVED_PROPERTIES](state, properties) {
            state.archivedProperties = properties;
        },

        [APPEND_ARCHIVED_PROPERTIES_PAGE](state, properties, _page) {
            if (state.archivedProperties !== null) {
                state.archivedProperties = state.archivedProperties.concat(properties);
            } else {
                state.archivedProperties = [...properties];
            }
        },

        [APPEND_ARCHIVED_PROPERTY](state, property) {
            if (state.archivedProperties !== null) {
                state.archivedProperties.unshift(property);
            }
        },
    },
    actions: {
        async [FETCH_ALL_PROPERTIES_ACTION]({ commit, dispatch }) {
            let page = 0;

            commit(SET_PROPERTIES_ASYNC, true);
            commit(SET_PROPERTIES, null);
            commit(SET_SEARCH_PROPERTIES, null);
            commit(SET_PROPERTIES_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_PROPERTIES_PAGE_ACTION, { page });
            }

            commit(SET_PROPERTIES_ASYNC, false);
        },
        async [FETCH_PROPERTIES_PAGE_ACTION]({ commit }, { page }) {
            try {
                const { 'hydra:view': pagination, 'hydra:member': properties } = await fetchPropertiesPage(page);
                if (Array.isArray(properties)) {
                    // good, we have stuff
                    commit(APPEND_PROPERTIES_PAGE, properties, page);
                }

                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Properties Page', ex);
            }
        },
        async [ADD_NEW_PROPERTY]({ commit }, { url, name, keyword_id, ...rest }) {
            const errors = [];
            if (!url || !name || !keyword_id) {
                errors.push('All Properties are required');
                commit(SET_PROPERTIES_ERRORS, errors);
                return false;
            }

            commit(SET_PROPERTIES_ERRORS, null);
            commit(SET_ADD_PROPERTY_ASYNC, true);

            return addNewProperty({
                url,
                name,
                keyword_id,
                ...rest,
            }).then(property => {
                commit(APPEND_NEW_PROPERTY, property);
                return true;
            }).catch(async ex => {
                const r = await ex.json();
                errors.push(r.detail);
                commit(SET_PROPERTIES_ERRORS, errors);
                console.error('Add properties', ex);
                throw ex;
            }).finally(() => {
                commit(SET_ADD_PROPERTY_ASYNC, false);
            });
        },
        async [DELETE_PROPERTY]({ commit }, propertyId) {
            try {
                await deleteProperty(propertyId);
                commit(REMOVE_PROPERTY, propertyId);
                return true;
            } catch (ex) {
                console.error('delete property', ex);
                throw ex;
            }
        },
        async [EDIT_PROPERTY_ACTION]({ commit }, { id, ...data }) {
            commit(SET_EDIT_PROPERTY_ASYNC, true);
            commit(SET_EDIT_PROPERTY_ERRORS, null);

            try {
                const res = await editProperty(id, data);
                commit(UPDATE_PROPERTY, res);

            } catch (ex) {
                if (ex.json) {
                    const r = await ex.json();
                    commit(SET_EDIT_PROPERTY_ERRORS, [r['hydra:description']]);
                } else {
                    commit(SET_EDIT_PROPERTY_ERRORS, ["Unknown Error"]);
                }

                commit(SET_EDIT_PROPERTY_ASYNC, false);

                throw ex;
            }

            commit(SET_EDIT_PROPERTY_ASYNC, false);
        },
        async [CHANGE_PROPERTY_AGENT_ACTION]({ commit }, { propertyId, agentId }) {

            commit(SET_EDIT_PROPERTY_ASYNC, true);
            commit(SET_EDIT_PROPERTY_ERRORS, null);

            try {
                const res = await changePropertyAgent(propertyId, agentId);
                commit(UPDATE_PROPERTY, res);

            } catch (ex) {
                const r = await ex.json();

                commit(SET_EDIT_PROPERTY_ERRORS, [r['hydra:description']]);
                commit(SET_EDIT_PROPERTY_ASYNC, false);

                throw ex;
            }

            commit(SET_EDIT_PROPERTY_ASYNC, false);
        },
        [SELECT_PROPERTY_ACTION]({ commit, dispatch, state }, { propertyId, controlSidebar }) {
            commit(SET_SELECTED_PROPERTY, propertyId);

            if (propertyId != null) {
                dispatch(FETCH_SELECTED_PROPERTY_INTERESTS, propertyId);
            }

            if (controlSidebar) {
                if (state.selectedPropertyId === propertyId) {
                    commit(SET_PROPERTY_SIDEBAR_OPEN, true);
                } else {
                    commit(SET_PROPERTY_SIDEBAR_OPEN, false);
                }
            }
        },

        async [FETCH_SELECTED_PROPERTY_INTERESTS]({ commit }, propertyId) {
            commit(SET_SELECTED_PROPERTY_INTERESTS_ASYNC, true);

            try {
                const res = await getPropertyInterestedLeads(propertyId);
                commit(SET_SELECTED_PROPERTY_INTERESTS, res);
            } catch (ex) {
                console.error('fetch selected property interests', ex);
            }

            commit(SET_SELECTED_PROPERTY_INTERESTS_ASYNC, false);
        },

        async [EDIT_SELECTED_PROPERTY_INTEREST_ACTION]({ commit }, { id, ...updates }) {
            commit(SET_EDIT_SELECTED_PROPERTY_INTEREST_ASYNC, true);

            try {
                const res = await editPropertyInterest(id, updates);
                commit(UPDATE_SELECTED_PROPERTY_INTEREST, res);
            } catch (ex) {
                console.error('update selected property interest', ex);
            }

            commit(SET_EDIT_SELECTED_PROPERTY_INTEREST_ASYNC, false);
        },

        [PROPERTY_INTEREST_UPDATED]({ commit }, data) {
            const jsonData = JSON.parse(data);

            commit(UPDATE_SELECTED_PROPERTY_INTEREST, {
                interest_id: jsonData.interest_id,
                archived: jsonData.archived,
                collapsed: jsonData.collapsed,
                note: jsonData.note,
            });
        },

        [SEARCH_PROPERTIES_ACTION]({ commit, state }, { searchText, isArchive = false }) {
            if (searchText === null || searchText.length === 0) {
                commit(SET_PROPERTIES_SEARCH_TEXT, null);
                commit(SET_SEARCH_PROPERTIES, null);
            } else {
                commit(SET_PROPERTIES_SEARCH_TEXT, searchText);
                if (isArchive) {
                    commit(SET_SEARCH_PROPERTIES, state.archivedProperties.filter(property => filterProperties(property, searchText)));
                } else {
                    commit(SET_SEARCH_PROPERTIES, state.properties.filter(property => filterProperties(property, searchText)));
                }
            }
        },

        async [ARCHIVE_PROPERTY_ACTION](_, { propertyId, newStatus }) {
            try {
                await archiveProperty(propertyId, newStatus);
            } catch (ex) {
                console.error(ex);
                throw ex;
            }
        },
        async [UNARCHIVE_PROPERTY_ACTION](_, { propertyId, newStatus, keywordId }) {
            try {
                await unarchiveProperty(propertyId, newStatus, keywordId);
            } catch (ex) {
                console.error(ex);
                throw ex;
            }
        },
        async [FETCH_ALL_ARCHIVED_PROPERTIES_ACTION]({ commit, dispatch }) {
            let page = 0;

            commit(SET_ARCHIVED_PROPERTIES_ASYNC, true);
            commit(SET_ARCHIVED_PROPERTIES, null);
            commit(SET_SEARCH_PROPERTIES, null);
            commit(SET_PROPERTIES_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_ARCHIVED_PROPERTIES_PAGE_ACTION, { page });
            }

            commit(SET_ARCHIVED_PROPERTIES_ASYNC, false);
        },
        async [FETCH_ARCHIVED_PROPERTIES_PAGE_ACTION]({ commit }, { page }) {
            try {
                const { 'hydra:view': pagination, 'hydra:member': leads } = await fetchArchivedPropertiesPage(page);
                if (Array.isArray(leads)) {
                    // good, we have stuff
                    commit(APPEND_ARCHIVED_PROPERTIES_PAGE, leads, page);
                }

                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch properties archived Page', ex);
            }
        },
        async [ASSIGN_KEYWORD_TO_PROPERTY_ACTION]({ commit }, { propertyId, keywordId }) {

            commit(SET_EDIT_PROPERTY_ASYNC, true);
            commit(SET_EDIT_PROPERTY_ERRORS, null);

            try {
                const res = await assignKeywordToProperty(propertyId, keywordId);
                commit(UPDATE_PROPERTY, res);
                commit(UPDATE_KEYWORD, { id: keywordId, status: KEYWORD_STATUS_OPTIONS.USED })

            } catch (ex) {
                const r = await ex.json();

                commit(SET_EDIT_PROPERTY_ERRORS, [r['hydra:description']]);
                commit(SET_EDIT_PROPERTY_ASYNC, false);

                throw ex;
            }

            commit(SET_EDIT_PROPERTY_ASYNC, false);
        },

        [PROPERTY_ARCHIVED]({ commit }, data) {
            const jsonData = JSON.parse(data);

            commit(REMOVE_PROPERTY, jsonData.property.id);
            commit(APPEND_ARCHIVED_PROPERTY, jsonData.property);
        },

        [PROPERTY_UNARCHIVED]({ commit }, data) {
            const jsonData = JSON.parse(data);

            commit(APPEND_NEW_PROPERTY, jsonData.property);
            commit(REMOVE_PROPERTY_FROM_ARCHIVED, jsonData.property.id);
        },
        [PROPERTY_DELETED]({ commit }, data) {
            const jsonData = JSON.parse(data);

            commit(REMOVE_PROPERTY_FROM_ARCHIVED, jsonData.property_id);
            commit(REMOVE_PROPERTY, jsonData.property_id);
        },
        [PROPERTY_AGENT_CHANGED]({ commit }, data) {
            const jsonData = JSON.parse(data);
            commit(UPDATE_PROPERTY, jsonData.property);
        },
    },
    getters: {
        selectedPropertyInterests(state) {
            if (state.selectedPropertyInterests !== null && state.selectedPropertyId !== null) {
                return state.selectedPropertyInterests;
            } else {
                return [];
            }
        },
        properties(state) {
            if (state.searchProperties !== null) {
                return state.searchProperties;
            } else {
                if (state.properties !== null) {
                    return state.properties;
                } else {
                    return [];
                }
            }
        },
        hasProperties(state) {
            return state.properties !== null;
        },
        selectedProperty(state) {
            if (!state.properties) {
                return null;
            }

            const propertyIndex = state.properties.findIndex(property => property.id === state.selectedPropertyId);

            if (propertyIndex === -1) {
                return null;
            } else {
                return state.properties[propertyIndex];
            }
        },
        hasArchivedProperties(state) {
            return state.archivedProperties !== null;
        },
        archivedProperties(state) {
            if (state.searchProperties !== null) {
                return state.searchProperties;
            }

            if (state.archivedProperties !== null) {
                return state.archivedProperties;
            } else {
                return [];
            }
        },
        propertyStatusOptions() {
            return Object.keys(PROPERTY_STATUS_OPTIONS).map(status => ({
                label: PROPERTY_STATUS_TEXT[status],
                value: status
            }))
        }
    },
};
