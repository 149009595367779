import Vue from 'vue';
import { getFullNameFromParts } from './utils';

import moment from 'moment';
import { PROPERTY_STATUS_OPTIONS, PROPERTY_STATUS_TEXT } from "@/utils/consts";

Vue.filter('phone', function (phone) {
    if (phone.startsWith("+")) {
        return phone.replace(/(\+1)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
    } else {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
});

Vue.filter('price', function (value) {
    if (!value) return '';
    let val = (value / 100).toFixed(0).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('mergeName', function (value) {
    if (!value) return '';

    return getFullNameFromParts(value);
});

Vue.filter('role', function (value) {
    if (!value) return '';

    return value.split("_").filter(r => r !== "ROLE").map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(" ");
})

Vue.filter('date_short_if_today', function (value) {
    if (!value) return '';

    const valueMoment = moment(value)

    const isToday = valueMoment.isSame(moment(), 'day');

    if (isToday) {
        return valueMoment.format("hh:mma");
    } else {
        return valueMoment.format("MM/DD/YY hh:mma")
    }
});

Vue.filter('propertyStatusText', function (status) {
    return PROPERTY_STATUS_TEXT[status] || '';
})

Vue.filter('propertyStatusColor', function (status) {
    switch (status) {
        case PROPERTY_STATUS_OPTIONS.SOLD:
            return "#748AA1"
        case PROPERTY_STATUS_OPTIONS.FOR_SALE:
            return "#13A68A"
        case PROPERTY_STATUS_OPTIONS.SALE_PENDING:
            return "#FEC400"
        case PROPERTY_STATUS_OPTIONS.OFF_MARKET:
            return "#FA5D5D";
    }
})

Vue.filter('cut_weird_spaces', function (value) {
    if (!value) return '';

    return value.replaceAll(String.fromCharCode(160), String.fromCharCode(32));
});
