export const FETCH_USER_TEMPLATES_ACTION = 'FETCH_USER_TEMPLATES_ACTION';
export const FETCH_COMPANY_TEMPLATES_ACTION = 'FETCH_COMPANY_TEMPLATES_ACTION';


export const ADD_NEW_TEMPLATE_ACTION = 'ADD_NEW_TEMPLATE_ACTION';

export const SET_USER_TEMPLATES_ASYNC = 'SET_USER_TEMPLATES_ASYNC';
export const SET_USER_TEMPLATES = 'SET_USER_TEMPLATES';

export const SET_COMPANY_TEMPLATES_ASYNC = 'SET_COMPANY_TEMPLATES_ASYNC';
export const SET_COMPANY_TEMPLATES = 'SET_COMPANY_TEMPLATES';

export const ADD_COMPANY_TEMPLATE = 'ADD_COMPANY_TEMPLATE';

export const EDIT_COMPANY_TEMPLATE_ACTION = 'EDIT_COMPANY_TEMPLATE_ACTION';

export const DELETE_COMPANY_TEMPLATE_ACTION = 'DELETE_COMPANY_TEMPLATE_ACTION';

export const REMOVE_COMPANY_TEMPLATE = 'REMOVE_COMPANY_TEMPLATE'

export const UPDATE_COMPANY_TEMPLATE = 'UPDATE_COMPANY_TEMPLATE';

export const ADD_USER_TEMPLATE = 'ADD_USER_TEMPLATE';

export const EDIT_USER_TEMPLATE_ACTION = 'EDIT_USER_TEMPLATE_ACTION';

export const DELETE_USER_TEMPLATE_ACTION = 'DELETE_USER_TEMPLATE_ACTION';

export const REMOVE_USER_TEMPLATE = 'REMOVE_USER_TEMPLATE'

export const UPDATE_USER_TEMPLATE = 'UPDATE_USER_TEMPLATE';

export const SET_TEMPLATES_ADD_ASYNC = 'SET_TEMPLATES_ADD_ASYNC';
export const SET_TEMPLATES_EDIT_ASYNC = 'SET_TEMPLATES_EDIT_ASYNC';
