<template>
    <div class="page users-page">
        <users-companies-list v-if="isSuperAdmin"/>
        <company-users/>
    </div>
</template>

<script>
import UsersCompaniesList from './components/UsersCompaniesList/UsersCompaniesList';
import CompanyUsers from './components/CompanyUsers/CompanyUsers';
import { mapMutations } from 'vuex';
import { SET_SELECTED_COMPANY } from '@/store/actions/companies';

export default {
    name: 'Users',
    components: { CompanyUsers, UsersCompaniesList },
    computed: {
        isSuperAdmin() {
            return this.$store.getters.isSuperAdmin;
        },
        companyId() {
            return this.$store.getters.companyId;
        },
        selectedCompanyId() {
            return this.$store.getters.selectedCompanyId;
        },
    },
    methods: {
        ...mapMutations({
            selectCompany: SET_SELECTED_COMPANY,
        }),
    },
    mounted() {
        if (!this.isSuperAdmin) {
            //he is company admin
            if (!this.selectedCompanyId) {
                this.selectCompany(this.companyId);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.users-page {
    background-color: #1E2234;
}
</style>
