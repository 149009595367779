<template>
    <div class="message-bar">
        <media-attachment-popup :open="hasFiles"
                                @delete="deleteFile"
                                :files="files"/>

        <div class="text-container">
            <div class="upload-icon">

                <el-dropdown trigger="click" @command="onAttachmentDropdown">
                    <span class="more-menu-btn">
                        <i class="icon icon-attachment"/>
                    </span>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="upload-media">
                            Send Attachment
                        </el-dropdown-item>
                        <el-dropdown-item command="insert-template">
                            Send Template
                        </el-dropdown-item>
                        <el-dropdown-item command="send-property">
                            Send Property
                        </el-dropdown-item>
                        <el-dropdown-item command="send-csi-survey">
                            Send CSI Survey
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

            </div>

            <div class="input-container">
                <textarea-autosize
                    @keydown.enter.native="onSendMessage"
                    placeholder="Type your message..."
                    rows="1"
                    v-model="text"
                    :max-height="350"
                />
            </div>

            <div class="send-button-container">
                <el-button v-if="hasCancel" type="text" @click="cancelSendMessage">
                    <div class="progress-cancel-button-content">
                        <vue-circle :progress="0"
                                    :size="16"
                                    ref="progressCircle"
                                    :fill="{color: '#13A68A'}"
                                    :thickness="3"
                                    :animation="false"
                                    :show-percent="false"
                                    :start-angle="-(Math.PI / 2)"/>
                        CANCEL
                    </div>
                </el-button>
                <el-button :loading="async"
                           v-else
                           @click="onSendMessage"
                           :class="{disabled: text.length === 0 && (!files || files.length === 0)}"
                           type="text">
                    SEND
                </el-button>
            </div>
        </div>

        <input type="file"
               ref="fileInput"
               @change="onFileInputChange"
               style="display: none;"
               accept=".pdf, .mp4, .png, .jpg, .jpeg"/>

        <attachment-popup :open="attachmentPopupOpen"
                          @hide="attachmentPopupOpen = false"
                          @send-template="appendText"
                          @send-property="sendProperty"
                          :mode="attachmentPopupMode"/>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ADD_NEW_MESSAGE, SEND_CSI_SURVEY_MESSAGE, SEND_MEDIA_MESSAGE, SEND_MESSAGE } from '@/store/actions/messenger';
import AttachmentPopup from '@/components/Messenger/MessageBar/AttachmentPopup/AttachmentPopup';
import { ATTACHMENT_POPUP_MODE, LEAD_OPT_STATUS } from '@/utils/consts';
import MediaAttachmentPopup from '@/components/Messenger/MessageBar/MediaAttachmentPopup/MediaAttachmentPopup';
import { FETCH_COMPANY_AGENCY_SETTINGS_ACTION } from '@/store/actions/settings/company_settings';
import VueCircle from 'vue2-circle-progress/src/index.vue';
import { sleep } from '@/utils/utils';

export default {
    name: 'MessageBar',
    components: { MediaAttachmentPopup, AttachmentPopup, VueCircle },
    data() {
        return {
            attachmentPopupOpen: false,
            attachmentPopupMode: ATTACHMENT_POPUP_MODE.PROPERTY,
            text: '',
            files: null,
        };
    },
    props: {
        forEntity: String,
        entityId: String,
    },
    computed: {
        ...mapGetters([
            'hasCompanyAgencySettings',
            'companyId',
            'multiSelectedLeads',
            'isLeadsMultiselectActive',
        ]),

        hasFiles() {
            return this.files !== null && this.files.length > 0;
        },

        progress() {
            return this.$store.state.messenger.uploadProgress;
        },

        hasCancel() {
            return this.$store.state.messenger.abortSignal !== null;
        },

        async() {
            return this.$store.state.messenger.sendingAsync;
        },

        companyAgencySettings() {
            return this.$store.state.company_settings.agencySettings;
        },
    },
    mounted() {
        if (!this.hasCompanyAgencySettings) {
            this.fetchCompanyAgencySettings(this.companyId);
        }
    },
    watch: {
        progress: function (newProgress) {
            if (newProgress !== null && this.$refs.progressCircle) {
                this.$refs.progressCircle.updateProgress(newProgress);
            }
        },
    },
    methods: {
        cancelSendMessage() {
            if (this.$store.state.messenger.abortSignal !== null) {
                this.$store.state.messenger.abortSignal.abort();
            }
        },

        onFileInputChange(e) {
            if (e.target.files.length > 0) {
                let showAlert = false;
                let files = Array.from(e.target.files).filter(file => {
                    if (file.size > 99 * 1024 * 1024) {
                        showAlert = true;
                        return false;
                    }

                    return true;
                });

                if (showAlert) {
                    this.$swal({
                        title: 'Error',
                        text: 'You can only upload files that are smaller then 100MB',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Ok!',
                        reverseButtons: true,
                    });
                }


                if (files.length > 0) {
                    this.files = files;
                }
            }

            e.target.value = '';
        },

        sendProperty(text) {
            const actualText = [
                this.companyAgencySettings.before_manual_property_url,
                text,
                this.companyAgencySettings.after_manual_property_url,
            ].filter(v => !!v).join(' ').trim();

            this.appendText(actualText);

            this.onSendMessage();
        },

        appendText(text) {
            this.text = [this.text, text].join(' ').trim();
        },

        onAttachmentDropdown(cmd) {
            switch (cmd) {
                case 'insert-template':
                    this.attachmentPopupMode = ATTACHMENT_POPUP_MODE.TEMPLATE;
                    this.attachmentPopupOpen = true;
                    break;
                case 'upload-media':
                    this.$refs.fileInput.click();
                    break;

                case 'send-csi-survey':
                    this.sendCsiSurveyMessage({
                        forEntity: this.forEntity,
                        entityId: this.entityId,
                    });
                    break;
                case 'send-property':
                    this.attachmentPopupMode = ATTACHMENT_POPUP_MODE.PROPERTY;
                    this.attachmentPopupOpen = true;

                    break;
            }
        },

        ...mapMutations({
            addMessage: ADD_NEW_MESSAGE,
        }),

        ...mapActions({
            sendMessage: SEND_MESSAGE,
            sendMediaMessage: SEND_MEDIA_MESSAGE,
            sendCsiSurveyMessage: SEND_CSI_SURVEY_MESSAGE,
            fetchCompanyAgencySettings: FETCH_COMPANY_AGENCY_SETTINGS_ACTION,
        }),


        deleteFile(file) {
            this.files = this.files.filter(f => f !== file);
        },

        doSendMessage(entityId, text, files) {
            if (this.hasFiles) {
                return this.sendMediaMessage({
                    forEntity: this.forEntity,
                    entityId,
                    message: text,
                    files,
                });
            } else {
                if (text.length > 0) {
                    return this.sendMessage({
                        forEntity: this.forEntity,
                        entityId,
                        message: text,
                    });
                }
            }
        },
        onSendMessage(e) {
            if (this.async || e?.shiftKey === true) return;

            e?.preventDefault();

            if (this.isLeadsMultiselectActive) {
                // multi msg
                let promises = this.multiSelectedLeads
                    .filter(lead => lead.opt_status !== LEAD_OPT_STATUS.OUT)
                    .map(async (lead, index) => {
                        await sleep(index * 2000);

                        return await this.doSendMessage(lead.id, this.text, this.files);
                    });

                Promise.allSettled(promises)
                    .then(async (results) => {
                        let res = results.find(r => r.status === 'fulfilled');

                        if (res?.value?.id) {
                            this.addMessage({ message: res.value });
                        }

                        this.text = '';
                        this.files = null;
                    });
            } else {
                this.doSendMessage(this.entityId, this.text, this.files)
                    .then(() => {
                        this.text = '';
                        this.files = null;
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.message-bar {

    .input-container {
        textarea {
            border: none;
            background-color: #FFF;
            border-radius: 0px;
            color: #000;
            resize: vertical;
            overflow: auto;
            padding: 1px 5px;

            &::placeholder {
                color: #8F9BB3;
            }
        }
    }
}
</style>

<style scoped lang="scss">
.theme-light {
    .message-bar {
        box-shadow: inset 0 1px 0 0 rgba(173, 173, 173, 0.1);
    }
}

.message-bar {
    position: relative;
    min-height: 62px;
    display: flex;
    background-color: #FFFFFF;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    flex-direction: column;

    .text-container {
        display: flex;
        align-items: center;
        flex: 1;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 100%;

        .upload-icon {
            cursor: pointer;

            i {
                font-size: 22px;
                color: #8F9BB3;
                transition: all 300ms ease-in-out;

                &:hover {
                    color: #13A68A;
                }
            }
        }

        .input-container {
            flex: 1;
        }

        .send-button-container {

            .progress-cancel-button-content {
                display: flex;
                align-items: center;

                div {
                    margin-right: 6px;
                }
            }

            .el-button {
                transition: all 300ms ease-in-out;
                color: #13A68A;
                font-weight: 700;
                font-size: 14px;


                &.disabled {
                    color: #8F9BB3;
                    font-weight: 400;
                }

            }
        }
    }
}
</style>
