<template>
    <span>
        <el-button @click.stop.prevent="visible = true"
                   v-tooltip="'Unarchive Property'"
                   class="delete-property-btn"
                   type="text"
                   style="margin-left: 16px;">
            <i style="display: block;cursor: pointer" class="icon icon-actions_edit"/>
        </el-button>

        <el-dialog :title="title"
                   :append-to-body="true"
                   :modal-append-to-body="true"
                   :custom-class="activeClass"
                   :visible.sync="visible"
                   @close="visible = false">
            <div class="unarchive-property">
                <div class="property-name">{{ property.name }}</div>
            </div>

            <labeled-select name="Keyword"
                            :show-label="false"
                            v-model="form.selectedKeyword"
                            placeholder="Keyword">
                <el-option :label="keyword.name"
                           :value="keyword.id"
                           :key="keyword.id"
                           v-for="keyword in keywords">
                    {{ keyword.name }}
                </el-option>
            </labeled-select>

            <p class="warning" v-if="!form.selectedKeyword">Keyword is not selected.</p>

            <span slot="footer" class="footer">
                <el-button type="text" @click="visible = false">Cancel</el-button>
                <el-button :loading="async"
                           :disabled="!Boolean(form.selectedKeyword)"
                           class="archive-btn"
                           type="text"
                           @click="onSubmit">
                    Unarchive
                </el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import { mapActions } from 'vuex';
import { GENERATE_KEYWORDS_ACTION } from '@/store/actions/companies';
import { UNARCHIVE_PROPERTY_ACTION } from '@/store/actions/properties';
import { PROPERTY_STATUS_OPTIONS } from '@/utils/consts';
import LabeledSelect from '@/components/LabeledSelect/LabeledSelect';

function getDefaultData() {
    return {
        selectedOption: PROPERTY_STATUS_OPTIONS.FOR_SALE,
        selectedKeyword: null,
    };
}

export default {
    name: 'UnarchivePropertyDialog',
    components: { LabeledSelect },
    data() {
        return {
            form: getDefaultData(),
            visible: false,
        };
    },
    props: {
        property: Object,
    },
    computed: {
        title() {
            return 'Unarchive Property';
        },
        async() {
            return this.$store.state.companies.addEditAsync;
        },
        activeClass() {
            return this.form.selectedOption === PROPERTY_STATUS_OPTIONS.FOR_SALE ? 'active-for-sale' : 'active-sale-pending';
        },
        keywords() {
            return this.$store.getters.availableKeywords;
        },
    },
    watch: {
        visible(newVisible) {
            if (newVisible === true) {
                this.form = getDefaultData();
            }
        },
    },
    methods: {
        ...mapActions({
            generateKeywords: GENERATE_KEYWORDS_ACTION,
            unarchivePropertyAction: UNARCHIVE_PROPERTY_ACTION,
        }),
        onSubmit() {
            this.unarchivePropertyAction({
                propertyId: this.property.id,
                newStatus: this.form.selectedOption,
                keywordId: this.form.selectedKeyword,
            }).then((res) => {
                if (res !== false) {
                    this.form = getDefaultData();
                    this.visible = false;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">

.unarchive-property {
    display: flex;
    flex-direction: column;
    align-items: center;

    .property-name {
        color: #E5E5EA;
        margin-bottom: 20px;
    }

    .status-option {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 2px solid #748AA1;
        color: #748AA1;
        border-radius: 8px;
        padding: 10px 0;
        margin-top: 20px;
        font-size: 16px;
        cursor: pointer;
        opacity: 0.8;
        transition: all 200ms ease-in-out;

        &.for-sale {
            &:hover {
                opacity: 1;
                color: #13A68A;
                border-color: #13A68A;
            }
        }

        &.sale-pending {

            &:hover {
                opacity: 1;
                color: #FEC400;
                border-color: #FEC400;
            }
        }
    }

    .message {
        margin-top: 30px;
        font-size: 16px;
        min-height: 20px;
    }
}

.active-for-sale {

    .for-sale {
        opacity: 1;
        color: #13A68A;
        border-color: #13A68A;
    }

    .archive-btn {
        &:hover {
            color: #13A68A;
        }
    }
}

.active-sale-pending {

    .sale-pending {
        opacity: 1;
        color: #FEC400;
        border-color: #FEC400;
    }

    .archive-btn {
        &:hover {
            color: #FEC400;
        }
    }
}

.footer {
    .el-button {
        color: #748AA1;
    }
}

.delete-property-btn {
    font-size: 16px;

    .icon {
        color: #748AA1;
        transition: all 300ms ease-in-out;
        font-size: 20px;

        &:hover {
            color: #FA5D5D;
        }
    }
}

.warning {
    margin-top: 20px;
    font-size: 14px;
    color: #FEC400;
    opacity: 0.8;
}

</style>
