<template>
    <div class="edit-property-sidebar" :class="{open: visible}">
        <div class="edit-property-sidebar-content">
            <div class="edit-property-header">
                <el-button type="text" @click="closeSidebar">
                    <i class="icon icon-actions_delete" style="font-size: 24px;"/>
                </el-button>
            </div>
            <el-tabs :stretch="true" v-model="tab">
                <el-tab-pane label="Interested in property" name="interested_in">
                    <div class="async-container" v-if="selectedInterestsAsync">
                        <vue-loaders-line-scale/>
                    </div>
                    <div v-else class="property-interest-container">
                        <edit-property-interest
                            :interest="interest"
                            v-for="interest in selectedInterests"
                            :key="interest.interest_id"/>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Property Details" name="preview">
                    <div class="preview-interest-tab">
                        <interest-preview :is-display-only="true"
                                          :interest="property"
                                          v-if="property !== null"/>

                        <edit-property-note :property="property"/>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { DESELECT_PROPERTY, SET_PROPERTY_SIDEBAR_OPEN } from '@/store/actions/properties';
import { mapMutations } from 'vuex';

import EditPropertyInterest
    from '@/views/AddNewProperty/components/PropertySidebar/EditPropertyInterest/EditPropertyInterest';
import InterestPreview from '@/components/ConversationSection/Details/InterestPreview/InterestPreview';
import EditPropertyNote from '@/views/AddNewProperty/components/PropertySidebar/EditPropertyNote/EditPropertyNote';

export const EDIT_PROPERTY_TABS = {
    INTERESTED_IN: 'interested_in',
    PREVIEW: 'preview',
};

export default {
    name: 'PropertySidebar',
    components: { EditPropertyNote, InterestPreview, EditPropertyInterest },
    data() {
        return {
            tab: EDIT_PROPERTY_TABS.INTERESTED_IN,
        };
    },
    methods: {
        ...mapMutations({
            deselectProperty: DESELECT_PROPERTY,
            setPropertySidebarOpen: SET_PROPERTY_SIDEBAR_OPEN,
        }),

        closeSidebar() {
            this.deselectProperty();
            this.setPropertySidebarOpen(false);
        }
    },
    computed: {
        errors() {
            return this.$store.state.properties.editErrors;
        },
        editAsync() {
            return this.$store.state.properties.editAsync;
        },
        selectedInterestsAsync() {
            return this.$store.state.properties.selectedPropertyInterestsAsync;
        },
        selectedInterests() {
            return this.$store.getters.selectedPropertyInterests;
        },
        property() {
            return this.$store.getters.selectedProperty;
        },
        visible() {
            return this.$store.state.properties.propertySidebarOpen;
        },
    },
};
</script>

<style lang="scss">
.edit-property-sidebar-content {

    .el-tabs {
        flex: 1;

        .el-tabs__content {
            flex: 1;
        }
    }
}
</style>

<style lang="scss" scoped>

.edit-property-sidebar {
    background-color: #12131F;
    max-width: 0px;
    min-width: 0px;
    transition: all 300ms ease-in-out;

    &.open {
        max-width: 420px;
        min-width: 420px;
        width: 420px;
    }

    .edit-property-sidebar-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 420px;
        min-width: 420px;

        .edit-property-header {
            display: flex;
            justify-content: flex-end;
            padding: 16px;
        }

        .preview-interest-tab {
            padding: 16px;
        }

        .async-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
        }

        .edit-property-dialog {
            padding: 16px;
            display: flex;
            flex-direction: column;
            flex: 1;

            .edit-submit-button {
                width: fit-content;
                align-self: flex-end;
            }
        }

        .property-interest-container {
            flex: 1;
            width: 100%;
            overflow: auto;
            padding-bottom: 20px;
        }
    }
}

.open-button {
    color: #748AA1;
    font-size: 16px;

    .icon {
        font-size: 20px;
    }

    &:hover {
        color: #748AA1;
    }
}

</style>
