<template>
    <div class="media-attachment-popup" :class="{open: open}">
        <div class="media-attachment-preview-container">
            <media-attachment-preview @delete="$emit('delete', file)"
                                      v-for="file in files"
                                      :key="file.name"
                                      :file="file"/>
        </div>

    </div>
</template>

<script>

import MediaAttachmentPreview from '@/components/Messenger/MessageBar/MediaAttachmentPopup/MediaAttachmentPreview';

export default {
    name: 'MediaAttachmentPopup',
    components: { MediaAttachmentPreview },

    props: {
        open: Boolean,
        files: Array
    },
};
</script>

<style lang="scss" scoped>
.media-attachment-popup {
    display: flex;
    width: 100%;
    transition: all 300ms ease-in-out;

    &.open {
        padding-top: 6px;
    }

    .media-attachment-preview-container {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>