import {
    APPEND_USER_NOTIFICATIONS_PAGE,
    FETCH_USER_NOTIFICATIONS_PAGE,
    MARK_NOTIFICATION_READ_ACTION,
    SET_NOTIFICATIONS,
    SET_NOTIFICATIONS_ASYNC,
    UPDATE_NOTIFICATION,
} from '@/store/actions/notifications';
import { fetchUserNotificationsPage, markNotificationRead } from '@/api/notifications/notifications';

export default {
    state: () => ({
        async: false,
        userNotifications: null,
    }),
    mutations: {
        [SET_NOTIFICATIONS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_NOTIFICATIONS](state, notifications) {
            state.userNotifications = [...notifications];
        },
        [APPEND_USER_NOTIFICATIONS_PAGE](state, notifications) {
            if (state.userNotifications === null) {
                state.userNotifications = [...notifications];
            } else {
                state.userNotifications.push(...notifications);
            }
        },
        [UPDATE_NOTIFICATION](state, { id, ...data }) {
            if (Array.isArray(state.userNotifications)) {
                for (let n of state.userNotifications) {
                    if (n.id === id) {
                        Object.assign(n, data);
                        return;
                    }
                }
            }
        },
    },
    actions: {
        async [FETCH_USER_NOTIFICATIONS_PAGE]({ commit }, { page }) {
            commit(SET_NOTIFICATIONS_ASYNC, true);

            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': notifications,
                } = await fetchUserNotificationsPage(page);

                if (Array.isArray(notifications)) {
                    commit(APPEND_USER_NOTIFICATIONS_PAGE, notifications);
                }

                return Boolean(pagination['hydra:next']);
            } catch (ex) {
                console.error('Fetch notifications page', page, ex);
            } finally {
                commit(SET_NOTIFICATIONS_ASYNC, false);
            }
        },
        async [MARK_NOTIFICATION_READ_ACTION]({ commit }, notificationId) {
            await markNotificationRead(notificationId).then(notification => {
                commit(UPDATE_NOTIFICATION, notification);
            });
        },
    },
    getters: {
        userNotifications(state) {
            return state.userNotifications || [];
        },
        unreadUserNotifications(state, getters) {
            return getters.userNotifications.filter(notification => notification.read_at === null);
        },
        hasUserNotifications(state) {
            return state.userNotifications !== null;
        },
    },
};
