<template>
    <div class="list-container">
        <div class="archived-tab" v-if="!async">
            <lead-list-item v-for="lead in allLeads"
                            @on-click="onClickListItem"
                            :lead="lead"
                            :key="lead.id"/>

            <div class="nothing-here" v-if="!hasArchivedLeads">
                <span>Nothing Here</span>
            </div>
        </div>

        <div class="async-container" v-else>
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LeadListItem from '../components/LeadListItem/LeadListItem';
import { FETCH_ALL_ARCHIVED_LEADS_ACTION, SELECT_LEAD_ACTION } from '@/store/actions/leads';

export default {
    name: 'ArchivedTab',
    components: { LeadListItem },
    methods: {
        ...mapActions({
            selectLead: SELECT_LEAD_ACTION,
            fetchAllArchivedLeads: FETCH_ALL_ARCHIVED_LEADS_ACTION,
        }),
        onClickListItem(leadId) {
            this.selectLead(leadId);
        },
    },
    computed: {
        ...mapGetters({
            allLeads: 'archivedLeads',
            hasArchivedLeads: "hasArchivedLeads",
        }),
        async() {
            return this.$store.state.leads.archivedAsync;
        },
    },

};
</script>

<style scoped lang="scss">
.list-container {
    .async-container {
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }
}

.archived-tab {
    color: #FFFFFF;

    .nothing-here {
        font-size: 15px;
        color: #4c545d;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
