<template>
    <div class="interested-in"
         :class="{collapsed: interest.collapsed || interest.archived, archived: interest.archived, borderless: isDisplayOnly,
         'added-by-agent': isInitiatedByAgent}">
        <div class="interest-initated-by" :class="{agent: isInitiatedByAgent}" v-if="!isDisplayOnly">
            <span v-if="isInitiatedByAgent">ADDED BY AGENT</span>
            <span v-else>SENT BY LEAD</span>
        </div>

        <div class="interest-status">
            <property-status-dot :status="getActivePropertyStatus(interest.status)"/>
        </div>

        <div class="address-price-container">
            <p>{{ interest.name | cut_weird_spaces }}</p>
            <p class="price" v-if="interest.price">
                {{ interest.currency }} {{ (+interest.price ? +interest.price : interest.price).toLocaleString() }}
            </p>
        </div>
        <div class="real-estate-details">
            <p class="description">
                {{ interest.description }}
            </p>
        </div>

        <img class="real-estate-image" v-if="!hasImageError" @error="hasImageError = true" :src="interest.image"/>
        <div class="real-estate-image img-missing" v-else>
            <div>Image Not Available</div>
        </div>

        <div class="full-profile-link-container">
            <div class="archive-interest-btn"
                 v-if="!isDisplayOnly"
                 v-tooltip="'Not interested anymore'"
                 @click="$emit('on-archive')">
                <i class="icon icon-clean"/>
            </div>


            <span class="interest-timestamp">{{ interest.created_at|date_short_if_today }}</span>

            <a class="full-profile-link" :href="interest.url" target="_blank">Show full listing</a>
        </div>

        <div class="expand-collapse-btn"
             v-if="!isDisplayOnly"
             v-tooltip="interest.archived ? 'Mark as interested' : interest.collapsed ? 'Expand' : 'Collapse'"
             @click="$emit('expand-collapse-clicked')">
            <i class="icon"
               :class="{'icon-up': !interest.archived, 'icon-undo': interest.archived}"/>
        </div>
    </div>
</template>

<script>
import PropertyStatusDot from '@/components/PropertyStatusDot/PropertyStatusDot';
import { getActivePropertyStatus } from "@/utils/utils";

export default {
    name: 'InterestPreview',
    components: { PropertyStatusDot },
    props: {
        interest: Object,
        isDisplayOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hasImageError: false,
        };
    },
    computed: {
        isInitiatedByAgent() {
            return this.interest.initiated_by === 'AGENT';
        },
    },
    methods: {
        getActivePropertyStatus
    }
};
</script>

<style lang="scss">

.interested-in {
    &.borderless {
        .interest-status {
            .property-status-dot {
                background: transparent;
            }
        }
    }
}

.interest-status {
    .property-status-dot {
        position: absolute;

        .dot {
            font-size: 24px;
            height: 33px;
        }

        .status {
            color: #b5b7ba;
            margin-left: 2px;
        }
    }
}
</style>

<style lang="scss" scoped>
.interested-in {
    border: 1px solid #13A68A;
    border-radius: 8px;
    display: flex;
    font-size: 15px;
    flex-direction: column;
    color: #748AA1;
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 14px;
    position: relative;
    transition: all 300ms ease-in-out;

    .interest-initated-by {
        font-size: 12px;
        position: absolute;
        top: -8px;
        padding-left: 4px;
        padding-right: 4px;
        background-color: #1E2234;
        color: #13A68A;
        transition: all 300ms ease-in-out;

        &.agent {
            color: orange;
        }
    }

    &.added-by-agent:not(.borderless):not(.archived) {
        border: 1px solid orange;

        .expand-collapse-btn {
            border: 1px solid orange;
            color: orange;
        }

        .address-price-container {
            color: orange;

            .price {
                color: orange;
            }
        }


        .archive-interest-btn {
            &:hover {
                color: orange;
            }
        }

        .full-profile-link {
            &:hover {
                color: orange;
            }
        }
    }

    .interest-timestamp {
        font-weight: 500;
        font-size: 14px;
        color: #b5b7ba;
        transition: all 300ms ease-in-out;
    }

    &.borderless {
        border: none;
        margin-top: unset;
    }

    &.archived {
        border: 1px solid #545F69;

        .interest-initated-by {
            color: #545F69;
        }

        .expand-collapse-btn {
            border: 1px solid #545F69;
            color: #545F69;
            transform: rotate(180deg);
        }

        .address-price-container {
            color: #545F69;

            .price {
                color: #545F69;
            }
        }
    }

    &.collapsed {
        .expand-collapse-btn {
            transform: rotate(180deg);
        }

        .real-estate-details {
            .description {
                max-height: 0px;
                margin-top: 0px;
            }
        }

        .real-estate-image {
            margin-top: 0px;
            overflow: hidden;
            height: 0px;
        }

        .img-missing {
            border: none;
        }

        .full-profile-link-container {
            max-height: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

    }

    .expand-collapse-btn {
        transition: all 300ms ease-in-out;
        border: 1px solid #13A68A;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: center;
        border-radius: 56px;
        position: absolute;
        bottom: -13px;
        background-color: #1E2234;
        right: 13px;
        color: #13A68A;
        cursor: pointer;

        .icon-undo {
            font-size: 20px;
        }
    }

    .address-price-container {
        transition: all 300ms ease-in-out;
        padding-top: 12px;
        color: #13A68A;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-direction: row;

        .price {
            padding-left: 12px;
            transition: all 300ms ease-in-out;
            white-space: nowrap;
            font-size: 16px;
            color: #13A68A;
            font-weight: 600;
        }
    }

    .real-estate-image {
        margin-top: 8px;
        width: 100%;
        height: 128px;
        object-fit: cover;
        border-radius: 6px;
        transition: all 300ms ease-in-out;
    }

    .img-missing {
        border: 1px solid #545F69;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }

    .real-estate-details {
        margin-top: 8px;
        font-size: 13px;
        color: #888;


        .description {
            transition: all 300ms ease-in-out;
            margin-top: 8px;
            max-height: 120px;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            overflow: hidden;
            -webkit-box-orient: vertical;
            font-size: 13px;
            color: #ffffff;
        }
    }

    .full-profile-link-container {
        overflow: hidden;
        max-height: 22px;
        margin-top: 10px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .archive-interest-btn {
            cursor: pointer;
            font-size: 20px;
            color: #b5b7ba;
            transition: all 300ms ease-in-out;

            &:hover {
                color: #13A68A;
            }
        }

        .full-profile-link {
            font-weight: 500;
            font-size: 14px;
            color: #b5b7ba;
            text-decoration: underline;
            transition: all 300ms ease-in-out;

            &:hover {
                color: #13A68A;
            }
        }
    }
}
</style>
