import moment from 'moment';

export function stringSort(a, b, direction) {
    const aVal = a.toLowerCase();
    const bVal = b.toLowerCase();
    if (direction === 'up') {
        if (aVal < bVal)
            return -1;
        if (aVal > bVal)
            return 1;
    } else {
        if (aVal > bVal)
            return -1;
        if (aVal < bVal)
            return 1;
    }
    return 0;
}

export function dateTimeSort(a, b, direction) {
    const dateA = new moment(a || 0).unix();
    const dateB = new moment(b || 0).unix();

    if (direction === 'up') {
        return dateA - dateB;
    } else {
        return dateB - dateA;
    }
}
