<template>
    <div v-if="valid" v-on:click="onSelectItem" class="menu-item" v-bind:class="{selected: selected}">
        <router-link class="name-container" :to="link">
            <div class="name-icon-container">
                <i class="icon" v-bind:class="icon"/>
                <span class="name" v-if="!menuCollapsed">{{ name }}</span>
                <div class="right-side-number-container" v-if="rightSideNumber">
                    <div class="check"/>

                    <span v-if="rightSideNumber < 100">{{ rightSideNumber }}</span>
                    <span v-else>..</span>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { isRouteValidForUser } from '@/utils/utils';
import { mapGetters } from 'vuex';

export default {
    name: 'MenuItem',
    data() {
        return {
            valid: false,
        };
    },
    beforeMount() {
        this.valid = this.validate();
    },
    props: {
        name: String,
        icon: String,
        link: String,
        meta: {
            type: Object,
            default: () => {
                return {};
            },
        },
        rightSideNumber: Number,
        selected: Boolean,
        menuCollapsed: Boolean,
    },
    methods: {
        validate() {
            return isRouteValidForUser(this.user, { meta: this.meta });
        },
        onSelectItem() {
            this.$router.push(this.link).catch(() => {
            });
        },
    },
    watch: {
        impersonatedUser: function (oldImpersonate, newImpersonate) {
            if (oldImpersonate?.id !== newImpersonate?.id) {
                this.valid = this.validate();
            }
        },
    },
    computed: {
        ...mapGetters([
            'isImpersonationActive',
            'impersonatedUser',
            'me',
        ]),
        user() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.me;
        },
    },
};
</script>

<style scoped lang="scss">

.theme-light {
    .menu-item {

        &:hover {
            background-color: #F4F7FC;

            .name-container {
                color: #545F69;
            }
        }

        &.selected {
            background-color: #F4F7FC;

            .name-container {
                color: #545F69;

                .name {
                    font-weight: bold;
                }
            }
        }
    }
}

.menu-item {
    height: 51px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    padding-left: 18px;

    &.selected {
        background-color: #545F69;

        .name-container {
            color: #FFFFFF;
            font-weight: 600;

            .icon {
                color: #13A68A;
            }
        }
    }

    &:hover {
        background-color: #1E2234;

        .name-container {
            color: #FFFFFF;
        }
    }

    .name-container {
        flex: 1;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        transition: color 200ms ease-in-out;
        font-size: 15px;
        color: #748AA1;
        font-weight: 500;
        position: relative;

        .name-icon-container {
            display: flex;
            align-items: center;
        }

        .right-side-number-container {
            width: 16px;
            height: 16px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 10px;
            font-size: 9px;
            left: 10px;
            color: #ffffff;

            background-color: indianred;

        }

        .name {
            white-space: nowrap;
            padding-left: 8px;
        }
    }
}

</style>
