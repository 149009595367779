<template>
    <div class="page companies">
        <div class="companies-header">
            <add-new-company-dialog/>
        </div>
        <companies-table/>
    </div>
</template>
<script>
import CompaniesTable from './components/CompaniesTable/CompaniesTable';
import AddNewCompanyDialog from './components/AddNewCompanyDialog/AddNewCompanyDialog';

export default {
    name: 'Companies',
    components: { AddNewCompanyDialog, CompaniesTable },

};
</script>

<style lang="scss" scoped>
.companies {
    display: flex;
    flex-direction: column;
    background-color: #12131F;

    .companies-header {
        display: flex;
        height: 55px;
        align-items: center;
        justify-content: flex-end;
        padding-left: 24px;
        padding-right: 24px;
    }
}
</style>
