<template>
    <span>
        <el-button @click="visible = true"
                   type="text"
                   v-tooltip="'Assign to agent'"
                   style="margin-left: 8px">
            <i class="icon-actions_edit" style="font-size: 16px"/>
        </el-button>

        <el-dialog title="Assign Keyword to Agent"
                   :visible="visible"
                   @close="visible=false">
            <div class="assign-keyword-to-agent-form" v-if="!fetchAsync">
                <labeled-select name="Agent" :required="true" v-model="selected_agent">
                    <el-option :key="agent.id"
                               :label="agent | mergeName"
                               v-for="agent in agents"
                               :value="agent.id">
                        {{ agent | mergeName }}
                    </el-option>
                </labeled-select>
            </div>
            <div class="async-container" v-else>
                <vue-loaders-line-scale/>
            </div>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
            </div>

            <span slot="footer">
                <el-button type="text" @click="visible = false">Close</el-button>
                <el-button :loading="submitAsync"
                           type="text"
                           @click="onSubmit">
                    Submit
                </el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import LabeledSelect from '@/components/LabeledSelect/LabeledSelect';
import { FETCH_ALL_USERS_FOR_COMPANY } from '@/store/actions/users';
import { mapActions } from 'vuex';
import { ASSIGN_KEYWORD_TO_AGENT_ACTION } from '@/store/actions/keywords';

export default {
    components: { LabeledSelect },
    name: 'AssignKeywordToAgent',
    props: {
        companyId: String,
        keyword: Object,
    },
    data() {
        return {
            visible: false,
            selected_agent: this.keyword?.agent?.id || null,
        };
    },
    watch: {
        visible: function (newVisible) {
            if (newVisible === true) {
                this.fetchUsersForCompany(this.companyId);
            }
        },
        keyword: function (newKeyword) {
            this.selected_agent = newKeyword?.agent?.id || null;
        },
    },
    computed: {
        agents() {
            return this.$store.getters.allUsers;
        },
        submitAsync() {
            return this.$store.state.keywords.assignAgentAsync;
        },
        fetchAsync() {
            return this.$store.state.users.async;
        },
        errors() {
            return this.$store.state.keywords.assignAgentErrors;
        },
    },
    methods: {
        ...mapActions({
            fetchUsersForCompany: FETCH_ALL_USERS_FOR_COMPANY,
            assignKeywordToAgent: ASSIGN_KEYWORD_TO_AGENT_ACTION,
        }),
        onSubmit() {
            if (this.keyword?.agent?.id !== this.selected_agent) {
                this.assignKeywordToAgent({ id: this.keyword.id, agent_id: this.selected_agent }).then((r) => {
                    if (r) {
                        this.visible = false;
                    }
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.async-container {
    display: flex;
    justify-content: center;
}
</style>
