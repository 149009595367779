<template>
    <div class="content">
        <div class="profile-picture-container">
            <p class="title">PROFILE PICUTRE</p>
            <div class="picture-buttons-container">
                <img class="profile-picture"
                     v-if="profilePictureUrl"
                     :src="profilePictureUrl"/>

                <rounded-initials opt="IN"
                                  :has-check="false"
                                  :customer="user"
                                  v-else/>

                <div class="profile-pic-buttons-container">
                    <el-button @click="changeProfilePicture" class="change-profile-picture-button" type="text">
                        CHANGE
                    </el-button>
                    <el-button @click="deleteProfilePicture" class="delete-profile-picture-button" type="text">
                        DELETE
                    </el-button>
                </div>
            </div>
        </div>
        <div class="info-edit-container">
            <labeled-input v-if="isSuperAdmin" v-model="name" name="Name"/>
            <div class="detail-field" v-else>
                <div class="title">Name</div>
                <div class="value" v-if="name">
                            <span>
                                {{ name }}
                            </span>
                </div>
            </div>

            <labeled-input v-if="isSuperAdmin" v-model="form.email" name="Email"/>
            <div class="detail-field" v-else>
                <div class="title">E-Mail</div>
                <div class="value" v-if="form.email">
                            <span>
                                {{ form.email }}
                            </span>
                </div>
            </div>

            <labeled-input v-model="form.phone" name="Phone"/>

            <labeled-input v-if="form.settings" type="number" :min="0" v-model="form.settings.unread_notification_timeout_minutes"
                           name="Unread notification timeout (minutes)"/>

            <el-button :loading="formAsync"
                       @click="saveUserData"
                       type="text"
                       class="save-edit-info-btn">
                SAVE
            </el-button>

            <div class="password-fields-container" :class="{open: passwordFieldsOpen}">
                <labeled-input type="password" v-model="form.password_1" name="Change Password"/>
                <labeled-input type="password" v-model="form.password_2" name="Confirm Password"/>
            </div>

            <el-button class="save-edit-info-btn"
                       type="text"
                       :class="{isCancel: passwordFieldsOpen}"
                       @click="passwordFieldsOpen = !passwordFieldsOpen">
                <span v-if="!passwordFieldsOpen">CHANGE PASSWORD</span>
                <span v-else>CANCEL</span>
            </el-button>

            <el-button :loading="passwordAsync"
                       v-if="passwordFieldsOpen === true"
                       @click="savePasswordChange"
                       type="text"
                       class="save-edit-info-btn">
                SAVE PASSWORD
            </el-button>
        </div>

        <div class="switch-container">
            <el-switch/>
            <span class="switch-text">LIGHT THEME</span>
        </div>

        <input type="file"
               ref="profilePictureInput"
               style="display: none;"
               accept=".png, .jpg, .jpeg"
               @change="onProfilePicInputChange"/>
    </div>
</template>

<script>

import LabeledInput from '@/components/LabeledInput/LabeledInput';
import { storage_url } from '@/api/apiCall';
import cloneDeep from 'lodash/cloneDeep';
import { SET_LIGHT_MODE } from '@/store/modules/preferences';
import { difference } from '@/utils/utils';
import { mapActions, mapGetters } from 'vuex';
import { CHANGE_USER_PROFILE_PICTURE, EDIT_USER_ACTION } from '@/store/actions/users';
import RoundedInitials from '@/components/RoundedInitials/RoundedInitials';

function transformFormFieldsForSend(form) {
    if (form?.settings?.unread_notification_timeout_minutes) {
        if (form.settings.unread_notification_timeout_minutes === '0') {
            form.settings.unread_notification_timeout_minutes = null;
        } else {
            form.settings.unread_notification_timeout_minutes = +form.settings.unread_notification_timeout_minutes;
        }
    }

    return form;
}

export default {
    name: 'UserTab',
    components: { RoundedInitials, LabeledInput },
    data() {
        return {
            passwordFieldsOpen: false,
            form: cloneDeep(this.user) || {},
            formAsync: false,
            passwordAsync: false,
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters([
            'isImpersonationActive',
            'impersonatedUser',
        ]),
        user() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.$store.getters.me;
        },
        isSuperAdmin() {
            return this.$store.getters.isSuperAdmin;
        },
        lightMode: {
            get() {
                return this.$store.state.preferences.isLightMode;
            },
            set(value) {
                this.$store.commit(SET_LIGHT_MODE, value);
            },
        },
        profilePictureUrl() {
            if (this.user.profile_picture) {
                return `${storage_url}${this.user.profile_picture.content_url}`;
            }

            return null;
        },
        name: {
            get() {
                if (!this.form) return '';

                return [
                    this.form.first_name,
                    this.form.middle_name,
                    this.form.last_name,
                ]
                    .filter(v => v !== null && v !== undefined)
                    .join(' ');
            },
            set(val) {
                const splitName = val.split(' ');

                if (splitName.length === 0) {
                    this.form.first_name = null;
                    this.form.middle_name = null;
                    this.form.last_name = null;
                }

                if (splitName.length === 1) {
                    this.form.first_name = splitName[0];
                    this.form.middle_name = null;
                    this.form.last_name = null;
                }

                if (splitName.length === 2) {
                    this.form.first_name = splitName[0];
                    this.form.middle_name = null;
                    this.form.last_name = splitName[1];
                }

                if (splitName.length === 3) {
                    this.form.first_name = splitName[0];
                    this.form.middle_name = splitName[1];
                    this.form.last_name = splitName[2];
                }
            },
        },
    },
    methods: {
        init() {
            this.form = cloneDeep(this.user) || {};
        },
        ...mapActions({
            editUser: EDIT_USER_ACTION,
            changeUserProfilePictureAction: CHANGE_USER_PROFILE_PICTURE,
        }),
        onImpersonateChange() {
            this.init();
        },
        onProfilePicInputChange(e) {
            const file = e.target.files[0];

            this.changeUserProfilePictureAction({ userId: this.user.id, file });
        },
        changeProfilePicture() {
            this.$refs.profilePictureInput.click();
        },
        deleteProfilePicture() {
            alert('Delete profile picture');
        },
        async savePasswordChange() {
            if (this.form.password_1?.length >= 8 && this.form.password_2?.length >= 8) {
                if (this.form.password_1 === this.form.password_2) {
                    let pw = `${this.form.password_1}`;

                    delete this.form.password_1;
                    delete this.form.password_2;

                    const { value: accept } = await this.$swal.fire({
                        title: 'Are you sure?',
                        text: 'Are you sure you want to change your password ?',
                        icon: 'warning',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonColor: '#F64B5D',
                        cancelButtonColor: '#d8d8d8',
                        confirmButtonText: 'Confirm',
                    });

                    if (accept) {
                        this.passwordAsync = true;

                        this.editUser({ id: this.user.id, password: pw }).then(() => {
                            this.$nextTick(function () {
                                this.passwordAsync = false;
                                this.passwordFieldsOpen = false;
                            });
                        });
                    }
                } else {
                    this.$toast.open({
                        message: 'Passwords dont match',
                        type: 'error',
                    });
                }
            }
        },
        saveUserData() {
            this.formAsync = true;

            const form = transformFormFieldsForSend(cloneDeep(this.form));

            this.editUser({ id: this.user.id, ...difference(form, this.user) }).then(() => {
                this.$nextTick(function () {
                    this.form = cloneDeep(this.user);
                    this.formAsync = false;
                });
            });
        },
    },
    watch: {
        user: function (newUser, oldUser) {
            if (!oldUser && newUser) {
                this.form = cloneDeep(newUser);
            }
        },
    },
};
</script>

<style>
#pane-account {
    flex-direction: column;
}
</style>

<style lang="scss" scoped>
.theme-light {
    .content {
        .profile-picture-container {
            .picture-buttons-container {
                .change-profile-picture-button {
                    color: #B8C5D3;
                }
            }
        }
    }
}

.content {
    max-width: 600px;
    padding: 16px;

    .detail-field {
        margin-top: 14px;
        margin-bottom: 14px;
        font-size: 14px;

        .title {
            margin-bottom: 4px;
            color: #b5b7ba;
            font-weight: 400;
        }

        .value {
            a {
                color: #ffffff;
                font-weight: 400;
                text-decoration: none;
            }

            color: #ffffff;
            font-weight: 400;
        }
    }

    .switch-container {
        margin-top: 12px;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;

        .switch-text {
            margin-left: 8px;
            font-size: 14px;
            color: #748AA1;
            text-align: right;
        }
    }

    .notifications-container {
        flex: 1;
        padding-left: 20px;
        padding-right: 20px;
    }

    .profile-picture-container {
        flex: 1;

        .title {
            color: #748AA1;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
            margin-bottom: 12px;
        }

        .picture-buttons-container {
            display: flex;
            align-items: center;
            flex: 1;

            .profile-pic-buttons-container {
                margin-left: 32px;
            }

            .el-button {
                font-size: 13px;
                font-weight: 900;
            }

            .change-profile-picture-button {
                color: #E5E9F2;
                transition: color 300ms ease-in-out;

                &:hover {
                    color: #94979e;
                }
            }

            .delete-profile-picture-button {
                color: #748AA1;
                transition: color 300ms ease-in-out;

                &:hover {
                    color: #aac3dc;
                }
            }

            .profile-picture {
                width: 64px;
                height: 64px;
                border-radius: 80px;
                object-fit: cover;
            }
        }
    }

    .info-edit-container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        .password-fields-container {
            height: 0px;
            overflow: hidden;
            transition: all 250ms ease-in-out;

            &.open {
                height: 210px;
            }
        }

        .input-container {
            margin-top: 16px;
        }

        .save-edit-info-btn {
            color: #13A68A;
            align-self: flex-end;
            margin-top: 16px;

            &.isCancel {
                color: #aac3dc;
            }

            &:hover {
                color: #13e2af;
            }
        }
    }

    .logout-btn {
        text-align: end;
    }
}
</style>
