import {
    ADD_NEW_USER,
    ADD_NEW_USER_ACTION,
    APPEND_USERS_PAGE,
    CHANGE_USER_PROFILE_PICTURE,
    EDIT_USER_ACTION,
    ENABLE_DISABLE_USER_ACTION,
    FETCH_ALL_USERS_FOR_COMPANY,
    FETCH_USERS_FOR_COMPANY_PAGE,
    RESET_USERS_STATE,
    SET_ADD_EDIT_USER_ASYNC,
    SET_ADD_EDIT_USER_ERRORS,
    SET_USERS,
    SET_USERS_ASYNC,
    SET_USERS_ERRORS,
    UPDATE_USER,
    FETCH_ALL_USERS_ACTION,
    FETCH_USERS_PAGE_ACTION,
    SET_AWAY_MODE_ACTION
} from '../actions/users';
import {
    addNewUser, changePassword,
    changeProfilePictureForUser,
    editUser,
    enableDisableUser,
    fetchUsersPage,
    fetchUsersPageForCompany, setAwayMode,
} from '@/api/users/users';
import { SET_IMPERSONATE, UPDATE_IMPERSONATE, UPDATE_ME } from '@/store/actions/login';
import { cloneDeep } from 'lodash';
import { AGENT_UPDATED } from "@/store/actions/socket_events";

const initialUsersState = () => ({
    users: null,
    async: false,
    errors: null,
    addEditAsync: false,
    addEditErrors: null,
});

export default {
    state: initialUsersState,
    mutations: {
        [SET_USERS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_USERS_ERRORS](state, errors) {
            state.errors = errors;
        },
        [SET_USERS](state, users) {
            state.users = users;
        },
        [APPEND_USERS_PAGE](state, { users, _page }) {
            if (Array.isArray(state.users)) {
                state.users = state.users.concat(users);
            } else {
                state.users = users;
            }
        },
        [RESET_USERS_STATE](state) {
            Object.assign(state, initialUsersState());
        },
        [UPDATE_USER](state, { id, ...data }) {
            if (state.users !== null) {
                for (let user of state.users) {
                    if (user.id === id) {
                        Object.assign(user, data);
                        return;
                    }
                }
            }
        },
        [SET_ADD_EDIT_USER_ASYNC](state, async) {
            state.addEditAsync = async;
        },
        [SET_ADD_EDIT_USER_ERRORS](state, errors) {
            state.addEditErrors = errors;
        },
        [ADD_NEW_USER](state, user) {
            if (state.users !== null) {
                state.users.unshift(user);
            } else {
                state.users = [user];
            }
        },
    },
    actions: {
        async [FETCH_ALL_USERS_FOR_COMPANY]({ commit, dispatch }, companyId) {
            let page = 0;

            commit(SET_USERS_ASYNC, true);
            commit(SET_USERS, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_USERS_FOR_COMPANY_PAGE, { page, companyId });
            }

            commit(SET_USERS_ASYNC, false);
        },
        async [FETCH_USERS_FOR_COMPANY_PAGE]({ commit }, { companyId, page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': users,
                } = await fetchUsersPageForCompany(companyId, page);

                if (Array.isArray(users)) {
                    commit(APPEND_USERS_PAGE, { users, page });
                }

                return Boolean(pagination['hydra:next']);
            } catch (ex) {
                console.error('Fetch users for company', companyId, page);
            }
        },
        async [FETCH_ALL_USERS_ACTION]({ state, commit, dispatch }) {
            let page = 0;

            if (state.async) return;

            commit(SET_USERS_ASYNC, true);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_USERS_PAGE_ACTION, { page });
            }

            commit(SET_USERS_ASYNC, false);
        },
        async [FETCH_USERS_PAGE_ACTION]({ commit }, { page }) {
            try {
                const { 'hydra:view': pagination, 'hydra:member': users } = await fetchUsersPage(page);

                if (Array.isArray(users)) {
                    commit(APPEND_USERS_PAGE, { users, page });
                }

                return Boolean(pagination['hydra:next']);
            } catch (ex) {
                console.error('Fetch users ', page);
            }
        },
        async [ENABLE_DISABLE_USER_ACTION]({ commit, _dispatch }, { userId, enabled }) {
            try {
                const user = await enableDisableUser(userId, enabled);
                console.log(user);
                commit(UPDATE_USER, user);
            } catch (ex) {
                console.error(ex);
            }
        },
        async [EDIT_USER_ACTION]({ commit, getters }, { id, ...user }) {
            commit(SET_ADD_EDIT_USER_ASYNC, true);
            commit(SET_ADD_EDIT_USER_ERRORS, null);

            const actualUser = cloneDeep(user);

            try {
                if (user.password) {
                    await changePassword(id, user.password);

                    delete actualUser.password;
                }

                if (Object.keys(actualUser).length === 0) {
                    commit(SET_ADD_EDIT_USER_ASYNC, false);
                    return;
                }

                const res = await editUser(id, actualUser);
                commit(UPDATE_USER, res);

                if (id === getters.meId) {
                    commit(UPDATE_ME, res);
                }

                if (getters.isImpersonationActive) {
                    if (getters.impersonatedUser?.id === id) {
                        commit(UPDATE_IMPERSONATE, res);
                    }
                }
            } catch (ex) {
                console.error(ex);
                const r = await ex.json();

                commit(SET_ADD_EDIT_USER_ERRORS, [r['hydra:description']]);
                commit(SET_ADD_EDIT_USER_ASYNC, false);

                throw ex;
            }

            commit(SET_ADD_EDIT_USER_ASYNC, false);
        },
        async [ADD_NEW_USER_ACTION]({ commit }, { user }) {
            commit(SET_ADD_EDIT_USER_ASYNC, true);
            commit(SET_ADD_EDIT_USER_ERRORS, null);

            try {
                const res = await addNewUser(user);
                commit(ADD_NEW_USER, res);
            } catch (ex) {
                const r = await ex.json();

                commit(SET_ADD_EDIT_USER_ERRORS, [r['hydra:description']]);
                commit(SET_ADD_EDIT_USER_ASYNC, false);

                throw ex;
            }

            commit(SET_ADD_EDIT_USER_ASYNC, false);
        },
        async [CHANGE_USER_PROFILE_PICTURE]({ commit }, { userId, file }) {
            try {
                const res = await changeProfilePictureForUser(userId, file);

                commit(UPDATE_ME, res);

            } catch (ex) {
                console.error('error changing profile picture for user', userId, ex);
            }
        },
        async [SET_AWAY_MODE_ACTION]({ commit,getters }, { ...data }) {
            try {
                commit(SET_ADD_EDIT_USER_ASYNC, true);
                commit(SET_ADD_EDIT_USER_ERRORS, null);

                if (getters.isImpersonationActive) {
                    const res = await setAwayMode({ userId: getters.impersonatedUser.id, data });
                    commit(SET_IMPERSONATE, res);
                } else {
                    const res = await setAwayMode({ userId: getters.meId, data });
                    commit(UPDATE_ME, res);
                }

            } catch (ex) {

                const r = await ex.json();

                console.log(r);

                commit(SET_ADD_EDIT_USER_ERRORS, [r['detail']]);
            } finally {
                commit(SET_ADD_EDIT_USER_ASYNC, false);
            }
        },
        async [AGENT_UPDATED]({ commit, getters }, data) {
            const jsonData = JSON.parse(data);

            const userId = jsonData.id;

            if (userId === getters.meId) {
                commit(UPDATE_ME, jsonData);
            } else {
                if (getters.isImpersonationActive) {
                    if (getters.impersonatedUser?.id === userId) {
                        commit(UPDATE_IMPERSONATE, jsonData);
                    }
                }
            }
        }
    },
    getters: {
        hasUsers(state) {
            return state.users !== null;
        },
        allUsers(state) {
            if (state.users !== null) {
                return state.users;
            } else {
                return [];
            }
        },
    },
};
