<template>
    <div class="conversation-section">
        <multi-details/>
        <Messenger for-entity="leads" />
    </div>
</template>

<script>
import Messenger from '@/components/Messenger/Messenger';
import { mapGetters } from 'vuex';
import MultiDetails from '@/components/MultiConversationSection/MultiDetails/MultiDetails';

export default {
    name: 'MultiConversationSection',
    components: { MultiDetails, Messenger },
    computed: {
        ...mapGetters([
            'multiSelectedLeadsIds',
        ]),
    },
};
</script>

<style scoped>

</style>
