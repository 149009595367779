<template>
    <div :class="{active: selected}" class="user-company-list-item list-item" @click="$emit('click', company.id)">
        <div class="name">
            {{ company.name }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'UsersCompaniesListItem',
    props: {
        company: Object,
        selected: Boolean,
    },
};
</script>

<style lang="scss" scoped>

.user-company-list-item {
    .name {

    }
}

</style>
