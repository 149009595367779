import { apiCall, endpoint } from '../apiCall';
import { progressFetch } from '@/utils/progressFetch';

export async function fetchMessagesPage(forEntity, entityId, page = 1) {
    let url = new URL(`${endpoint}/api/${forEntity}/${entityId}/messages`);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', 30);

    return apiCall({
        url,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function sendMessage(forEntity, entityId, text) {
    let url = new URL(`${endpoint}/api/${forEntity}/${entityId}/send_message`);

    return apiCall({
        url,
        method: 'POST',
        jsonData: {
            text,
        },
    }).then(r => r.json());
}

export async function sendCsiSurveyMessage(forEntity, entityId) {
    let url = new URL(`${endpoint}/api/${forEntity}/${entityId}/send_csi_survey_message`);

    return progressFetch({
        url,
        method: 'PUT',
        jsonData: {},
    });
}


export async function sendMediaMessage(forEntity, entityId, text, files, uploadProgress = () => {}, signal = null) {
    let url = new URL(`${endpoint}/api/${forEntity}/${entityId}/send_message_with_attachment`);

    let data = new FormData();
    data.append('text', text);
    data.append('file', files[0]);

    return progressFetch({
        url,
        method: 'POST',
        body: data,
        signal,
        uploadProgress,
    });
}


export async function markLeadMessageThreadAsRead(entityId) {
    const url = new URL(`${endpoint}/api/leads/${entityId}/mark_as_read`);

    await apiCall({
        url,
        method: 'PUT',
        jsonData: {},
    });
}


export async function markLeadMessageThreadAsUnread(entityId) {
    const url = new URL(`${endpoint}/api/leads/${entityId}/mark_as_unread`);

    await apiCall({
        url,
        method: 'PUT',
        jsonData: {},
    });
}
