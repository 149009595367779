import { apiCall, notifications } from '@/api/apiCall';

export async function fetchUserNotificationsPage(page) {
    let url = new URL(notifications);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', 10);

    return apiCall({
        url,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function markNotificationRead(notificationId) {
    return apiCall({
        method: "PUT",
        url: `${notifications}/${notificationId}/read`,
        headers: {
            'accept': 'application/json',
        },
        jsonData: {},
    }).then(r => r.json());
}
