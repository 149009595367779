<template>
    <div class="input-container">
        <label>
            <span v-if="showLabel" :class="{required: required}">{{ name }}</span>
            <textarea :name="name"
                      v-model="inputVal"
                      :id="name"
                      :type="type"
                      :autofill="autofill"
                      :rows="rows"
                      :class="{error: errorText}"
                      :placeholder="placeholder || name"/>
            <span v-if="errorText" class="error-text">
                {{ errorText }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'LabeledTextArea',
    props: {
        name: String,
        showLabel: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        rows: {
            type: Number,
            default: 4,
        },
        errorText: {
            type: String,
            default: null,
        },
        placeholder: String,
        value: String,
        autofill: String,
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style scoped lang="scss">
.input-container {
    margin-top: 8px;

    label {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        letter-spacing: 1px;
        color: #748aa1;
        text-transform: uppercase;

        span {
            &.required:after {
                content: " *";
                color: indianred;
            }
        }

        textarea {
            border-radius: 4px;
            margin-top: 6px;
            background-color: transparent !important;
            font-size: 14px;
            line-height: 20px;
            border: 2px solid #3d414b;
            color: #E5E9F2;
            padding: 12px 14px;
            outline: unset;
            transition: all 300ms ease-in-out;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border: 2px solid #3d414b;
                -webkit-text-fill-color: #E5E9F2;
                -webkit-box-shadow: 0 0 0px 1000px #282E36 inset;
                transition: background-color 5000s ease-in-out 0s;
            }


            &::placeholder {
                color: #748AA1;
            }

            &:focus,
            &:active,
            &:hover {
                border: 2px solid #13A68A;
            }

            &.error {
                border: 2px solid #FA5D5D;
            }
        }

        .error-text {
            margin-top: 4px;
            color: #FA5D5D;
            font-size: 14px;
            letter-spacing: 0;
            text-transform: uppercase;
        }
    }
}
</style>
