import { addWindowFocusCallback, removeWindowFocusCallback } from '@/utils/registerWindowFocusLossHandler';

export let WindowFocusPlugin = {
    install: function (Vue, _options) {
        Vue.mixin({
            created() {
                if (typeof this.onWindowFocused === 'function') {
                    addWindowFocusCallback(this.onWindowFocused);
                }
            },
            beforeDestroy() {
                if (typeof this.onWindowFocused === 'function') {
                    removeWindowFocusCallback(this.onWindowFocused);
                }
            }
        })
    },
};
