export const FETCH_COMPANY_AGENCY_SETTINGS_ACTION = 'FETCH_COMPANY_AGENCY_SETTINGS_ACTION';
export const SET_COMPANY_AGENCY_SETTINGS = 'SET_COMPANY_AGENCY_SETTINGS';
export const SET_COMPANY_AGENCY_SETTINGS_ASYNC = 'SET_ACOMPANY_GENCY_SETTINGS_ASYNC';
export const FETCH_COMPANY_SETTINGS_ACTION = 'FETCH_COMPANY_SETTINGS_ACTION';
export const SET_COMPANY_SETTINGS_ASYNC = 'SET_COMPANY_SETTINGS_ASYNC';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';
export const SET_COMPANY_SETTINGS_SAVE_ASYNC = 'SET_COMPANY_SETTINGS_SAVE_ASYNC';
export const SET_COMPANY_SETTINGS_SAVE_ERRORS = 'SET_COMPANY_SETTINGS_SAVE_ERRORS';
export const SAVE_COMPANY_SETTINGS_ACTION = 'SAVE_COMPANY_SETTINGS_ACTION';
export const SAVE_AGENCY_SETTINGS_ACTION = 'SAVE_AGENCY_SETTINGS_ACTION';
export const UPDATE_AGENCY_SETTINGS = 'UPDATE_AGENCY_SETTINGS';
