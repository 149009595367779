<template>
    <div id="app" v-bind:class="{'theme-light': lightMode, 'theme-dark': !lightMode}">
        <Layout v-if="!isLogin && !isLeadLandingPage && !async && user"/>

        <router-view name="landing" v-if="isLeadLandingPage"/>

        <router-view name='login' v-if="isLogin"/>

        <div class="page async-loader-container" v-if="(async || !user) && !isLeadLandingPage">
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>
import Layout from './components/Layout/Layout';
import { STORAGE_KEYS } from './utils/consts';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { DO_ME_ACTION } from './store/actions/login';
import { SET_MENU_IS_COLLAPSED } from './store/actions/menu';
import { ws_endpoint } from './api/apiCall';

export default {
    components: {
        Layout,
    },
    mounted() {
        if (this.user) {
            return;
        }

        const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

        if (!token && !this.isLeadLandingPage) {
            this.$router.push('/login').catch(() => {
            });
            return;
        }

        if (token && !this.user) {
            this.doMe();
        }
    },
    created() {
        window.addEventListener('resize', this.resizeHandler);
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        ...mapActions({
            doMe: DO_ME_ACTION,
        }),
        ...mapMutations({
            setMenuCollapsed: SET_MENU_IS_COLLAPSED,
        }),
        resizeHandler(_e) {
            // should menu be collapsed ?

            const { matches: isWidthLess1280 } = window.matchMedia('(max-width: 1280px)');

            if (isWidthLess1280) {
                this.setMenuCollapsed(true);
            } else {
                this.setMenuCollapsed(false);
            }
        },
        connectSocket() {
            if (this.$socket.connected) {
                this.$socket.close();
            }

            let socketUrl = new URL(ws_endpoint);

            socketUrl.searchParams.append('iam', 'main');

            if (this.isImpersonationActive) {
                socketUrl.searchParams.append('id', this.impersonatedUser.id);
            } else {
                socketUrl.searchParams.append('id', this.user.id);
            }

            this.$socket.io.uri = socketUrl.href;
            this.$socket.open();
        }
    },
    watch: {
        impersonatedUser: function (newImpersonated, oldImpersonated) {
            if (oldImpersonated?.id !== newImpersonated?.id) {
                this.connectSocket();
            }
        },
        user: function (newUser, oldUser) {
            let oldUserId = oldUser ? oldUser.id : null;

            if (oldUserId !== newUser.id) {
                this.connectSocket();
            }
        },
    },
    computed: {
        ...mapGetters([
            'impersonatedUser',
            'isImpersonationActive',
        ]),
        async() {
            return this.$store.state.login.async;
        },
        user() {
            return this.$store.state.login.user;
        },
        isLogin() {
            return this.$route.name === 'Login';
        },
        isLeadLandingPage() {
            return this.$route.name === "LeadLanding";
        },
        lightMode() {
            return this.$store.state.preferences.isLightMode;
        },
    },
    sockets: {
        connect() {
            console.log('WS Connected');
        },
        disconnect() {
            console.log("WS Disconnected");
        },
        reconnect() {
            console.log("WS Reconnect");
        },
    },
};
</script>

<style lang="scss">
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow-x: hidden;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;

    .async-loader-container {
        align-items: center;
        justify-content: center;
    }
}
</style>

