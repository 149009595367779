<template>
    <div class="templates-mode">
        <div class="templates-list-container" v-if="!async">
            <div class="template-list-header">
                <span>Insert Template</span>

                <div class="close" @click="$emit('hide')">
                    <i class="icon-actions_delete"/>
                </div>
            </div>

            <div class="templates-list">
                <div class="template-list-item"
                     v-for="template of userTemplates"
                     :key="template.id"
                     @click="onSelectTemplate(template.text)">
                    <span>{{ template.text }}</span>
                </div>
                <hr/>
                <div class="template-list-item"
                     v-for="template of companyTemplates"
                     :key="template.id"
                     @click="onSelectTemplate(template.text)">
                    <span>{{ template.text }}</span>
                </div>
            </div>
        </div>

        <div v-else class="async-container">
            <vue-loaders-line-scale color="#12131F"/>
        </div>
    </div>
</template>

<script>
import { FETCH_COMPANY_TEMPLATES_ACTION, FETCH_USER_TEMPLATES_ACTION } from '@/store/actions/templates';
import { mapActions } from 'vuex';

export default {
    name: 'AttachmentTemplates',
    methods: {
        ...mapActions({
            fetchUserTemplates: FETCH_USER_TEMPLATES_ACTION,
            fetchCompanyTemplates: FETCH_COMPANY_TEMPLATES_ACTION,
        }),
        onSelectTemplate(text) {
            this.$emit('append-text', text);
            this.$emit('hide');
        },
    },
    mounted() {
        if (!this.hasUserTemplates) {
            this.fetchUserTemplates();
        }

        if (!this.hasCompanyTemplates) {
            this.fetchCompanyTemplates();
        }
    },
    computed: {
        hasUserTemplates() {
            return this.$store.getters.hasUserTemplates;
        },
        hasCompanyTemplates() {
            return this.$store.getters.hasCompanyTemplates;
        },
        companyTemplates() {
            return this.$store.getters.companyTemplates;
        },
        userTemplates() {
            return this.$store.getters.userTemplates;
        },
        async() {
            return this.$store.state.templates.userTemplatesAsync || this.$store.state.templates.companyTemplatesAsync;
        },
    },
};
</script>

<style scoped lang="scss">
.templates-mode {

    .templates-list-container {
        padding-top: 20px;
        display: flex;
        flex: 1;
        flex-direction: column;

        .template-list-header {
            font-weight: 500;
            color: #13A68A;
            margin-left: 20px;
            margin-right: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .close {
                font-size: 18px;
                cursor: pointer;
                color: #60666c;
            }
        }

        .templates-list {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .template-list-item {
                word-break: break-word;
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-left: 20px;
                margin-right: 20px;
                transition: all 300ms ease-in-out;
                cursor: pointer;
                padding-top: 16px;
                padding-bottom: 16px;

                border-bottom: 1px solid #ddd;

                &:hover {
                    color: #13A68A;
                }
            }
        }
    }

    .async-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
