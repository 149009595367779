<template>
    <span>
        <el-button v-tooltip="'Edit Property'"
                   class="open-button"
                   type="text"
                   @click.stop="visible = true">
            <i class="icon icon-actions_edit"/>
        </el-button>

        <el-dialog :append-to-body="true"
                   :modal-append-to-body="true"
                   class="edit-property-dialog-content"
                   title="Edit Property"
                   :visible.sync="visible">
            <div class="edit-property-dialog">
                <labeled-input required name="Name" v-model="form.name"/>
                <labeled-input required name="URL" v-model="form.url"/>
                <labeled-text-area required name="Description" v-model="form.description"/>
                <div class="price-container">
                    <labeled-input class="price-input" :disabled="form.isPriceNa || form.isPriceNegotiable" required
                                   name="Price" type="number"
                                   v-model="form.price"/>
                    <div class="switches-container">
                        <div class="switch-container">
                            <el-switch @change="priceNaChange" :value="form.isPriceNa"/>
                            <span class="switch-text">N/A</span>
                        </div>
                        <div class="switch-container">
                            <el-switch @change="priceNegotiableChange" :value="form.isPriceNegotiable"/>
                            <span class="switch-text">Negotiable</span>
                        </div>
                        <div class="switch-container">
                            <el-switch v-model="form.has_generic_url"/>
                            <span class="switch-text">Generic Url</span>
                        </div>
                    </div>
                </div>

                <labeled-select name="Keyword"
                                v-model="form.keyword_id"
                                placeholder="Keyword">
                    <el-option :label="keyword.name"
                               :value="keyword.id"
                               :key="keyword.id"
                               v-for="keyword in keywords">
                        {{ keyword.name }}
                    </el-option>
                    <el-option :label="property.keyword.name"
                               v-if="property.keyword"
                               :value="property.keyword.id"
                               :key="property.keyword.id">
                        {{ property.keyword.name }}
                    </el-option>
                </labeled-select>
                <labeled-select name="Status"
                                v-model="form.status"
                                placeholder="Status">
                    <el-option :label="status.label"
                               :value="status.value"
                               :key="status.value"
                               :style="{display: isPropertyStatusOptionDisabled(status.value) ? 'none' : 'block'}"
                               :disabled="isPropertyStatusOptionDisabled(status.value)"
                               v-for="status in propertyStatusOptions">
                        {{ status.label }}
                    </el-option>
                </labeled-select>
                <labeled-select name="Property Agent"
                                v-if="isCompanyAdmin || isSuperAdmin"
                                v-model="form.agent_id"
                                placeholder="Agent">
                    <el-option :label="user | mergeName"
                               :value="user.id"
                               :key="user.id"
                               v-for="user in users">
                        {{ user | mergeName }}
                    </el-option>
                </labeled-select>
                <labeled-text-area v-model="form.note" name="Property Notes"/>
            </div>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
            </div>

            <span slot="footer">
                    <el-button type="text" class="close-btn" @click="visible = false">Close</el-button>
                    <el-button :loading="async"
                               type="text"
                               @click="onSubmit">
                        Submit
                    </el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import LabeledInput from '../../../../components/LabeledInput/LabeledInput';
import {
    ASSIGN_KEYWORD_TO_PROPERTY_ACTION,
    CHANGE_PROPERTY_AGENT_ACTION,
    EDIT_PROPERTY_ACTION,
} from '@/store/actions/properties';
import { mapActions } from 'vuex';

import cloneDeep from 'lodash/cloneDeep';
import {difference, isEmptyObject, userIsCompanyAdmin} from '@/utils/utils';
import LabeledTextArea from '@/components/LabeledTextArea/LabeledTextArea';
import LabeledSelect from '@/components/LabeledSelect/LabeledSelect';
import { FETCH_ALL_USERS_ACTION } from '@/store/actions/users';

const getDefaultProperty = () => ({
    name: '',
    description: '',
    phone: '',
    price: '',
    agent_id: null,
});

const transformFormFields = (form) => {
    if (form.agent) {
        form.agent_id = form.agent.id;
        delete form.agent;
    }

    if (form.keyword) {
        form.keyword_id = form.keyword.id;
        delete form.keyword;
    }

    if (form.price === 'Negotiable') {
        form.isPriceNegotiable = true;
    }

    if (form.price === 'N/A') {
        form.isPriceNa = true;
    }

    if (form.status?.length) {
        form.status = form.status[form.status.length - 1].status;
    }

    return form;
};

export default {
    name: 'EditPropertyDialog',
    components: { LabeledSelect, LabeledInput, LabeledTextArea },
    data() {
        return {
            visible: false,
            form: transformFormFields(cloneDeep(this.property)),
        };
    },
    props: {
        property: {
            type: Object,
            default: getDefaultProperty(),
        },
    },
    methods: {
      userIsCompanyAdmin,
        ...mapActions({
            editProperty: EDIT_PROPERTY_ACTION,
            fetchAllUsers: FETCH_ALL_USERS_ACTION,
            changePropertyAgent: CHANGE_PROPERTY_AGENT_ACTION,
            assignKeywordToProperty: ASSIGN_KEYWORD_TO_PROPERTY_ACTION,
        }),
        isPropertyStatusOptionDisabled(option) {
            return ['SOLD', 'OFF_MARKET'].includes(option);
        },
        async onSubmit() {

            const data = difference(this.form, this.property);

            if (data.agent_id && data.agent_id !== this.property?.agent?.id) {
                await this.changePropertyAgent({ propertyId: this.property.id, agentId: data.agent_id });
            }

            if (data.keyword_id && data.keyword_id !== this.property?.keyword?.id) {
                await this.assignKeywordToProperty({ propertyId: this.property.id, keywordId: data.keyword_id });
            }

            if (data.isPriceNa) {
                data.price = 'N/A';
            }

            if (data.isPriceNegotiable) {
                data.price = 'Negotiable';
            }

            const lastActiveStatus = this.property?.status[this.property?.status?.length - 1]?.status;
            if (this.form.status !== lastActiveStatus) {
                data.new_status = this.form.status;
            }

            delete data.keyword_id;
            delete data.keyword;
            delete data.agent_id;
            delete data.agent;
            delete data.isPriceNa;
            delete data.isPriceNegotiable;
            delete data.status;

            if (!isEmptyObject(data)) {
                this.editProperty({ id: this.property.id, ...data }).then(() => {
                    this.$nextTick(function () {
                        this.form = transformFormFields(cloneDeep(this.property));
                        this.visible = false;
                    });
                });
            } else {
                this.visible = false;
            }
        },
        priceNegotiableChange(val) {
            console.log('pricenegotiablechange', val);
            const form = cloneDeep(this.form);

            form.isPriceNegotiable = val;

            if (val) {
                form.isPriceNa = false;
            }

            this.form = form;
        },
        priceNaChange(val) {
            console.log('pricenachange', val);
            const form = cloneDeep(this.form);
            form.isPriceNa = val;

            if (val) {
                form.isPriceNegotiable = false;
            }

            this.form = form;
        },
    },
    computed: {
        errors() {
            return this.$store.state.properties.editErrors;
        },
        async() {
            return this.$store.state.properties.editAsync;
        },
        isSuperAdmin() {
            return this.$store.getters.isSuperAdmin;
        },
        isCompanyAdmin() {
          return this.$store.getters.isCompanyAdmin;
        },
        usersAsync() {
            return this.$store.state.users.async;
        },
        hasUsers() {
            return this.$store.getters.hasUsers;
        },
        users() {
            return this.$store.getters.allUsers;
        },
        keywords() {
            return this.$store.getters.availableKeywords;
        },
        propertyStatusOptions() {
            return this.$store.getters.propertyStatusOptions;
        },
    },
    mounted() {
        if (this.isSuperAdmin && !this.hasUsers) {
            this.fetchAllUsers();
        }
    },
};
</script>

<style lang="scss">

.edit-property-dialog {
    .price-input {
        input {
            &:disabled {
                opacity: 0.5;
            }
        }
    }
}
</style>

<style lang="scss" scoped>

.edit-property-dialog-content {
    text-align: left !important;

    .close-btn {
        margin-right: 18px;
        color: #b5b7ba;
        transition: all 300ms ease-in-out;

        &:hover {
            color: #13A68A;
        }
    }

    .price-container {
        .switches-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .switch-container {
                padding-right: 12px;
                padding-top: 4px;
                padding-bottom: 4px;
                color: #FFFFFF;

                span {
                    margin-left: 8px;
                }
            }
        }
    }
}

.open-button {
    color: #748AA1;
    font-size: 16px;

    .icon {
        font-size: 20px;
        transition: all 300ms ease;

        &:hover {
            color: #13A68A;
        }
    }

    &:hover {
        color: #748AA1;
    }
}

</style>
