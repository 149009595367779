<template>
    <div class="list-container">
        <div class="important-tab">
            <lead-list-item v-for="lead in allLeads"
                                      @on-click="onClickListItem"
                                      :lead="lead"
                                      :key="lead.id"/>

            <div class="nothing-here" v-if="!hasLeads">
                <span>Nothing Here</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LeadListItem from '../components/LeadListItem/LeadListItem';
import { SELECT_LEAD_ACTION } from '@/store/actions/leads';

export default {
    name: 'ImportantTab',
    components: { LeadListItem },
    methods: {
        ...mapActions({
            selectLead: SELECT_LEAD_ACTION,
        }),
        onClickListItem(leadId) {
            this.selectLead(leadId);
        },
    },
    computed: {
        ...mapGetters({
            hasLeads: 'hasLeads',
            allLeads: 'importantLeads',
        }),
    },
};
</script>

<style scoped lang="scss">
.important-tab {
    color: #FFF;

    .nothing-here {
        font-size: 15px;
        color: #4c545d;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
