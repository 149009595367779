import {
    ADD_COMPANY,
    ADD_NEW_COMPANY_ACTION,
    APPEND_COMPANIES_PAGE,
    DELETE_COMPANY_ACTION,
    EDIT_COMPANY_ACTION,
    FETCH_ALL_COMPANIES_ACTION,
    FETCH_COMPANIES_PAGE_ACTION,
    REMOVE_COMPANY,
    SET_ADD_EDIT_COMPANY_ASYNC,
    SET_ADD_EDIT_COMPANY_ERRORS,
    SET_COMPANIES,
    SET_COMPANIES_ASYNC,
    SET_SELECTED_COMPANY,
    UPDATE_COMPANY,
    GENERATE_KEYWORDS_ACTION
} from '../actions/companies';
import { addNewCompany, deleteCompany, editCompany, fetchCompaniesPage } from '@/api/companies/companies';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { SET_ADD_EDIT_USER_ERRORS } from '../actions/users';
import { generateKeywords } from "@/api/keywords/keywords";

export default {
    state: () => ({
        companies: null,
        selectedCompanyId: null,
        async: false,
        addEditAsync: false,
        addEditErrors: null,
    }),
    mutations: {
        [SET_COMPANIES_ASYNC](state, async) {
            state.async = async;
        },
        [SET_COMPANIES](state, companies) {
            state.companies = companies;
        },
        [APPEND_COMPANIES_PAGE](state, companies, _page) {
            if (state.companies !== null) {
                state.companies = state.companies.concat(companies);
            } else {
                state.companies = [...companies];
            }
        },
        [SET_ADD_EDIT_COMPANY_ASYNC](state, async) {
            state.addEditAsync = async;
        },
        [SET_ADD_EDIT_COMPANY_ERRORS](state, errors) {
            state.addEditErrors = errors;
        },
        [ADD_COMPANY](state, company) {
            if (state.companies !== null) {
                state.companies.unshift(company);
            } else {
                state.companies = [company];
            }
        },
        [REMOVE_COMPANY](state, companyId) {
            if (state.companies !== null) {
                state.companies = state.companies.filter(company => company.id !== companyId);
            }
        },
        [UPDATE_COMPANY](state, { id, ...data }) {
            for (let company of state.companies) {
                if (company.id === id) {
                    Object.assign(company, data);
                    return;
                }
            }
        },
        [SET_SELECTED_COMPANY](state, companyId) {
            if (state.selectedCompanyId === companyId) {
                state.selectedCompanyId = null;
            } else {
                state.selectedCompanyId = companyId;
            }
        },
    },
    actions: {
        async [FETCH_ALL_COMPANIES_ACTION]({ commit, dispatch }) {
            let page = 0;

            commit(SET_COMPANIES_ASYNC, true);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_COMPANIES_PAGE_ACTION, { page });
            }

            commit(SET_COMPANIES_ASYNC, false);
        },
        async [FETCH_COMPANIES_PAGE_ACTION]({ commit }, { page }) {
            try {
                const { 'hydra:view': pagination, 'hydra:member': companies } = await fetchCompaniesPage(page);
                if (Array.isArray(companies)) {
                    // good, we have stuff
                    commit(APPEND_COMPANIES_PAGE, companies, page);
                }

                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Companies Page', ex);
            }
        },
        async [ADD_NEW_COMPANY_ACTION]({ commit }, { company }) {
            commit(SET_ADD_EDIT_COMPANY_ASYNC, true);

            const { name, phone } = company;

            const errors = [];

            if (!name || !phone) {
                errors.push('Name and phone are required');


                commit(SET_ADD_EDIT_COMPANY_ERRORS, errors);
                commit(SET_ADD_EDIT_COMPANY_ASYNC, false);
                return false;
            }

            try {
                const rCompany = await addNewCompany(omitBy(company, isNil));

                commit(ADD_COMPANY, rCompany);

                commit(SET_ADD_EDIT_COMPANY_ASYNC, false);
                return true;
            } catch (ex) {
                console.error('add company', ex);

                const data = await ex.json();
                const { 'hydra:description': error } = data;

                commit(SET_ADD_EDIT_COMPANY_ERRORS, [error]);
                commit(SET_ADD_EDIT_COMPANY_ASYNC, false);
                throw ex;
            }
        },
        async [DELETE_COMPANY_ACTION]({ commit }, { companyId }) {
            try {
                await deleteCompany(companyId);
                commit(REMOVE_COMPANY, companyId);
                return true;
            } catch (ex) {
                console.error('delete company', ex);
                throw ex;
            }
        },
        async [EDIT_COMPANY_ACTION]({ commit }, { id, ...rest }) {
            commit(SET_ADD_EDIT_COMPANY_ASYNC, true);
            commit(SET_ADD_EDIT_USER_ERRORS, null);

            try {
                const res = await editCompany(id, rest);
                commit(UPDATE_COMPANY, res);
            } catch (ex) {
                const r = await ex.json();
                commit(SET_ADD_EDIT_COMPANY_ERRORS, [r['hydra:description']]);
                commit(SET_ADD_EDIT_COMPANY_ASYNC, false);
                throw ex;
            }
            commit(SET_ADD_EDIT_COMPANY_ASYNC, false);
        },
        async [GENERATE_KEYWORDS_ACTION]({ commit }, { companyId, ...rest }) {

            commit(SET_ADD_EDIT_COMPANY_ASYNC, true);

            try {
                const res = await generateKeywords({ companyId, ...rest });
                commit(UPDATE_COMPANY, res);
            } catch (ex) {
                const r = await ex.json();
                commit(SET_ADD_EDIT_COMPANY_ERRORS, [r['hydra:description']]);
                commit(SET_ADD_EDIT_COMPANY_ASYNC, false);
                throw ex;
            }
            commit(SET_ADD_EDIT_COMPANY_ASYNC, false);
        },
    },
    getters: {
        selectedCompany(state) {
            if (state.companies !== null) {
                return state.companies.find(company => company.id === state.selectedCompanyId) || null;
            } else {
                return null;
            }
        },
        selectedCompanyId(state) {
            return state.selectedCompanyId;
        },
        hasCompanies(state) {
            return state.companies !== null;
        },
        allCompanies(state) {
            if (state.companies !== null) {
                return state.companies;
            } else {
                return [];
            }
        },
    },
};
