<template>
    <div class="template">
        <div v-if="!isEditMode" class="template-text">
            {{ template.text }}
        </div>
        <div v-else class="template-edit-mode">
            <labeled-text-area v-model="text"/>

            <div class="buttons-container">
                <el-button class="close-btn" @click="closeEditMode" type="text">
                    CLOSE
                </el-button>
                <el-button :loading="async"
                           @click="onEditTemplate"
                           type="text">
                    SAVE
                </el-button>
            </div>
        </div>
        <div class="template-actions" v-if="!isEditMode">
            <div @click="isEditMode = true"
                 v-tooltip="'Edit Template'"
                 v-if="!isEditMode"
                 class="edit action">
                <i class="icon icon-actions_edit"/>
            </div>
            <div v-tooltip="'Delete Template'"
                 @click="onDeleteTemplate"
                 class="delete action">
                <i class="icon icon-actions_delete"/>
            </div>
        </div>
    </div>
</template>

<script>
import LabeledTextArea from '@/components/LabeledTextArea/LabeledTextArea';
import { showAreYouSureSwal } from '@/utils/utils';

export default {
    name: 'SettingsTemplate',
    components: { LabeledTextArea },
    props: {
        template: Object,
        editTemplate: Function,
        deleteTemplate: Function,
    },
    data() {
        return {
            isEditMode: false,
            text: this.template?.text || '',
            async: false,
        };
    },
    methods: {
        async onEditTemplate() {
            if (this.text !== this.template?.text) {
                this.async = true;

                await this.editTemplate({ id: this.template?.id, text: this.text });
                this.isEditMode = false;
                this.async = false;
            }
        },
        onDeleteTemplate() {
            showAreYouSureSwal(this.$swal, { text: 'Are you sure you want to do delete this template ?' }).then(result => {
                if (result.value) {
                    this.deleteTemplate(this.template.id);
                }
            });
        },
        closeEditMode() {
            this.isEditMode = false;
            this.text = this.template?.text || '';
        },
    },
};
</script>

<style lang="scss">
.template {
    .template-edit-mode {
        .input-container {
            margin-top: 0px;

            textarea {
                margin-top: 0px;
                resize: none;
            }
        }
    }
}

</style>

<style scoped lang="scss">
.template {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 #586471;
    transition: all 300ms ease-in-out;
    word-break: break-word;

    .template-text {
        font-size: 14px;
        color: #b5b7ba;
        width: 100%;
    }

    .template-edit-mode {
        width: 100%;

        .buttons-container {
            text-align: right;

            .close-btn {
                color: #b5b7ba;
                transition: all 300ms ease-in-out;

                &:hover {
                    color: #13A68A;
                }
            }
        }
    }

    .template-actions {
        display: flex;
        margin-left: 32px;

        .action {
            cursor: pointer;
            color: #b5b7ba;
            margin-left: 16px;
            transition: all 300ms ease-in-out;
            font-size: 20px;

            &:hover {
                color: #13A68A;
            }
        }
    }
}
</style>
