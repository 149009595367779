import { apiCall } from '@/api/apiCall';

export async function fetchFrontendRevision() {
    let url = new URL(window.location.origin + "/REVISION");

    return apiCall({
        url,
        headers: {
            'accept': 'text/plain',
        },
    }).then(r => r.text());
}
