<template>
    <div class="search-bar">
        <el-input ref="searchInputRef" :class="{shown: searchEnabled}" v-model="searchText" placeholder="Search"/>

        <div v-on:click="searchEnabled = !searchEnabled" class="search-button">
            <i class="icon icon-search"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchBar',

    data() {
        return {
            searchText: null,
            searchEnabled: false,
        };
    },

    watch: {
        searchEnabled: function (newSearchEnabled) {
            if (newSearchEnabled === false) {
                this.searchText = null;
            } else {
                this.$refs.searchInputRef.focus();
            }
        },

        searchText: function (newSearchText) {
            this.$emit('change', newSearchText);
        },
    },
};
</script>

<style lang="scss">
.theme-light {
    .search-bar {
        .el-input {
            .el-input__inner {
                border-bottom: 1px solid #545F69;
                color: #b5b7ba;
            }
        }
    }
}

.search-bar {
    .el-input {
        .el-input__inner {
            padding-left: 8px;
            padding-right: 8px;
            background-color: transparent;
            border: none;
            border-bottom: none;
            border-radius: unset;
            color: #FFFFFF;
            transition: padding 300ms ease-in-out;

            &::placeholder {
                color: #b5b7ba;
            }
        }

        &.hidden {
            .el-input__inner {
                padding: 0;
                border: none;
            }
        }
    }
}

</style>

<style scoped lang="scss">

.theme-light {
    .search-bar {
        .el-input {
            .el-input__inner {
                border-bottom: none;
                color: #545F69;
            }
        }

        .search-button {
            .icon {
                color: #545F69;
            }
        }
    }
}

.search-bar {
    padding: 16px;
    padding-top: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;

    .el-input {
        width: 0;
        transition: all 300ms ease-in-out;

        &.shown {
            width: 100%;
        }
    }

    .search-button {
        cursor: pointer;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;

        &:hover {
            .icon {
                color: #13A68A;
            }
        }

        .icon {
            font-size: 14px;
            color: #44515d;
            transition: all 300ms ease-in-out;

        }
    }
}

</style>
