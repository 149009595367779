import { keywords, apiCall, companies } from '../apiCall';

export async function fetchAllKeywords() {

    return apiCall({
        url: keywords,
        headers: {
            'accept': 'application/json',
        },
    }).then(r => r.json());
}

export async function generateKeywords({ companyId, amount, keywordLength, prefix, suffix, exactKeywords }) {

    return apiCall({
        url: `${companies}/${companyId}/generate_keywords`,
        method: 'PUT',
        headers: {
            'accept': 'application/json',
        },
        jsonData: {
            company_id: companyId,
            keyword_length: keywordLength,
            exact_keywords: exactKeywords || [],
            amount,
            prefix,
            suffix,
        },
    }).then(r => r.json());
}

export async function assignKeywordToAgent({ id, agent_id }) {
    return apiCall({
        url: `${keywords}/${id}/assign_to_agent`,
        method: 'PUT',
        headers: {
            'accept': 'application/json',
        },
        jsonData: {
            agent_id
        },
    }).then(r => r.json());
}

export async function editKeyword({ id, data }) {
    return apiCall({
        url: `${keywords}/${id}`,
        method: 'PUT',
        headers: {
            'accept': 'application/json',
        },
        jsonData: data
    }).then(r => r.json());
}