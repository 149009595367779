export const FETCH_ALL_COMPANIES_ACTION = 'FETCH_ALL_COMPANIES_ACTION';
export const FETCH_COMPANIES_PAGE_ACTION = 'FETCH_COMPANIES_PAGE_ACTION';
export const SET_COMPANIES_ASYNC = 'SET_COMPANIES_ASYNC';
export const APPEND_COMPANIES_PAGE = 'APPEND_COMPANIES_PAGE';
export const SET_COMPANIES = 'SET_COMPANIES';
export const ADD_NEW_COMPANY_ACTION = 'ADD_NEW_COMPANY_ACTION';
export const SET_ADD_EDIT_COMPANY_ASYNC = 'SET_ADD_EDIT_COMPANY_ASYNC';
export const SET_ADD_EDIT_COMPANY_ERRORS = 'SET_ADD_EDIT_COMPANY_ERRORS';
export const ADD_COMPANY = 'ADD_COMPANY';
export const DELETE_COMPANY_ACTION = 'DELETE_COMPANY_ACTION';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const EDIT_COMPANY_ACTION = 'EDIT_COMPANY_ACTION';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const GENERATE_KEYWORDS_ACTION = 'GENERATE_KEYWORDS_ACTION'
