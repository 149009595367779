<template>
    <div class="messenger">
        <div ref="msgScrollContainer" class="msg-list-container" @scroll="onListScroll">
            <div key=1 v-if="!hasMessages && !async">
                <transition appear name="slide-right">
                    <div class="no-messages">No messages here...</div>
                </transition>
            </div>

            <div class="async-container" key=2 v-if="async">
                <transition appear name="slide-right">
                    <vue-loaders-line-scale color="#12131F"/>
                </transition>
            </div>

            <div class="msg-list"
                 ref="msgList"
                 key=3
                 v-if="hasMessages">
                <Message :key="message.id"
                         :message="message"
                         v-for="(message) in messages"/>
            </div>
        </div>

        <message-bar v-if="!isOptedOut"
                     :for-entity="forEntity"
                     :entity-id="entityId"/>
    </div>
</template>

<script>
import Message from './Message/Message';
import MessageBar from './MessageBar/MessageBar';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
    FETCH_ALL_MESSAGES_ACTION,
    FETCH_MESSAGES_PAGE_ACTION,
    RESET_MESSENGER_STATE,
    SET_MESSAGES_ASYNC,
} from '@/store/actions/messenger';

export default {
    name: 'Messenger',
    components: { MessageBar, Message },
    props: {
        forEntity: String,
        entityId: String,
    },
    data() {
        return {
            page: 1,
            hasMore: true,
        };
    },
    mounted() {
        this.resetMessengerState();

        if (!this.hasMessages && !this.isLeadsMultiselectActive) {
            this.fetchNextMessagesPage();
        }
    },
    watch: {
        entityId(newEntityId, oldEntityId) {
            if (newEntityId !== '') {
                if (newEntityId !== oldEntityId) {
                    this.resetMessengerState();

                    this.page = 1;
                    this.hasMore = true;

                    this.fetchNextMessagesPage();
                }
            }
        },
    },
    methods: {
        onListScroll() {
            if (this.$refs.msgScrollContainer && this.$refs.msgScrollContainer.scrollTop === 0) {
                let initialHeight = this.$refs.msgScrollContainer.scrollHeight;
                let vm = this;

                if (this.hasMore) {
                    const res = vm.fetchNextMessagesPage();
                    if (res) {
                        res.then(() => {
                            vm.$nextTick(() => {
                                vm.$refs.msgScrollContainer.scrollTop = vm.$refs.msgScrollContainer.scrollHeight - initialHeight;
                            });
                        });
                    }
                }
            }
        },
        ...mapActions({
            fetchAllMessages: FETCH_ALL_MESSAGES_ACTION,
            fetchMessagesPage: FETCH_MESSAGES_PAGE_ACTION,
        }),
        ...mapMutations({
            setMessagesAsync: SET_MESSAGES_ASYNC,
            resetMessengerState: RESET_MESSENGER_STATE,
        }),
        fetchNextMessagesPage() {
            if (this.async || this.isLeadsMultiselectActive) return;

            this.setMessagesAsync(true);

            return this.fetchMessagesPage({
                forEntity: this.forEntity,
                entityId: this.entityId,
                page: this.page,
            }).then((hasMore) => {
                this.setMessagesAsync(false);

                this.hasMore = hasMore;

                if (hasMore) {
                    this.page += 1;
                }
            });
        },
    },
    computed: {
        ...mapGetters({
            messages: 'allMessages',
            selectedLead: 'selectedLead',
            isLeadsMultiselectActive: 'isLeadsMultiselectActive',
        }),
        isOptedOut() {
            return this.selectedLead !== null && this.selectedLead.opt_status === 'OUT';
        },
        async() {
            return this.$store.state.messenger.async;
        },
        hasMessages() {
            if (this.messages !== null) {
                if (this.messages.length > 0) {
                    return true;
                }
            }

            return false;
        },
    },
};
</script>

<style scoped lang="scss">
.theme-light {
    .messenger {
        background-color: #ffffff;
    }
}

.messenger {
    width: 55%;
    height: 100%;
    background-color: #F3F4F7;
    display: flex;
    flex-direction: column;
    flex: 1;

    .async-container {
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 24px;
        flex: 1;
        flex-direction: column;
    }

    .msg-list-container {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .no-messages {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        font-size: 14px;
    }

    .msg-list {
        display: flex;
        overflow-x: hidden;
        flex-direction: column;
        flex: 1;
    }
}
</style>
