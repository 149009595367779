<template>
    <el-dialog :title="title" :visible="visible" @close="$emit('update:visible', false)">
        <div class="add-edit-company-form">
            <labeled-input required name="Name" v-model="form.name"/>
            <labeled-input required name="Phone" v-model="form.phone"/>
            <labeled-input name="Address" v-model="form.address"/>
            <labeled-input name="City" v-model="form.city"/>
            <labeled-input name="Postal Code" v-model="form.postal_code"/>
            <labeled-input name="State" v-model="form.state"/>
        </div>

        <div class="errors-container" style="margin-top: 24px;">
            <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
        </div>

        <span slot="footer">
                <el-button type="text" @click="$emit('update:visible', false)">Close</el-button>
                <el-button :loading="async"
                           type="text"
                           @click="onSubmit">
                    Submit
                </el-button>
            </span>
    </el-dialog>
</template>

<script>
import LabeledInput from '../../../../components/LabeledInput/LabeledInput';
import { mapActions } from 'vuex';
import { ADD_NEW_COMPANY_ACTION, EDIT_COMPANY_ACTION } from '@/store/actions/companies';

import cloneDeep from 'lodash/cloneDeep';
import { difference } from '@/utils/utils';

function getDefaultCompanyObject() {
    return {
        name: null,
        phone: null,
        address: null,
        city: null,
        postal_code: null,
        state: null,
    };
}

export default {
    name: 'AddEditCompanyDialog',
    components: { LabeledInput },
    data() {
        return {
            form: cloneDeep(this.company),
        };
    },
    props: {
        visible: Boolean,
        company: {
            type: Object,
            default: getDefaultCompanyObject,
        },
    },
    watch: {
        visible(newVisible) {
            if (newVisible === true) {
                this.form = cloneDeep(this.company);
            }
        },
    },
    methods: {
        ...mapActions({
            addNewCompany: ADD_NEW_COMPANY_ACTION,
            editCompany: EDIT_COMPANY_ACTION,
        }),
        onSubmit() {
            if (this.isEditMode) {
                this.editCompany({ id: this.company.id, ...difference(this.form, this.company) }).then(() => {
                    this.$nextTick(function () {
                        this.form = cloneDeep(this.company);
                        this.$emit("hide");
                    });
                });
            } else {
                this.addNewCompany({ company: this.form }).then((res) => {
                    if (res !== false) {
                        this.form = getDefaultCompanyObject();
                        this.$emit("hide");
                    }
                });
            }
        },
    },
    computed: {
        title() {
            if (this.isEditMode) {
                return 'Edit Company';
            } else {
                return 'Add Company';
            }
        },
        isEditMode() {
            return Boolean(this.company.id);
        },
        errors() {
            return this.$store.state.companies.addEditErrors;
        },
        async() {
            return this.$store.state.companies.addEditAsync;
        },
    },
};
</script>
