<template>
    <div class="property-note-container" v-if="isEditMode">
        <labeled-text-area v-model="note" name="Property Notes"/>
        <div class="buttons-container">
            <el-button @click="isEditMode = false" type="text">
                CANCEL
            </el-button>

            <el-button :loading="editAsync"
                       @click="onSaveNote"
                       type="text">
                SAVE
            </el-button>
        </div>
    </div>

    <div class="property-note-container display" v-else>
        <div class="property-note-header" @click="onEditClick">
            <span class="property-note-title">Property Notes</span>

            <div class="add-edit-icon">
                <div @click="onEditClick"
                     v-if="note == null || note.length === 0"
                     v-tooltip="'Add Note'">
                    <i class="icon icon-addnew"/>
                </div>
                <div @click="onEditClick" v-tooltip="'Edit Notes'" v-else>
                    <i class="icon icon-actions_edit"/>
                </div>
            </div>
        </div>
        <div class="property-note">
            {{ note }}
        </div>
    </div>
</template>

<script>
import LabeledTextArea from '@/components/LabeledTextArea/LabeledTextArea';
import { EDIT_PROPERTY_ACTION } from '@/store/actions/properties';
import { mapActions } from 'vuex';

export default {
    name: 'EditPropertyNote',
    components: { LabeledTextArea },
    props: {
        property: Object,
    },
    computed: {
        editAsync() {
            return this.$store.state.properties.editAsync;
        }
    },
    watch: {
        property: {
            deep: true,
            handler: function (val) {
                this.note = val?.note;
                this.isEditMode = false;
            }
        }
    },
    methods: {
        ...mapActions({
            editPropertyAction: EDIT_PROPERTY_ACTION,
        }),
        onEditClick() {
            this.isEditMode = true;
        },
        onSaveNote() {
            if (this.property?.note !== this.note && this.property?.id !== null) {
                this.editPropertyAction({
                    id: this.property?.id,
                    note: this.note,
                }).then(() => {
                    this.$nextTick(function () {
                        this.note = this.property?.note;
                        this.isEditMode = false;
                    });
                });
            }
        },
    },
    data() {
        return {
            isEditMode: this.property?.note === null || this.property?.note.length === 0,
            note: this.property?.note,
        };
    },
};
</script>

<style lang="scss" scoped>
.property-note-container {
    padding-left: 12px;
    padding-right: 12px;

    .buttons-container {
        text-align: right;
    }

    &.display {
        color: #b5b7ba;
    }

    .property-note-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #b5b7ba;
        padding-bottom: 8px;
        transition: all 300ms ease-in-out;
        cursor: pointer;

        &:hover {
            color: #13A68A;

            .add-edit-icon {
                .icon {
                    color: #13A68A;
                }
            }
        }

        .add-edit-icon {
            font-size: 20px;
            display: flex;
            align-items: center;

            .icon {
                transition: all 300ms ease-in-out;
                color: #b5b7ba;
                cursor: pointer;
            }
        }

        .property-note-title {
            font-size: 15px;
        }
    }

    .property-note {
        padding-top: 12px;
        font-size: 14px;
        color: #ffffff;
    }
}
</style>
