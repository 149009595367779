<template>
    <div>
        <el-button type="text" @click="dialogVisible = true">ADD NEW COMPANY</el-button>

        <add-edit-company-dialog @hide="dialogVisible = false" :visible="dialogVisible" />
    </div>
</template>

<script>
import AddEditCompanyDialog from '../AddEditCompanyDialog/AddEditCompanyDialog';

export default {
    name: 'AddNewCompanyDialog',
    components: { AddEditCompanyDialog },
    data() {
        return {
            dialogVisible: false,
        };
    },
};
</script>
