import { apiCall, login, me } from '../apiCall';
import { STORAGE_KEYS } from '@/utils/consts';

export async function doLogin(email, password) {
    return apiCall({
        url: login,
        method: 'POST',
        jsonData: {
            email,
            password,
        },
    })
        .then(r => r.json())
        .then(r => {
            // get token, set to storage, call /me, dispatch action
            localStorage.setItem(STORAGE_KEYS.TOKEN, r.token);
            localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, r.refresh_token);
        });
}

export async function doMe(impersonateEmail = null) {
    let url = new URL(me);

    if (impersonateEmail) {
        url.searchParams.append("impersonate", impersonateEmail);
    }

    return apiCall({
        url,
        method: 'GET',
    }, false).then(r => r.json());
}
