import { apiCall, companies, impersonate_list, users } from '../apiCall';

export async function fetchUsersPage(page = 1) {
    let url = new URL(`${users}`);

    url.searchParams.append('per_page', 30);
    url.searchParams.append('page', page);

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function fetchUsersPageForCompany(companyId, page = 1) {
    let url = new URL(`${companies}/${companyId}/read_users`);

    url.searchParams.append('per_page', 30);
    url.searchParams.append('page', page);

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function enableDisableUser(userId, enable) {
    let url = new URL(`${users}/${userId}/${enable ? 'enable' : 'disable'}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: {},
    }).then(r => r.json());
}

export async function editUser(userId, data) {
    let url = new URL(`${users}/${userId}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: data,
    }).then(r => r.json());
}

export async function changePassword(userId, password) {
    let url = new URL(`${users}/${userId}/change_password`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: {
            password,
        },
    }).then(r => r.json());
}

export async function addNewUser(user) {
    let url = new URL(users);

    return apiCall({
        url: url.href,
        method: 'POST',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: user,
    }).then(r => r.json());
}

export async function changeProfilePictureForUser(userId, file) {
    let url = new URL(`${users}/${userId}/change_profile_picture`);

    const formData = new FormData();

    formData.append('file', file);

    return apiCall({
        url: url.href,
        method: 'POST',
        headers: {
            'accept': 'application/ld+json',
        },
        body: formData,
    }).then(r => r.json());
}


export async function fetchUserListForImpersonating() {
    const url = new URL(impersonate_list);

    return apiCall({
        url,
        headers: {
            'accept': 'application/ld+json',
        },
    }, false).then(r => r.json());
}

export async function setAwayMode({ userId, data }) {
    let url = new URL(`${users}/${userId}/set_away`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: data,
    }).then(r => r.json());
}