import { render, staticRenderFns } from "./CompanyUsersHeader.vue?vue&type=template&id=6d380dc5&scoped=true&"
import script from "./CompanyUsersHeader.vue?vue&type=script&lang=js&"
export * from "./CompanyUsersHeader.vue?vue&type=script&lang=js&"
import style0 from "./CompanyUsersHeader.vue?vue&type=style&index=0&id=6d380dc5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d380dc5",
  null
  
)

export default component.exports