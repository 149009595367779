import {
    FETCH_COMPANY_AGENCY_SETTINGS_ACTION,
    FETCH_COMPANY_SETTINGS_ACTION,
    SAVE_AGENCY_SETTINGS_ACTION,
    SAVE_COMPANY_SETTINGS_ACTION,
    SET_COMPANY_AGENCY_SETTINGS,
    SET_COMPANY_AGENCY_SETTINGS_ASYNC,
    SET_COMPANY_SETTINGS,
    SET_COMPANY_SETTINGS_ASYNC,
    SET_COMPANY_SETTINGS_SAVE_ASYNC,
    SET_COMPANY_SETTINGS_SAVE_ERRORS, UPDATE_AGENCY_SETTINGS,
} from '../../actions/settings/company_settings';
import { fetchCompanyAgencySettings, saveCompanySettings, saveAgencySettings } from '@/api/settings/company_settings';
import { fetchCompanyById } from '@/api/companies/companies';

export default {
    state: () => ({
        agencySettings: null,
        agencySettingsAsync: false,

        company: null,
        companyAsync: null,

        saveAsync: false,
        saveErrors: null,
    }),
    mutations: {
        [SET_COMPANY_AGENCY_SETTINGS](state, settings) {
            state.agencySettings = settings;
        },
        [SET_COMPANY_AGENCY_SETTINGS_ASYNC](state, async) {
            state.agencySettingsAsync = async;
        },
        [SET_COMPANY_SETTINGS](state, company) {
            state.company = company;
        },
        [SET_COMPANY_SETTINGS_ASYNC](state, async) {
            state.companyAsync = async;
        },
        [SET_COMPANY_SETTINGS_SAVE_ASYNC](state, async) {
            state.saveAsync = async;
        },
        [SET_COMPANY_SETTINGS_SAVE_ERRORS](state, errors) {
            state.saveErrors = errors;
        },
        [UPDATE_AGENCY_SETTINGS](state, settings) {
            if (state.agencySettings !== null) {
                Object.assign(state.agencySettings, settings);
            }
        },
    },
    actions: {
        async [FETCH_COMPANY_AGENCY_SETTINGS_ACTION]({ commit }, companyId) {
            commit(SET_COMPANY_AGENCY_SETTINGS_ASYNC, true);

            try {
                const settings = await fetchCompanyAgencySettings(companyId);
                commit(SET_COMPANY_AGENCY_SETTINGS, settings);
            } catch (ex) {
                console.error('agency settings', ex);
                commit(SET_COMPANY_SETTINGS_ASYNC, false);
                throw ex;
            }

            commit(SET_COMPANY_AGENCY_SETTINGS_ASYNC, false);
        },
        async [FETCH_COMPANY_SETTINGS_ACTION]({ commit }, companyId) {
            commit(SET_COMPANY_SETTINGS_ASYNC, true);

            try {
                const company = await fetchCompanyById(companyId);
                commit(SET_COMPANY_SETTINGS, company);
            } catch (ex) {
                console.error('company settings', ex);
                commit(SET_COMPANY_SETTINGS_ASYNC, false);
                throw ex;
            }

            commit(SET_COMPANY_SETTINGS_ASYNC, false);
        },
        async [SAVE_COMPANY_SETTINGS_ACTION]({ commit }, { companyId, company, agency_settings }) {
            commit(SET_COMPANY_SETTINGS_SAVE_ASYNC, true);
            commit(SET_COMPANY_SETTINGS_SAVE_ERRORS, null);

            try {
                const res = await saveCompanySettings(companyId, { ...company, agency_settings });
                commit(SET_COMPANY_SETTINGS, res);
                commit(UPDATE_AGENCY_SETTINGS, agency_settings);
            } catch (ex) {
                console.error(ex);
                const r = await ex.json();

                commit(SET_COMPANY_SETTINGS_SAVE_ERRORS, [r['hydra:description']]);
                commit(SET_COMPANY_SETTINGS_SAVE_ASYNC, false);

                throw ex;
            }
            commit(SET_COMPANY_SETTINGS_SAVE_ASYNC, false);
        },
        async [SAVE_AGENCY_SETTINGS_ACTION]({ commit }, { agencySettingsId, updatedValues }) {
            commit(SET_COMPANY_SETTINGS_SAVE_ASYNC, true);
            commit(SET_COMPANY_SETTINGS_SAVE_ERRORS, null);

            try {
                const res = await saveAgencySettings(agencySettingsId, { ...updatedValues });
                commit(SET_COMPANY_SETTINGS, res);
                commit(UPDATE_AGENCY_SETTINGS, updatedValues);
            } catch (ex) {
                console.error(ex);
                const r = await ex.json();

                commit(SET_COMPANY_SETTINGS_SAVE_ERRORS, [r['hydra:description']]);
                commit(SET_COMPANY_SETTINGS_SAVE_ASYNC, false);

                throw ex;
            }
            commit(SET_COMPANY_SETTINGS_SAVE_ASYNC, false);
        },
    },
    getters: {
        hasCompanyAgencySettings(state) {
            return state.agencySettings !== null;
        },
    },
};
