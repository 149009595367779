<template>
    <span>
        <el-button @click="visible = true"
                   type="text"
                   style="margin-left: 8px">
            <i class="el-icon-circle-plus-outline" style="font-size: 16px"/>
        </el-button>

        <el-dialog :title="title"
                   :visible="visible"
                   @close="visible=false">
        <div class="generate-keywords-form">
            <labeled-input type="number" name="Amount" v-model="form.amount"/>
            <labeled-input type="number" name="Keyword length" v-model="form.keywordLength"/>
            <labeled-input name="Prefix" v-model="form.prefix"/>
            <labeled-input name="Suffix" v-model="form.suffix"/>
            <labeled-input name="Exact keywords (delimited by comma)"
                           v-model="form.exactKeywords"
                           style="text-transform: uppercase"
                           placeholder="ABC123, MNN213"/>
        </div>

        <div class="errors-container" style="margin-top: 24px;">
            <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
        </div>

        <span slot="footer">
                <el-button type="text" @click="visible = false">Close</el-button>
                <el-button :loading="async"
                           type="text"
                           @click="onSubmit">
                    Submit
                </el-button>
            </span>
    </el-dialog>
    </span>
</template>

<script>
import LabeledInput from '../../../../components/LabeledInput/LabeledInput';
import { mapActions } from 'vuex';
import { GENERATE_KEYWORDS_ACTION } from '@/store/actions/companies';

function getDefaultData() {
    return {
        amount: 10,
        keywordLength: 6,
        prefix: '',
        suffix: '',
        exactKeywords: ''
    };
}

export default {
    name: 'GenerateKeywordsDialog',
    components: { LabeledInput },
    data() {
        return {
            form: getDefaultData(),
            visible: false,
        };
    },
    props: {
        companyId: String,
    },
    watch: {
        visible(newVisible) {
            if (newVisible === true) {
                this.form = getDefaultData();
            }
        },
    },
    methods: {
        ...mapActions({
            generateKeywords: GENERATE_KEYWORDS_ACTION,
        }),
        onSubmit() {

            const data = { companyId: this.companyId, ...this.form };

            if (data.exactKeywords) {
                data.exactKeywords = data.exactKeywords.toUpperCase().replace(/\s+/g, '').split(',');
            }

            this.generateKeywords(data).then((res) => {
                if (res !== false) {
                    this.form = getDefaultData();
                    this.visible = false;
                }
            });
        },
    },
    computed: {
        title() {
            return "Generate keywords"
        },
        errors() {
            return this.$store.state.companies.addEditErrors;
        },
        async() {
            return this.$store.state.companies.addEditAsync;
        },
    },
};
</script>
