<template>
    <div class="page keywords">
        <div class="keywords-header">

        </div>
        <keywords-table/>
    </div>
</template>
<script>

import KeywordsTable from "@/views/Keywords/KeywordsTable/KeywordsTable";

export default {
    name: 'Keywords',
    components: { KeywordsTable },
};
</script>

<style lang="scss" scoped>
.keywords {
    display: flex;
    flex-direction: column;
    background-color: #12131F;

    .keywords-header {
        display: flex;
        height: 55px;
        align-items: center;
        justify-content: flex-end;
        padding-left: 24px;
        padding-right: 24px;
    }
}
</style>
