let timer = null;
let callbacks = [];

function runCallbacks() {
    callbacks.forEach((callback) => {
        callback();
    });
}

function visibilityChange() {
    if (document.visibilityState === "visible") {
        timer = setTimeout(runCallbacks, 500);
    } else {
        clearTimeout(timer);
    }
}

export function addWindowFocusCallback(cb) {
    if (!callbacks.includes(cb)) {
        callbacks.push(cb);
    }
}

export function removeWindowFocusCallback(cb) {
    let i = callbacks.findIndex(c => c === cb);

    if (i > -1) {
        callbacks.splice(i, 1);
    }
}

export function registerFocusListeners() {
    window.addEventListener('visibilitychange', visibilityChange);
}
