import Vue from 'vue';
import { FETCH_FRONTEND_REVISION_ACTION, SET_FRONTEND_REVISION } from '@/store/actions/app';
import { fetchFrontendRevision } from '@/api/app/app';
import { BUILD_VERSION, IS_DEV } from '@/utils/consts';

export default {
    state: () => ({
        frontendRevision: null,
    }),
    mutations: {
        [SET_FRONTEND_REVISION](state, revision) {
            state.frontendRevision = revision;
        },
    },
    actions: {
        async [FETCH_FRONTEND_REVISION_ACTION]({ commit }) {
            try {
                const r = await fetchFrontendRevision();
                const plainTextVersion = r.trim();

                if (!IS_DEV) {
                    if (BUILD_VERSION !== plainTextVersion) {
                        Vue.$toast.open({
                            message: 'New Version available. Click here to refresh',
                            type: 'success',
                            position: 'top',
                            onClick: () => {
                                window.location.reload(true);
                            },
                        });
                    }
                }

                commit(SET_FRONTEND_REVISION, plainTextVersion);
            } catch (ex) {
                console.error('Error fetching frontend revision', ex);
            }
        },
    },
    getters: {},
};
