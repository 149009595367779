<template>
    <div class="templates-tab">
        <div class="user-templates">
            <el-button @click="isAddOpen = true" class="add-template-btn">
                Add Template
            </el-button>
            <div class="add-template-form-container" :class="{open: isAddOpen}">
                <labeled-text-area v-model="newTemplateText"
                                   placeholder="Type template here"/>

                <div class="buttons-container">
                    <el-button @click="isAddOpen = false" type="text">
                        CANCEL
                    </el-button>
                    <el-button :loading="addAsync"
                               @click="onAddTemplate"
                               type="text">
                        ADD
                    </el-button>
                </div>
            </div>
            <div class="user-templates-list">
                <settings-template v-for="template in userTemplates"
                                   :delete-template="deleteTemplate"
                                   :edit-template="editTemplate"
                                   :key="template.id"
                                   :template="template"/>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ADD_NEW_TEMPLATE_ACTION, DELETE_USER_TEMPLATE_ACTION,
    EDIT_USER_TEMPLATE_ACTION,
    FETCH_USER_TEMPLATES_ACTION,
} from '@/store/actions/templates';
import { mapActions, mapGetters } from 'vuex';
import SettingsTemplate from '@/views/UserSettings/tabs/TemplatesTab/SettingsTemplate/SettingsTemplate';
import LabeledTextArea from '@/components/LabeledTextArea/LabeledTextArea';

export default {
    name: 'TemplatesTab',
    components: { LabeledTextArea, SettingsTemplate },
    methods: {
        ...mapActions({
            fetchUserTemplates: FETCH_USER_TEMPLATES_ACTION,
            addNewTemplate: ADD_NEW_TEMPLATE_ACTION,

            editTemplate: EDIT_USER_TEMPLATE_ACTION,
            deleteTemplate: DELETE_USER_TEMPLATE_ACTION,
        }),
        onAddTemplate() {
            if (this.newTemplateText.length !== 0) {
                this.addNewTemplate(this.newTemplateText).then(() => {
                    this.isAddOpen = false;
                    this.newTemplateText = '';
                });
            }
        },
        onImpersonateChange() {
            console.log('impersonatechange');
            this.fetchUserTemplates();
        },
    },
    data() {
        return {
            isAddOpen: false,
            newTemplateText: '',
        };
    },
    watch: {
        isAddOpen: function () {
            if (this.isAddOpen) {
                this.newTemplateText = '';
            }
        },
    },
    computed: {
        ...mapGetters({
            userTemplates: 'userTemplates',
            hasTemplates: 'hasUserTemplates',
        }),
        addAsync() {
            return this.$store.state.templates.addAsync;
        },
    },
    mounted() {
        if (!this.hasTemplates) {
            this.fetchUserTemplates();
        }
    },
};
</script>

<style lang="scss">
.user-templates {
    .add-template-form-container {
        .input-container {
            margin-top: 0px;

            textarea {
                margin-top: 0px;
                resize: none;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.templates-tab {
    display: flex;
    flex-direction: column;
    flex: 1;

    .user-templates {
        padding: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        overflow: hidden;

        .add-template-btn {
            align-self: flex-start;
            background-color: transparent;
            border: 1px solid #13A68A;
            margin-bottom: 24px;
        }

        .user-templates-list {
            overflow: auto;
            margin-top: 24px;
        }

        .add-template-form-container {
            max-height: 0px;
            overflow: hidden;
            transition: all 300ms ease-in-out;

            &.open {
                flex: 0 0 auto;
                max-height: 160px;
            }

            .buttons-container {
                text-align: right;
            }
        }
    }
}
</style>
