<template>
    <span>
        <el-button @click="dialogVisible = true" type="text">
            <i class="icon icon-actions_edit"/>
        </el-button>
        <add-edit-user-dialog :user="user" :visible.sync="dialogVisible"/>
    </span>
</template>

<script>
import AddEditUserDialog from '../AddEditUserDialog/AddEditUserDialog';

export default {
    name: 'EditUserDialogWrapper',
    components: { AddEditUserDialog },
    data() {
        return {
            dialogVisible: false,
        };
    },
    props: {
        user: Object,
    },
};
</script>
