<template>
    <div @click="$emit('click')" class="rounded-initials"
         :class="{'multi-select-view': multiSelectView, 'opt-in': isOptedIn, 'opt-out' :isOptOut, 'opt-unknown': isOptUnknown}">
        <div class="check" v-if="hasCheck"/>

        <template v-if="!multiSelectView">
            <span v-if="Boolean(customer.first_name)">
                {{ customer.first_name.charAt(0) }}
            </span>

            <span v-if="Boolean(customer.last_name)">
                {{ customer.last_name.charAt(0) }}
            </span>

            <span class="na-text" v-if="!Boolean(customer.first_name) && !Boolean(customer.last_name)">
                NN
            </span>
        </template>
        <template v-else>
            <i class="el-icon-check" />
        </template>
    </div>
</template>

<script>
export default {
    name: 'RoundedInitials',
    props: {
        customer: Object,
        hasCheck: Boolean,
        multiSelectView: {
            type: Boolean,
            default: false,
        },
        opt: {
            type: String,
            default: 'UNKNOWN',
        },
    },

    computed: {
        isOptedIn() {
            return this.opt === 'IN';
        },
        isOptUnknown() {
            return this.opt === 'UNKNOWN';
        },
        isOptOut() {
            return this.opt === 'OUT';
        },
    },
};
</script>

<style scoped lang="scss">
.rounded-initials {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 14px;

    .check {
        position: absolute;
        left: 1px;
        top: 1px;
        height: 12px;
        width: 12px;
        background-color: #FA5D5D;
        border-radius: 100px;
    }

    .checkmark {
        font-size: 12px;
    }

    &.opt-in {
        background-color: #13A68A;
        color: #fff;
    }

    &.opt-unknown {
        background-color: #404B66;
    }

    &.opt-out {
        background-color: indianred;
    }

    &.multi-select-view {
        background: #4072EE;
    }

    .na-text {
        opacity: 0.5;
    }
}
</style>
