import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import 'cleave.js/dist/addons/cleave-phone.us';

import Cleave from 'vue-cleave-component';

import ElementUI from 'element-ui';
import './assets/scss/table.scss';
import './assets/scss/misc.scss';
import './assets/scss/page.scss';
import './assets/scss/animations.scss';
import 'vue-loaders/dist/vue-loaders.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import { sync } from 'vuex-router-sync';
import enLocale from 'element-ui/lib/locale/lang/en';
import moment from 'vue-moment';
import VueSweetalert2 from 'vue-sweetalert2';
import VueLoaders from 'vue-loaders';
import VueToast from 'vue-toast-notification';
import VTooltip from 'v-tooltip';

import './assets/scss/tooltip.scss';

import 'vue-toast-notification/dist/theme-default.css';

import './assets/fonts/icomoon/style.css';

import './assets/scss/theme-light.scss';
import './utils/filters';
import VueSocketIO from 'vue-socket.io';
import { IS_DEV } from './utils/consts';
import { ws_endpoint } from './api/apiCall';
import * as Sentry from '@sentry/vue';
import { CaptureConsole } from '@sentry/integrations';

import linkify from 'vue-linkify';

import { registerWakeHandler } from '@/utils/wakeEvent';
import { registerFocusListeners } from '@/utils/registerWindowFocusLossHandler';
import { WindowFocusPlugin } from '@/plugins/windowFocusPlugin';
import { unregisterServiceWorkers } from '@/utils/unregisterServiceWorkers';
import './assets/scss/overrides.scss';
import TextareaAutosize from 'vue-textarea-autosize';
import { Plugin as VueFragmentPlugin } from 'vue-fragment';
import { ImpersonationChangedPlugin } from '@/plugins/impersonationChangedPlugin';

if (!IS_DEV) {
    Sentry.init({
        Vue: Vue,
        dsn: 'https://2a6c7733be4a4807b961272ca3aeba69@o229960.ingest.sentry.io/5497621',
        integrations: [
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
    });
}

sync(store, router);

Vue.use(ElementUI, { locale: enLocale });
Vue.use(VueLoaders);
Vue.use(moment);
Vue.use(VueSweetalert2);
Vue.use(VueToast);
Vue.use(VTooltip);
Vue.use(Viewer);
Vue.use(Cleave);
Vue.use(WindowFocusPlugin);
Vue.use(ImpersonationChangedPlugin);
Vue.use(TextareaAutosize);
Vue.use(VueFragmentPlugin);

Vue.directive('linkified', linkify);

Vue.use(new VueSocketIO({
    debug: IS_DEV,
    connection: ws_endpoint,
    vuex: {
        store: store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_',
    },
    options: {
        autoConnect: false,
    },
}));

registerWakeHandler();
registerFocusListeners();

unregisterServiceWorkers();

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
