<template>
    <div>
        <el-button @click="dialogVisible = true" type="text">
            ADD NEW USER
        </el-button>

        <add-edit-user-dialog :visible.sync="dialogVisible"/>
    </div>
</template>

<script>
import AddEditUserDialog from '../AddEditUserDialog/AddEditUserDialog';
export default {
    name: 'AddUserDialogWrapper',
    components: { AddEditUserDialog },
    data() {
        return {
            dialogVisible: false,
        };
    },
};
</script>
