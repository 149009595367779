<template>
    <div class="all-properties-table">
        <div v-if="!async" class="table-container">
            <custom-table :data="properties"
                          @row-click="selectProperty($event)"
                          :selectedId="selectedProperty !== null ? selectedProperty.id : ''"
                          class="properties-table"
                          :fields="fields">
                <template v-slot:status="props">
                    <div class="status-container">
                        <property-status-dot :status="getActivePropertyStatus(props.item.status)"/>
                    </div>
                </template>
                <template v-slot:note="props">
                    <div class="note-container">
                        <div class="note">{{ props.item.note }}</div>
                    </div>
                </template>
                <template v-slot:createdat="props">
                    <span>{{ props.item.created_at | moment('MM-DD-YYYY') }}</span>
                </template>
                <template v-slot:agent="props">
                    <span>{{ props.item.agent | mergeName }}</span>
                </template>
                <template v-slot:actions="props">
                    <div style="display: flex; align-items: start">
                        <qr-generator-dialog :property="props.item"/>
                        <el-button v-tooltip="'Generate Landing URL'"
                                   class="landing-url-button"
                                   type="text"
                                   @click.stop="generateLandingPageUrl(props.item)">
                            <i class="icon el-icon-link"/>
                        </el-button>
                        <edit-property-dialog :property="props.item"/>
                        <archive-property-dialog :property="props.item"/>
                    </div>
                </template>
            </custom-table>
        </div>
        <div v-else class="async-container">
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>
import {
    FETCH_ALL_PROPERTIES_ACTION,
    SELECT_PROPERTY_ACTION,
    SET_PROPERTY_SIDEBAR_OPEN,
} from '@/store/actions/properties';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CustomTable from '@/components/CustomTable/CustomTable';
import EditPropertyDialog from '@/views/AddNewProperty/components/EditPropertyDialog/EditPropertyDialog';
import { FETCH_ALL_KEYWORDS_ACTION } from '@/store/actions/keywords';
import ArchivePropertyDialog from '@/views/AddNewProperty/components/ArchivePropertyDialog/ArchivePropertyDialog';
import PropertyStatusDot from '@/components/PropertyStatusDot/PropertyStatusDot';
import { dateTimeSort, stringSort } from '@/utils/sorts';
import { getActivePropertyStatus } from "@/utils/utils";
import Vue from "vue";
import QrGeneratorDialog from "@/views/AddNewProperty/components/QRGeneratorDialog/QrGeneratorDialog";

function getFields(props) {
    const { isCompanyAdmin, isImpersonationActive } = props;

    const showAgent = isCompanyAdmin && !isImpersonationActive;

    return [{
        name: 'created_at',
        title: 'Date Created',
        slot: 'createdat',
        width: '12%',
        sort: (a, b, direction) => dateTimeSort(a.created_at, b.created_at, direction),
    }, {
        name: 'status',
        title: 'Status',
        slot: 'status',
        width: '12%',
    }, {
        name: 'name',
        title: 'Address',
    }, {
        name: 'keyword',
        title: 'Keyword',
        accessor: 'keyword.name',
        width: '20%',
        sort: (a, b, direction) => stringSort(a.keyword, b.keyword, direction),
    }, {
        name: 'agent',
        title: 'Agent',
        slot: 'agent',
        width: '10%',
        disabled: !showAgent,
    }, {
        name: 'note',
        title: 'Property Notes',
        slot: 'note',
    }, {
        name: 'actions',
        title: 'Edit Actions',
        slot: 'actions',
        width: '12%',
        rowClass: 'actions',
        headerClass: 'actions',
    }];
}

export default {
    name: 'AllPropertiesTable',
    components: { QrGeneratorDialog, PropertyStatusDot, ArchivePropertyDialog, EditPropertyDialog, CustomTable },
    data() {
        return {
            fields: getFields({
                isCompanyAdmin: this.isCompanyAdmin,
                isImpersonationActive: this.isImpersonationActive
            }),
        };
    },
    computed: {
        ...mapGetters([
            'hasProperties',
            'selectedProperty',
            'isSuperAdmin',
            'isImpersonationActive',
            'isCompanyAdmin',
            'impersonatedUser',
            'properties',
        ]),
        async() {
            return this.$store.state.properties.async;
        },
        propertySidebarOpen() {
            return this.$store.state.properties.propertySidebarOpen;
        },
    },
    watch: {
        impersonatedUser: function (oldImpersonate, newImpersonate) {
            if (oldImpersonate?.id !== newImpersonate?.id) {
                this.init();
            }
        },
        isCompanyAdmin: function (isCompanyAdmin) {
            this.fields = getFields({
                isCompanyAdmin: isCompanyAdmin,
                isImpersonationActive: this.isImpersonationActive
            });
        },
    },
    mounted() {
        this.fields = getFields({
            isCompanyAdmin: this.isCompanyAdmin,
            isImpersonationActive: this.isImpersonationActive
        });

        this.init();
    },
    methods: {
        ...mapMutations({
            setPropertySidebarOpen: SET_PROPERTY_SIDEBAR_OPEN,
        }),
        ...mapActions({
            fetchAllProperties: FETCH_ALL_PROPERTIES_ACTION,
            selectPropertyAction: SELECT_PROPERTY_ACTION,
            fetchAllKeywords: FETCH_ALL_KEYWORDS_ACTION,
        }),
        init() {
            this.fetchAllProperties();
            this.fetchAllKeywords();
        },
        selectProperty(property) {
            this.selectPropertyAction({ propertyId: property.id, controlSidebar: true });
        },
        getActivePropertyStatus,
        generateQRCode(property) {
            console.log(property);
        },
        generateLandingPageUrl(property) {

            const landingUrl = `${window.location.origin}/landing/property/${property?.short_id}`

            navigator.clipboard.writeText(landingUrl);

            Vue.$toast.open({
                message: 'URL copied to clipboard.',
                type: 'success',
                duration: 2000,
                position: 'top-right',
            });
        }
    },
};
</script>

<style lang="scss">
.theme-dark {
}

.all-properties-table {

    .table-container {
        .properties-table {
            .table-header-item-actions {
                justify-content: flex-end;
                display: flex;
            }
        }
    }

    .status-container {
        .property-status-dot {
            .dot {
                font-size: 30px;
            }

            .status {
                margin-left: 5px;
                color: #b5b7ba;
            }
        }
    }

    .table-row {
        &.selected {
            .table-row-item {
                .delete-property-btn {
                    .icon {
                        &:not(:hover) {
                            color: #05c696;
                        }
                    }
                }
            }
        }
    }

}
</style>

<style lang="scss" scoped>
.all-properties-table {
    background-color: #1E2234;
    padding: 24px;
    padding-top: 0px;
    display: flex;
    flex: 1;
    overflow: hidden;

    .table-container {
        display: flex;
        flex: 1;
    }

    .icon {
        font-size: 20px;
    }

    .async-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    .el-button {
        color: #748AA1;
        font-size: 16px;
    }

    .note-container {
        padding-top: 8px;
        padding-bottom: 8px;

        .note {
            line-clamp: 2;
            white-space: normal;
            color: #ffffff;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            /* Needed to make it work */
            overflow: hidden;
        }
    }

    .status-container {
        display: flex;
        align-items: center;
    }

    .landing-url-button {
        margin: 0 16px;
    }
}
</style>
