<template>
    <div class="menu" v-bind:class="{collapsed: isCollapsed}">
        <menu-item-group v-for="group in groups"
                         :key="group.name"
                         :icon="group.icon"
                         :name="group.name"
                         :menu-collapsed="isCollapsed"
                         :items="group.items"/>

        <div class="version-field" v-if="!isCollapsed">
            <span>{{ versionText }}</span>
        </div>
    </div>
</template>

<script>
import MenuItemGroup from './components/MenuItemGroup/MenuItemGroup';
import moment from 'moment';
import { BUILD_VERSION, RELEASE_TIMESTAMP } from '@/utils/consts';
import { mapGetters } from 'vuex';
import { isRouteValidForUser } from '@/utils/utils';

export default {
    name: 'Menu',
    components: { MenuItemGroup },
    methods: {
        onImpersonateChange() {
            this.checkRouteValidForUser();
        },
        checkRouteValidForUser() {
            const activeItem = this.getActiveRouteItem(this.activeItemPath);
            if (!isRouteValidForUser(this.checkUser, activeItem)) {
                this.$router.push("/");
            }
        }
    },
    mounted() {
        this.checkRouteValidForUser();
    },
    computed: {
        ...mapGetters([
            'isImpersonationActive',
            'getActiveRouteItem',
            'impersonatedUser',
            'me'
        ]),
        checkUser() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.me;
        },
        activeItemPath() {
            return this.$store.state.route.path;
        },
        groups() {
            return this.$store.state.menu.groups;
        },
        isCollapsed() {
            return this.$store.state.menu.isCollapsed;
        },
        versionText() {
            return `Version ${BUILD_VERSION}@${moment(RELEASE_TIMESTAMP).format('DD/MM/YY')}`;
        },
    },
};
</script>

<style lang="scss">
div.menu {
    .icon {
        font-size: 20px;
    }
}
</style>

<style scoped lang="scss">

.theme-light {
    div.menu {
        background-color: #ffffff;
    }
}

div.menu {
    box-sizing: border-box;
    height: 100%;
    width: 256px;
    background-color: #181B29;
    transition: all 300ms ease-in-out;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    .icon {
        font-size: 17px;
    }

    &.collapsed {
        width: 56px;
    }

    .version-field {
        margin-top: auto;
        margin-bottom: 18px;
        font-size: 14px;
        color: #5f636f;
        text-align: left;
        padding-left: 21px;
        word-break: break-all;
        white-space: nowrap;
    }
}

</style>
