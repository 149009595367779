export const STORAGE_KEYS = {
    TOKEN: 'retext_token',
    REFRESH_TOKEN: 'retext_refresh_token',
    IMPERSONATE: 'impersonate',
};

export const IS_DEV = process.env.NODE_ENV === 'development';

export const BUILD_VERSION = process.env.VUE_APP_BUILD_VERSION;

export const RELEASE_TIMESTAMP = process.env.VUE_APP_RELEASE_TIMESTAMP;

export const ATTACHMENT_POPUP_MODE = {
    PROPERTY: 'property',
    TEMPLATE: 'template',
};

export const ROLES = ['ROLE_USER', 'ROLE_COMPANY_ADMIN'];

export const PROPERTY_STATUS_OPTIONS = {
    FOR_SALE: 'FOR_SALE',
    FOR_LEASE: 'FOR_LEASE',
    SALE_PENDING: 'SALE_PENDING',
    SOLD: 'SOLD',
    OFF_MARKET: 'OFF_MARKET'
};

export const PROPERTY_STATUS_TEXT = {
    FOR_SALE: 'For Sale',
    SOLD: 'Sold',
    OFF_MARKET: 'Off Market',
    SALE_PENDING: 'Sale Pending',
    FOR_LEASE: "For Lease"
};

export const PROPERTY_STATUS_COLOR_CLASS = {
    [PROPERTY_STATUS_OPTIONS.SOLD]: 'bluegray',
    [PROPERTY_STATUS_OPTIONS.FOR_SALE]: 'green',
    [PROPERTY_STATUS_OPTIONS.SALE_PENDING]: 'orange',
    [PROPERTY_STATUS_OPTIONS.OFF_MARKET]: 'red',
};

export const LEAD_OPT_STATUS = {
    IN: "IN",
    OUT: "OUT",
    UNKNOWN: "UNKNOWN"
}