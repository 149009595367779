<template>
    <div class="property-status-dot">
        <span :class="{dot: true, [statusColor]: true}">&bull;</span>
        <div class="status">{{ statusText }}</div>
    </div>
</template>

<script>
import { PROPERTY_STATUS_COLOR_CLASS, PROPERTY_STATUS_TEXT } from '@/utils/consts';

export default {
    name: 'PropertyStatusDot',
    props: {
        status: String
    },
    computed: {
        statusText() {
            return PROPERTY_STATUS_TEXT[this.status];
        },
        statusColor() {
            return PROPERTY_STATUS_COLOR_CLASS[this.status];
        }
    }
};
</script>

<style scoped lang="scss">
.property-status-dot {
    top: -18px;
    right: 20px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #1E2234;
    color: #13A68A;
    display: flex;
    align-items: center;
    font-size: 12px;

    .dot {
        &.bluegray {
            color: #748AA1;
        }

        &.green {
            color: #13A68A;
        }

        &.orange {
            color: #FEC400;
        }

        &.red {
            color: #FA5D5D;
        }
    }
}
</style>
