export const FETCH_ALL_LEADS_ACTION = 'FETCH_ALL_LEADS_ACTION';
export const FETCH_LEADS_PAGE_ACTION = 'FETCH_LEADS_PAGE_ACTION';
export const SET_LEADS_ASYNC = 'SET_LEADS_ASYNC';
export const SET_LEADS = 'SET_LEADS';
export const SET_LEADS_FILTERS = 'SET_LEADS_FILTERS';
export const APPEND_LEADS_PAGE = 'APPEND_LEADS_PAGE';
export const APPEND_SEARCH_LEADS_PAGE = 'APPEND_SEARCH_LEADS_PAGE';
export const SELECT_LEAD = 'SELECT_LEAD';
export const MULTI_SELECT_LEAD = 'MULTI_SELECT_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';

export const UPDATE_LEAD_ACTION = 'UPDATE_LEAD_ACTION';
export const SELECT_LEAD_ACTION = 'SELECT_LEAD_ACTION';
export const SET_SELECTED_LEAD_INTERESTS = 'SET_SELECTED_LEAD_INTERESTS';
export const ADD_SELECTED_LEAD_INTEREST = 'ADD_SELECTED_LEAD_INTEREST';
export const SET_SELECTED_LEAD_INTERESTS_ASYNC = 'SET_SELECTED_LEAD_INTERESTS_ASYNC';
export const SET_SELECTED_LEAD_INTERESTS_ERRORS = 'SET_SELECTED_LEAD_INTERESTS_ERRORS';
export const SET_LEADS_MULTI_SELECT = 'SET_LEADS_MULTI_SELECT';
export const SET_MULTISELECT_ALL = 'SET_MULTISELECT_ALL';
export const ADD_NEW_LEAD = 'ADD_NEW_LEAD';
export const ARCHIVE_LEAD_ACTION = 'ARCHIVE_LEAD_ACTION';
export const REMOVE_LEAD = 'REMOVE_LEAD';
export const REMOVE_LEAD_FROM_ARCHIVED = 'REMOVE_LEAD_FROM_ARCHIVED';

export const UNARCHIVE_LEAD_ACTION = 'UNARCHIVE_LEAD_ACTION';

export const EDIT_LEAD_ACTION = 'EDIT_LEAD_ACTION';
export const SET_EDIT_LEAD_ASYNC = 'SET_EDIT_LEAD_ASYNC';
export const SET_EDIT_LEAD_ERRORS = 'SET_EDIT_LEAD_ERRORS';
export const MARK_LEAD_AS_IMPORTANT_ACTION = 'MARK_LEAD_AS_IMPORTANT_ACTION';
export const MARK_LEAD_AS_UNREAD = "MARK_LEAD_AS_UNREAD";
export const OPT_OUT_LEAD_ACTION = 'OPT_OUT_LEAD_ACTION';

export const FETCH_ALL_ARCHIVED_LEADS_ACTION = 'FETCH_ALL_ARCHIVED_LEADS_ACTION';
export const FETCH_ARCHIVED_LEADS_PAGE_ACTION = 'FETCH_ARCHIVED_LEADS_PAGE_ACTION';

export const SET_ARCHIVED_LEADS_ASYNC = 'SET_ARCHIVED_LEADS_ASYNC';
export const SET_ARCHIVED_LEADS = 'SET_ARCHIVED_LEADS';

export const APPEND_ARCHIVED_LEADS_PAGE = 'APPEND_ARCHIVED_LEADS_PAGE';

export const SET_INTEREST_COLLAPSED_ACTION = 'SET_INTEREST_COLLAPSED_ACTION';
export const SET_INTEREST_ARCHIVED_ACTION = 'SET_INTEREST_ARCHIVED_ACTION';

export const UPDATE_LEAD_INTEREST = 'UPDATE_LEAD_INTEREST';

export const FETCH_SINGLE_SELECTED_PROPERTY_INTEREST_ACTION = 'FETCH_SINGLE_SELECTED_PROPERTY_INTEREST_ACTION';

export const SEARCH_LEADS_ACTION = 'SEARCH_LEADS_ACTION';

export const SET_LEADS_SEARCH_TEXT = 'SET_LEADS_SEARCH_TEXT';
export const SET_SEARCH_LEADS = 'SET_SEARCH_LEADS';

export const ADD_PROPERTY_INTEREST_ACTION = 'ADD_PROPERTY_INTEREST_ACTION';

export const UPDATE_LEADS_RIGHT_SIDE_NUMBER = 'UPDATE_LEADS_RIGHT_SIDE_NUMBER';
export const ADD_MANUAL_LEAD_ACTION = 'ADD_MANUAL_LEAD_ACTION';
export const SET_ADD_LEAD_ASYNC = 'SET_ADD_LEAD_ASYNC';
export const SET_LEAD_ADD_ERRORS = 'SET_LEAD_ADD_ERRORS';
