<template>
    <div class="input-container">
        <label>
            <span v-if="showLabel" :class="{required: required}">{{ name }}</span>
            <cleave :name="name"
                    :raw="raw"
                    v-model="inputVal"
                    :type="type"
                    :inputmode="inputmode"
                    :options="options"
                    :autofill="autofill"
                    :placeholder="placeholder || name"/>
        </label>
    </div>
</template>

<script>
import Cleave from 'vue-cleave-component';

export default {
    components: { Cleave },
    name: 'LabeledCleave',
    props: {
        name: String,
        showLabel: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        raw: {
            type: Boolean,
            default: true,
        },
        inputmode: {
            type: String,
            default: 'text'
        },
        placeholder: String,
        value: String,
        autofill: String,
        options: Object,
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style scoped lang="scss">
.theme-light {
    .input-container {
        input {
            background-color: #ffffff !important;
            color: #748AA1 !important;
            border: 2px solid #e4e3e8;

        }
    }
}

.input-container {
    padding-top: 10px;
    padding-bottom: 10px;

    label {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        letter-spacing: 1px;
        color: #748aa1;
        text-transform: uppercase;

        span {
            &.required:after {
                content: " *";
                color: indianred;
            }
        }

        input {
            border-radius: 4px;
            margin-top: 6px;
            background-color: transparent !important;
            font-size: 14px;
            line-height: 20px;
            border: 2px solid #3d414b;
            color: #E5E9F2;
            padding: 12px 14px;
            outline: unset;
            transition: all 300ms ease-in-out;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border: 2px solid #3d414b;
                -webkit-text-fill-color: #E5E9F2;
                -webkit-box-shadow: 0 0 0px 1000px #282E36 inset;
                transition: background-color 5000s ease-in-out 0s;
            }


            &::placeholder {
                color: #748AA1;
            }

            &:focus,
            &:active,
            &:hover {
            }
        }
    }
}
</style>
