export let ImpersonationChangedPlugin = {
    install: function (Vue, _options) {
        Vue.mixin({
            computed: {
                impersonatedUser() {
                    if (this.$store) {
                        return this.$store.getters.impersonatedUser;
                    } else {
                        return null;
                    }
                },
            },
            watch: {
                impersonatedUser(newUser, oldUser) {
                    if (newUser?.id !== oldUser?.id) {
                        if (typeof this.onImpersonateChange === 'function') {
                            this.onImpersonateChange(newUser);
                        }
                    }
                },
            },
        });
    },
};
