<template>
    <div class="profile-sidebar">
        <div class="profile-name-icon-container">
            <div class="company-details">
                <span class="company-name" v-if="user.company != null">
                    {{ user.company.name }}
                </span>
                <span class="status">
                    <span v-if="isSuperAdmin">(Super Admin)</span>
                    <span v-else-if="isCompanyAdmin">(Company Admin)</span>
                    <span v-else></span>
                </span>
                <span class="company-phone" style="margin-right: 6px" v-if="user.company != null">
                    | <span style="user-select: text; cursor: text;">{{ user.company.phone | phone }}</span> |&nbsp;
                </span>
            </div>

            <impersonation/>

<!--            <notifications />-->

            <div class="dropdown-handle">
                <img v-if="profilePictureUrl"
                     @click="toggleProfileDropdown()"
                     class="profile-picture"
                     :src="profilePictureUrl"/>

                <rounded-initials opt="IN"
                                  @click="toggleProfileDropdown()"
                                  class="profile-initials"
                                  :has-check="false"
                                  :customer="user"
                                  v-else/>

                <span @click="toggleProfileDropdown()" :class="{'away-status': true, [awayStatusColor]: true}"/>

                <profile-dropdown />
            </div>
        </div>
    </div>
</template>

<script>
import { storage_url } from '@/api/apiCall';
import RoundedInitials from '@/components/RoundedInitials/RoundedInitials';
import Impersonation from './Impersonation/Impersonation';
import { mapGetters, mapMutations } from 'vuex';
import { TOGGLE_PROFILE_DROPDOWN } from '@/store/actions/ui';
// import Notifications from '@/components/Header/components/ProfileSidebar/Notifications/Notifications';
import ProfileDropdown from '@/components/Header/components/ProfileSidebar/ProfileDropdown/ProfileDropdown';

export default {
    name: 'ProfileSidebar',
    components: { ProfileDropdown, /*Notifications,*/ RoundedInitials, Impersonation },
    methods: {
        ...mapMutations({
            toggleProfileDropdown: TOGGLE_PROFILE_DROPDOWN,
        }),
    },
    computed: {
        ...mapGetters([
            'isCompanyAdmin',
            'isSuperAdmin',
            'me',
            'isImpersonationActive',
            'impersonatedUser',
        ]),
        user() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.me;
        },
        profilePictureUrl() {
            if (this.user.profile_picture) {
                return `${storage_url}${this.user.profile_picture.content_url}`;
            }

            return null;
        },
        awayStatusColor() {
            return this.user?.settings?.away_mode?.is_away === true ? 'away' : 'active';
        },
    },
};
</script>

<style lang="scss" scoped>
.theme-light {
    .profile-sidebar {
        .profile-name-icon-container {
            color: #545F69;

            .profile-picture {
                border: none;
            }
        }

        .sidebar {
            background-color: #ffffff;
        }
    }
}

.profile-sidebar {
    justify-self: flex-end;

    .dropdown-handle,
    .profile-name-icon-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #ffffff;
        user-select: none;
        position: relative;

        .company-details {
            color: #13A68A;

            .status {
                color: #FFFFFF;
            }
        }

        .company-details,
        .name {
            margin-right: 16px;
            font-size: 14px;
            transition: all 300ms ease-in-out;
            font-weight: 500;

            &.active {
                color: #13A68A;
            }
        }

        .profile-initials {
        }

        .profile-picture {
            width: 38px;
            height: 38px;
            border-radius: 50px;
            border: 1px solid #2a2e36;
            object-fit: cover;
        }

        .away-status {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            right: 2px;
            bottom: -2px;

            &.active {
                background-color: green;
            }

            &.away {
                background-color: #FA5D5D;
            }

        }
    }

    .sidebar {
        transition: all 300ms ease-in-out;
        // full - header height
        height: calc(100% - 58px);
        width: 0px;
        background-color: #12131F;
        position: absolute;
        right: 0;
        top: 58px;
        font-size: 15px;
        z-index: 500;
        user-select: none;
        user-focus: none;

        &.open {
            width: 370px;
            user-focus: initial;
            user-select: initial;
        }

        .sidebar-content {
            width: 370px;

            .light-mode-checkbox {
                padding: 12px;
                display: flex;
                align-items: center;
                color: #F3F4F7;

                span {
                    margin-left: 16px;
                }
            }
        }
    }
}
</style>
