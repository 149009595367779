<template>
    <div class="page settings">
        <el-tabs v-model="activeTab">
            <el-tab-pane name="company-settings" label="Company">
                <company-settings/>
            </el-tab-pane>
            <el-tab-pane name="messages-settings" label="Messages">
                <messages-settings/>
            </el-tab-pane>
            <el-tab-pane name="templates-settings" label="Templates">
                <company-templates/>
            </el-tab-pane>

        </el-tabs>
    </div>
</template>

<script>
import CompanySettings from './tabs/CompanySettings/CompanySettings';
import MessagesSettings from '@/views/Settings/tabs/MessagesSettings/MessagesSettings';
import CompanyTemplates from '@/views/Settings/tabs/CompanyTemplates/CompanyTemplates';

export default {
    name: 'Settings',
    components: { CompanyTemplates, MessagesSettings, CompanySettings },
    data() {
        return {
            activeTab: 'company-settings',
        };
    },
};
</script>

<style lang="scss">
.settings {
    .el-tabs {
        width: 100%;

        .el-tabs__content {
            flex: 1;
            flex-direction: column;
        }

        .el-tabs__nav-wrap:after {
            background-color: #3E444E;
        }
    }
}
</style>

<style lang="scss" scoped>
.settings {
    background-color: #1E2234;
    padding-top: 56px;
    width: 100%;
}
</style>
