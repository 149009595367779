<template>
    <div class="page user-settings">
        <el-tabs v-model="activeTab">
            <el-tab-pane label="Templates" name="templates">
                <templates-tab/>
            </el-tab-pane>
            <el-tab-pane label="Notifications" name="notifications">
                <notifications-tab/>
            </el-tab-pane>
            <el-tab-pane label="Account" name="account">
                <user-tab/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import TemplatesTab from '@/views/UserSettings/tabs/TemplatesTab/TemplatesTab';
import NotificationsTab from '@/views/UserSettings/tabs/NotificationsTab/NotificationsTab';
import UserTab from '@/views/UserSettings/tabs/UserTab/UserTab';

export default {
    name: 'UserSettings',
    components: { UserTab, NotificationsTab, TemplatesTab },
    data() {
        return {
            activeTab: "templates"
        }
    }
};
</script>


<style lang="scss">
.user-settings {
    .el-tabs {
        width: 100%;

        .el-tabs__content {
            flex: 1;
            flex-direction: column;
        }

        .el-tabs__nav-wrap:after {
            background-color: #3E444E;
        }
    }
}
</style>

<style lang="scss" scoped>
.user-settings {
    background-color: #1E2234;
    padding-top: 56px;
    width: 100%;
}
</style>
