export const SET_LIGHT_MODE = 'SET_LIGHT_MODE';

export default {
    state: () => ({
        isLightMode: localStorage.getItem('retext-light-mode') === 'true' || false,
        user: {
            first_name: 'Test',
            last_name: 'User',
            profile_picture: 'https://cdn3.f-cdn.com/contestentries/1376995/30494909/5b566bc71d308_thumb900.jpg',
        },
    }),
    mutations: {
        [SET_LIGHT_MODE](state, value) {
            state.isLightMode = value;
            localStorage.setItem('retext-light-mode', value);
        },
    },
    actions: {},
    getters: {},
};
