<template>
    <div class="company-users">
        <div v-if="!usersAsync && selectedCompanyId !== null">
            <company-users-header v-if="isSuperAdmin"/>
            <company-users-table/>
        </div>
        <div class="async-container" v-if="usersAsync">
            <vue-loaders-line-scale/>
        </div>
        <div class="select-company-text" v-if="!usersAsync && selectedCompanyId === null">
            <span>Please select a company</span>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { FETCH_ALL_USERS_FOR_COMPANY, RESET_USERS_STATE } from '@/store/actions/users';
import CompanyUsersTable from '../CompanyUsersTable/CompanyUsersTable';
import CompanyUsersHeader from '../CompanyUsersHeader/CompanyUsersHeader';

export default {
    name: 'CompanyUsers',
    components: { CompanyUsersHeader, CompanyUsersTable },
    methods: {
        ...mapMutations({
            resetUsersState: RESET_USERS_STATE,
        }),
        ...mapActions({
            fetchAllUsersForCompany: FETCH_ALL_USERS_FOR_COMPANY,
        }),
    },
    watch: {
        selectedCompanyId(newCompanyId) {
            this.resetUsersState();

            if (newCompanyId) {
                this.fetchAllUsersForCompany(newCompanyId);
            }
        },
    },

    computed: {
        ...mapGetters([
            'isSuperAdmin',
        ]),
        hasUsers() {
            return this.$store.getters.hasUsers;
        },
        usersAsync() {
            return this.$store.state.users.async;
        },
        selectedCompanyId() {
            return this.$store.getters.selectedCompanyId;
        },
    },
};
</script>

<style lang="scss" scoped>
.company-users {
    display: flex;
    flex: 1;
    flex-direction: column;

    .select-company-text,
    .async-container {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #F3F4F7;
    }
}

</style>
