export const FETCH_ALL_PROPERTIES_ACTION = 'FETCH_ALL_PROPERTIES_ACTION';
export const FETCH_PROPERTIES_PAGE_ACTION = 'FETCH_PROPERTIES_PAGE_ACTION';
export const SET_PROPERTIES_ASYNC = 'SET_PROPERTIES_ASYNC';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const APPEND_PROPERTIES_PAGE = 'APPEND_PROPERTIES_PAGE';
export const ADD_NEW_PROPERTY = 'ADD_NEW_PROPERTY';
export const SET_PROPERTIES_ERRORS = 'SET_PROPERTIES_ERRORS';
export const APPEND_NEW_PROPERTY = 'APPEND_NEW_PROPERTY';
export const SET_ADD_PROPERTY_ASYNC = 'SET_ADD_PROPERTY_ASYNC';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const REMOVE_PROPERTY = 'REMOVE_PROPERTY';
export const EDIT_PROPERTY_ACTION = 'EDIT_PROPERTY_ACTION';
export const CHANGE_PROPERTY_AGENT_ACTION = 'CHANGE_PROPERTY_AGENT_ACTION';
export const ASSIGN_KEYWORD_TO_PROPERTY_ACTION = 'ASSIGN_KEYWORD_TO_PROPERTY_ACTION';
export const SET_EDIT_PROPERTY_ASYNC = 'SET_EDIT_PROPERTY_ASYNC';
export const SET_EDIT_PROPERTY_ERRORS = 'SET_EDIT_PROPERTY_ERRORS';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const DESELECT_PROPERTY = 'DESELECT_PROPERTY';
export const SELECT_PROPERTY_ACTION = 'SELECT_PROPERTY_ACTION';
export const SET_PROPERTY_SIDEBAR_OPEN = 'SET_PROPERTY_SIDEBAR_OPEN';

export const SET_SELECTED_PROPERTY_INTERESTS = 'SET_SELECTED_PROPERTY_INTERESTS';
export const FETCH_SELECTED_PROPERTY_INTERESTS = 'SET_SELECTED_PROPERTY_INTERESTS';

export const SET_SELECTED_PROPERTY_INTERESTS_ASYNC = 'SET_SELECTED_PROPERTY_INTERESTS_ASYNC';

export const SET_EDIT_SELECTED_PROPERTY_INTEREST_ASYNC = 'SET_EDIT_SELECTED_PROPERTY_INTEREST_ASYNC';
export const EDIT_SELECTED_PROPERTY_INTEREST_ACTION = 'EDIT_SELECTED_PROPERTY_INTEREST_ACTION';
export const UPDATE_SELECTED_PROPERTY_INTEREST = 'UPDATE_SELECTED_PROPERTY_INTEREST';

export const SET_PROPERTIES_SEARCH_TEXT = 'SET_PROPERTIES_SEARCH_TEXT';
export const SET_SEARCH_PROPERTIES = 'SET_SEARCH_PROPERTIES';
export const SEARCH_PROPERTIES_ACTION = 'SEARCH_PROPERTIES_ACTION';
export const APPEND_SEARCH_PROPERTIES_PAGE = 'APPEND_SEARCH_PROPERTIES_PAGE';


export const REMOVE_PROPERTY_FROM_ARCHIVED = 'REMOVE_PROPERTY_FROM_ARCHIVED';
export const ARCHIVE_PROPERTY_ACTION = 'ARCHIVE_PROPERTY_ACTION';
export const UNARCHIVE_PROPERTY_ACTION = 'UNARCHIVE_PROPERTY_ACTION';
export const FETCH_ALL_ARCHIVED_PROPERTIES_ACTION = 'FETCH_ALL_ARCHIVED_PROPERTIES_ACTION';
export const FETCH_ARCHIVED_PROPERTIES_PAGE_ACTION = 'FETCH_ARCHIVED_PROPERTIES_PAGE_ACTION';
export const SET_ARCHIVED_PROPERTIES_ASYNC = 'SET_ARCHIVED_PROPERTIES_ASYNC';
export const SET_ARCHIVED_PROPERTIES = 'SET_ARCHIVED_PROPERTIES';
export const APPEND_ARCHIVED_PROPERTIES_PAGE = 'APPEND_ARCHIVED_PROPERTIES_PAGE';
export const APPEND_ARCHIVED_PROPERTY = 'APPEND_ARCHIVED_PROPERTY';
