<template>
    <span>
        <el-button @click.stop.prevent="visible = true"
                   v-tooltip="'Archive Property'"
                   class="delete-property-btn"
                   type="text"
                   style="margin-left: 16px;">
            <i style="display: block;cursor: pointer" class="icon icon-actions_delete"/>
        </el-button>

        <el-dialog :title="title"
                   :append-to-body="true"
                   :modal-append-to-body="true"
                   :custom-class="activeClass"
                   :visible.sync="visible"
                   @close="visible = false">
            <div class="archive-property">
                <div class="property-name">{{ property.name }}</div>
                <div class="status-option sold" @click="form.selectedOption = 'SOLD'">
                    SOLD
                </div>
                <div class="status-option off-market" @click="form.selectedOption = 'OFF_MARKET'">
                    OFF MARKET
                </div>

                <div class="message">{{ message }}</div>
            </div>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
            </div>

            <span slot="footer" class="footer">
                <el-button type="text" @click="visible = false">Cancel</el-button>
                <el-button :loading="async"
                           class="archive-btn"
                           type="text"
                           @click="onSubmit">
                    Archive
                </el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import { mapActions } from 'vuex';
import { GENERATE_KEYWORDS_ACTION } from '@/store/actions/companies';
import { ARCHIVE_PROPERTY_ACTION } from "@/store/actions/properties";
import { PROPERTY_STATUS_OPTIONS } from "@/utils/consts";

function getDefaultData() {
    return {
        selectedOption: PROPERTY_STATUS_OPTIONS.SOLD
    };
}

export default {
    name: 'ArchivePropertyDialog',
    components: {},
    data() {
        return {
            form: getDefaultData(),
            visible: false,
        };
    },
    props: {
        property: Object,
    },
    computed: {
        title() {
            return "Archive Property"
        },
        errors() {
            return this.$store.state.companies.addEditErrors;
        },
        async() {
            return this.$store.state.companies.addEditAsync;
        },
        message() {
            return this.form.selectedOption === PROPERTY_STATUS_OPTIONS.OFF_MARKET ? "This action will archive all related interests!" : "";
        },
        activeClass() {
            return this.form.selectedOption === PROPERTY_STATUS_OPTIONS.OFF_MARKET ? "active-off-market" : "active-sold";
        }
    },
    watch: {
        visible(newVisible) {
            if (newVisible === true) {
                this.form = getDefaultData();
            }
        },
    },
    methods: {
        ...mapActions({
            generateKeywords: GENERATE_KEYWORDS_ACTION,
            archivePropertyAction: ARCHIVE_PROPERTY_ACTION,
        }),
        onSubmit() {
            this.archivePropertyAction({
                propertyId: this.property.id,
                newStatus: this.form.selectedOption
            }).then((res) => {
                if (res !== false) {
                    this.form = getDefaultData();
                    this.visible = false;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">

.archive-property {
    display: flex;
    flex-direction: column;
    align-items: center;

    .property-name {
        color: #E5E5EA;
        margin-bottom: 20px;
    }

    .status-option {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 2px solid #748AA1;
        color: #748AA1;
        border-radius: 8px;
        padding: 10px 0;
        margin-top: 20px;
        font-size: 16px;
        cursor: pointer;
        opacity: 0.8;

        &.sold {
            &:hover {
                opacity: 1;
            }
        }

        &.off-market {

            &:hover {
                opacity: 1;
                color: #FA5D5D;
                border-color: #FA5D5D;
            }
        }
    }

    .message {
        margin-top: 30px;
        font-size: 16px;
        min-height: 20px;
    }
}

.active-sold {

    .sold {
        opacity: 1;
    }

    .archive-btn {
        &:hover {
            color: #fff;
        }
    }
}

.active-off-market {

    .off-market {
        opacity: 1;
        color: #FA5D5D;
        border-color: #FA5D5D;
    }

    .message {
        color: #FA5D5D;
    }

    .archive-btn {
        &:hover {
            color: #FA5D5D;
        }
    }
}

.footer {
    .el-button {
        color: #748AA1;
    }
}

.delete-property-btn {
    font-size: 16px;

    .icon {
        color: #748AA1;
        transition: all 300ms ease-in-out;
        font-size: 20px;

        &:hover {
            color: #FA5D5D;
        }
    }
}

</style>
