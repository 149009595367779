<template>
    <div class="notifications-container">
        <div class="switch-container">
            <el-switch @change="saveUserData"
                       v-model="form.sms_notifications"/>
            <span class="switch-text">SMS Notifications</span>
        </div>

        <div class="switch-container">
            <el-switch @change="saveUserData"
                       v-model="form.email_notifications"/>
            <span class="switch-text">Email Notifications</span>
        </div>

        <div v-if="form.email_notifications">
            <labeled-text-area v-model="form.notify_emails"
                               placeholder="Type comma separated emails here"/>
            <el-button :loading="formAsync"
                       @click="saveUserData"
                       type="text">
                SAVE
            </el-button>
        </div>

        <div class="switch-container">
            <el-switch @change="saveUserData"
                       v-model="form.browser_notifications"/>
            <span class="switch-text">Browser Notifications</span>
        </div>

        <div class="switch-container">
            <el-switch @change="saveUserData"
                       v-model="form.app_push_notifications"/>
            <span class="switch-text">App Push Notifications</span>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {difference} from '@/utils/utils';
import {mapActions, mapGetters} from 'vuex';
import {EDIT_USER_ACTION} from '@/store/actions/users';
import LabeledTextArea from "@/components/LabeledTextArea/LabeledTextArea.vue";

export default {
    name: 'NotificationsTab',
    components: {LabeledTextArea},
    data() {
        return {
            form: {},
            formAsync: false,
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters([
            'isImpersonationActive',
            'impersonatedUser',
        ]),
        user() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.$store.getters.me;
        },
    },
    methods: {
        ...mapActions({
            editUser: EDIT_USER_ACTION,
        }),
        init() {
            this.form = cloneDeep(this.user);
        },
        onImpersonateChange() {
            this.init();
        },
        saveUserData() {
            this.formAsync = true;
            this.editUser({id: this.user.id, ...difference(this.form, this.user)})
                .then(() => {
                    this.$nextTick(function () {
                        this.form = cloneDeep(this.user);
                    });
                })
                .finally(() => {
                    this.formAsync = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>

.notifications-container {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;

    .switch-container {
        margin-top: 12px;

        .switch-text {
            color: #fff;
            font-size: 14px;
            margin-left: 12px;
        }
    }
}

</style>
