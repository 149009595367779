<template>
    <el-popover
        placement="bottom"
        width="400"
        v-model="popoverVisible">
        <div class="lead-filter-popover">

            <div class="flex-row" style="align-items: center">
                <labeled-select name="Property"
                                v-model="selectedProperty"
                                placeholder="Property">
                    <el-option :label="property.name"
                               :value="property.id"
                               :key="property.id"
                               v-for="property in properties">
                        {{ property.name }}
                    </el-option>
                </labeled-select>

                <el-button v-tooltip="'Clear Filter'"
                           class="clear-filter"
                           type="text"
                           @click="clearPropertyFilter">
                    <i class="icon-actions_delete"/>
                </el-button>
            </div>

            <div class="footer">
                <el-button class="main-filter-button" @click="onFilter">
                    Filter
                </el-button>
            </div>
        </div>
        <div slot="reference" v-tooltip="'Filter'" class="filter-btn">
            <i class="icon-filter"/>
        </div>
    </el-popover>
</template>

<script>
import { FETCH_ALL_PROPERTIES_ACTION } from '@/store/actions/properties';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LabeledSelect from '@/components/LabeledSelect/LabeledSelect.vue';
import { FETCH_ALL_ARCHIVED_LEADS_ACTION, FETCH_ALL_LEADS_ACTION, SET_LEADS_FILTERS } from '@/store/actions/leads';

export default {
    name: 'LeadListHeaderFilters',
    components: { LabeledSelect },
    props: {
        isArchived: {
            type: Boolean,
            value: false,
        },
    },
    data() {
        return {
            popoverVisible: false,
            selectedProperty: null,
        };
    },
    methods: {
        ...mapMutations({
            setLeadsFilters: SET_LEADS_FILTERS,
        }),
        ...mapActions({
            fetchAllPropertiesAction: FETCH_ALL_PROPERTIES_ACTION,
            fetchAllLeads: FETCH_ALL_LEADS_ACTION,
            fetchAllArchivedLeads: FETCH_ALL_ARCHIVED_LEADS_ACTION,
        }),
        onFilter() {
            let filters = {};

            if (this.selectedProperty !== null) {
                filters.property = this.selectedProperty;
            }

            this.setLeadsFilters(filters);

            this.popoverVisible = false;

            if (this.isArchived) {
                this.fetchAllArchivedLeads();
            } else {
                this.fetchAllLeads();
            }
        },
        clearPropertyFilter() {
            this.selectedProperty = null;
        },
    },
    computed: {
        ...mapGetters([
            'properties',
        ]),
        filters() {
            return this.$store.state.leads.filters;
        },
        propertiesAsync() {
            return this.$store.state.properties.async;
        },
    },
    watch: {
        popoverVisible(newVisible) {
            if (newVisible) {
                if (this.filters.property) {
                    this.selectedProperty = this.filters.property;
                }

                // fetch properties
                if (this.properties.length === 0) {
                    this.fetchAllPropertiesAction();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.filter-btn {
    cursor: pointer;
    display: flex;
    align-items: center;

    i {
        color: #05c696;
    }
}

.lead-filter-popover {

    .clear-filter {
        margin-top: 24px;
        margin-left: 1rem;
        margin-right: 0.5rem;
        font-size: 22px;
        color: #748AA1;

        &:hover {
            color: #15d0a2;
        }
    }

    .select-container {
        flex: 1;
    }

    .main-filter-button {
        background-color: transparent;
        border: 1px solid #13A68A;
        padding: 6px 10px;

        &:focus {
            color: #15d0a2;
        }
    }

    .footer {
        padding-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }
}

</style>
