import { agencySettings, apiCall, companies } from '../apiCall';

export async function fetchCompanyAgencySettings(companyId) {
    let url = new URL(`${companies}/${companyId}/agency_settings`);

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function saveCompanySettings(companyId, settings) {
    let url = new URL(`${companies}/${companyId}`);

    return apiCall({
        url: url.href,
        method: 'PATCH',
        headers: {
            'content-type': 'application/merge-patch+json',
            'accept': 'application/ld+json',
        },
        jsonData: settings,
    }).then(r => r.json());
}


export async function saveAgencySettings(agencySettingsId, settings) {
    let url = new URL(`${agencySettings}/${agencySettingsId}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: settings,
    }).then(r => r.json());
}
