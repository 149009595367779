import {
    FETCH_ALL_KEYWORDS_ACTION,
    SET_KEYWORDS_ASYNC,
    SET_KEYWORDS,
    UPDATE_KEYWORD,
    SET_KEYWORDS_ASSIGN_AGENT_ASYNC,
    SET_KEYWORDS_ASSIGN_AGENT_ERRORS,
    ASSIGN_KEYWORD_TO_AGENT_ACTION,
    EDIT_KEYWORD_ACTION,
    SET_EDIT_KEYWORD_ERRORS,
    SET_EDIT_KEYWORD_ASYNC
} from '@/store/actions/keywords';
import { assignKeywordToAgent, editKeyword, fetchAllKeywords } from '@/api/keywords/keywords';

export const KEYWORD_STATUS_OPTIONS = {
    USED: 'USED',
    AVAILABLE: 'AVAILABLE',
};

export default {
    state: () => ({
        keywords: [],
        async: false,
        editAsync: false,
        editErrors: null,
        assignAgentAsync: false,
        assignAgentErrors: null,
    }),
    mutations: {
        [SET_KEYWORDS](state, keywords) {
            state.keywords = keywords;
        },
        [SET_KEYWORDS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_KEYWORDS_ASSIGN_AGENT_ASYNC](state, async) {
            state.assignAgentAsync = async;
        },
        [SET_KEYWORDS_ASSIGN_AGENT_ERRORS](state, errors) {
            state.assignAgentErrors = errors;
        },
        [UPDATE_KEYWORD](state, { id, ...data }) {
            for (let keyword of state.keywords) {
                if (keyword.id === id) {
                    Object.assign(keyword, data);
                    return;
                }
            }
        },
        [SET_EDIT_KEYWORD_ERRORS](state, errors) {
            state.editErrors = errors;
        },
        [SET_EDIT_KEYWORD_ASYNC](state, async) {
            state.editAsync = async;
        }
    },
    actions: {
        async [FETCH_ALL_KEYWORDS_ACTION]({ commit }) {
            commit(SET_KEYWORDS_ASYNC, true);

            await fetchAllKeywords().then(keywords => {
                commit(SET_KEYWORDS, keywords);
            }).finally(() => {
                commit(SET_KEYWORDS_ASYNC, false);
            });
        },
        async [ASSIGN_KEYWORD_TO_AGENT_ACTION]({ commit }, { id, agent_id }) {
            commit(SET_KEYWORDS_ASSIGN_AGENT_ASYNC, true);
            commit(SET_KEYWORDS_ASSIGN_AGENT_ERRORS, null);

            try {
                const r = await assignKeywordToAgent({ id, agent_id });
                commit(SET_KEYWORDS_ASSIGN_AGENT_ASYNC, false);
                commit(UPDATE_KEYWORD, r);

                return true;
            } catch (ex) {
                console.error(ex);

                commit(SET_KEYWORDS_ASSIGN_AGENT_ERRORS, ['Unknown Error']);
                commit(SET_KEYWORDS_ASSIGN_AGENT_ASYNC, false);

                return false;
            }
        },
        async [EDIT_KEYWORD_ACTION]({ commit }, { id, ...data }) {
            commit(SET_EDIT_KEYWORD_ASYNC, true);
            commit(SET_EDIT_KEYWORD_ERRORS, null);

            try {
                const res = await editKeyword({ id, data });
                commit(SET_EDIT_KEYWORD_ASYNC, false);
                commit(UPDATE_KEYWORD, res);

                return true;
            } catch (ex) {
                await ex.json().then(r => {
                    commit(SET_EDIT_KEYWORD_ERRORS, [r['detail']]);
                }).catch(() => {
                    commit(SET_EDIT_KEYWORD_ERRORS, ["Unknown error."]);
                });

                commit(SET_EDIT_KEYWORD_ASYNC, false);
                return false;
            }
        },
    },
    getters: {
        availableKeywords(state, getters) {
            return getters.allKeywords.filter(k => k.status === KEYWORD_STATUS_OPTIONS.AVAILABLE);
        },
        allKeywords(state) {
            return state.keywords || [];
        },
    },
};
