<template>
    <div class="lead-multiselect-actions">
        <el-dropdown placement="bottom" trigger="click" @command="onDropdownCommand">
            <span class="more-menu-btn">
                <i class="icon icon-menudots_vertical"/>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="toggle-important">
                    <span>Mark Important</span>
                </el-dropdown-item>
                <el-dropdown-item command="toggle-unimportant">
                    <span>Mark Unimportant</span>
                </el-dropdown-item>
                <el-dropdown-item command="opt-out">
                    <span>Opt out</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="!isArchived" command="archive">
                    <span>Archive</span>
                </el-dropdown-item>
                <el-dropdown-item v-else command="unarchive">
                    <span>Unarchive</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
    ARCHIVE_LEAD_ACTION,
    MARK_LEAD_AS_IMPORTANT_ACTION,
    OPT_OUT_LEAD_ACTION,
    UNARCHIVE_LEAD_ACTION,
} from '@/store/actions/leads';

export default {
    name: 'LeadMultiSelectActions',
    props: {
        isArchived: {
            type: Boolean,
            value: false,
        },
    },
    methods: {
        ...mapActions({
            archiveLead: ARCHIVE_LEAD_ACTION,
            unarchiveLead: UNARCHIVE_LEAD_ACTION,
            markLeadAsImportant: MARK_LEAD_AS_IMPORTANT_ACTION,
            optOutLeadAction: OPT_OUT_LEAD_ACTION,
        }),
        onDropdownCommand(command) {
            switch (command) {
                case 'toggle-important': {
                    this.onMarkLeadImportant();
                    break;
                }
                case 'toggle-unimportant': {
                    this.onMarkLeadUnimportant();
                    break;
                }
                case 'unarchive': {
                    this.onUnarchiveLead();
                    break;
                }
                case 'archive': {
                    this.onArchiveLead();
                    break;
                }
                case 'opt-out': {
                    this.onOptOut();
                    break;
                }
            }
        },
        onMarkLeadImportant() {
            this.multiSelectedLeads.forEach(lead => {
                if (!lead.is_important) {
                    this.markLeadAsImportant(lead.id);
                }
            });
        },
        onMarkLeadUnimportant() {
            this.multiSelectedLeads.forEach(lead => {
                if (lead.is_important) {
                    this.markLeadAsImportant(lead.id);
                }
            });
        },
        onUnarchiveLead() {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes, unarchive it!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    const promises = this.multiSelectedLeads.map(lead => {
                        return this.unarchiveLead(lead.id);
                    });

                    Promise.allSettled(promises).then(() => {
                        this.$swal({
                            text: 'Success',
                            icon: 'success',
                            timer: 1000,
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: false,
                        });
                    });
                }
            });
        },
        onArchiveLead() {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes, archive it!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    const promises = this.multiSelectedLeads.map(lead => {
                        return this.archiveLead(lead.id);
                    });

                    Promise.allSettled(promises).then(() => {
                        this.$swal({
                            text: 'Success',
                            icon: 'success',
                            timer: 1000,
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: false,
                        });
                    });
                }
            });
        },
        onOptOut() {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Yes, opt out!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    const promises = this.multiSelectedLeads.map(lead => {
                        return this.optOutLeadAction(lead.id);
                    });

                    Promise.allSettled(promises).then(() => {
                        this.$swal({
                            text: 'Success',
                            icon: 'success',
                            timer: 1000,
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: false,
                        });
                    });
                }
            });
        },
    },
    computed: {
        ...mapGetters([
            'multiSelectedLeads',
        ]),
    },
};
</script>

<style lang="scss">
.lead-multiselect-actions {
    .el-dropdown {
        span {
            display: flex;
        }
    }
}
</style>

<style scoped lang="scss">
.lead-multiselect-actions {
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .more-menu-btn {
        color: #007AFF;
        margin-right: 1px;
        font-size: 18px;
    }
}
</style>
