<template>
    <div class="page my-leads">
        <leads-list/>
        <transition name="fade">
            <leads-conversation-section-wrapper v-if="Boolean(selectedLead) || isLeadsMultiselectActive"/>
            <span class="select-lead" v-else>
                Select Lead
            </span>
        </transition>
    </div>
</template>

<script>
import LeadsList from './components/LeadsList/LeadsList';
import { mapGetters } from 'vuex';
import LeadsConversationSectionWrapper from '@/views/MyLeads/LeadsConversationSectionWrapper';

const ALL_MESSAGES_TAB_ID = 'all-messages';
const UNREAD_TAB_ID = 'unread';
const IMPORTANT_TAB_ID = 'important';

const tabs = [{
    name: 'All Messages',
    id: ALL_MESSAGES_TAB_ID,
}, {
    name: 'Unread',
    id: UNREAD_TAB_ID,
}, {
    name: 'Important',
    id: IMPORTANT_TAB_ID,
}];

export default {
    name: 'MyLeads',
    components: { LeadsConversationSectionWrapper, LeadsList },
    computed: {
        ...mapGetters([
            'selectedLead',
            'isLeadsMultiselectActive',
        ]),
        tabs: function () {
            return tabs;
        },
    },
};
</script>

<style scoped lang="scss">

.theme-light {
    .my-leads {
        background-color: #F3F4F7;
    }
}

.my-leads {
    background-color: #1E2234;
    display: flex;

    .select-lead {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        overflow: hidden;

        color: #cccccc;
        font-size: 15px;
    }
}

</style>
