import { apiCall, message_templates } from '@/api/apiCall';

export async function fetchCompanyTemplates() {
    let url = new URL(`${message_templates}/read_company_templates`);

    url.searchParams.append("pagination", "false");

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/json',
        },
    }).then(r => r.json());
}

export async function addNewCompanyTemplate(text) {
    let url = new URL(`${message_templates}/create_company_template`);

    return apiCall({
        url: url.href,
        method: 'POST',
        headers: {
            'accept': 'application/json',
        },
        jsonData: {
            text,
        },
    }).then(r => r.json());
}

export async function fetchUserTemplates() {
    let url = new URL(`${message_templates}`);

    url.searchParams.append("pagination", "false");

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/json',
        },
    }).then(r => r.json());
}

export async function addNewTemplate(text) {
    let url = new URL(`${message_templates}`);

    return apiCall({
        url: url.href,
        method: 'POST',
        headers: {
            'accept': 'application/json',
        },
        jsonData: {
            text,
        },
    }).then(r => r.json());
}

export async function editTemplate(id, data) {
    let url = new URL(`${message_templates}/${id}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/json',
        },
        jsonData: {
            ...data,
        },
    }).then(r => r.json());
}

export async function deleteTemplate(id) {
    let url = new URL(`${message_templates}/${id}`);

    return apiCall({
        url: url.href,
        method: 'DELETE',
        headers: {
            'accept': 'application/json',
        },
    });
}
