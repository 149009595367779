<template>
    <div class="profile-dropdown-container" v-click-outside="onClickOutside" :class="{open: profileDropdownOpened}">
        <div class="arrow-container">
            <div class="arrow-up"/>
        </div>

        <div class="profile-dropdown-content">
            <div class="away-mode">
                <div class="online-away-switch-container" :class="{online: isOnline, away: !isOnline}">
                    <span v-if="isOnline">ONLINE</span>
                    <span v-else>AWAY</span>
                    <el-switch :value="isOnline" @change="onOnlineStatusChange"/>
                </div>
                <div class="away-settings" :class="{}" v-if="!isOnline">
                    <labeled-select name="Minutes" v-model="form.minutes" v-if="isEditMode">
                        <el-option v-for="minutes in minutesOptions"
                                   :key="minutes"
                                   :label="minutes + ' minutes'"
                                   :value="minutes">
                            {{ minutes + ' minutes' }}
                        </el-option>
                    </labeled-select>
                    <labeled-input :key="forceUpdate" name="Minutes" v-if="!isEditMode" :placeholder="awayForNextNMinutesText()" :disabled="true"/>
                    <labeled-input name="Auto Response Message" v-model="form.auto_response_message"
                                   :disabled="!isEditMode"/>
                    <div class="editstatusbtn-container">

                        <el-button class="editstatus-button" @click="onAwayEditSaveClick" :loading="async">
                            <span v-if="!isEditMode">EDIT STATUS</span>
                            <span v-else>SAVE STATUS</span>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="log-out-btn" @click="logOut()">
                Log Out
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ClickOutside from 'vue-click-outside';
import { SET_PROFILE_DROPDOWN } from '@/store/actions/ui';
import { storage_url } from '@/api/apiCall';
import { logOut } from '@/api/apiCall';
import { SET_AWAY_MODE_ACTION } from '@/store/actions/users';
import LabeledSelect from '@/components/LabeledSelect/LabeledSelect';
import LabeledInput from '@/components/LabeledInput/LabeledInput';
import { cloneDeep } from 'lodash';
import moment from 'moment';

export default {
    name: 'ProfileDropdown',
    directives: {
        ClickOutside,
    },
    data() {
        return {
            interval: null,
            forceUpdate: 0,
            async: false,
            isEditMode: false,
            form: {
                minutes: 15,
            },
        };
    },
    components: { LabeledInput, LabeledSelect },
    methods: {
        ...mapActions({
            setAwayModeAction: SET_AWAY_MODE_ACTION,
        }),
        ...mapMutations({
            setProfileDropdown: SET_PROFILE_DROPDOWN,
        }),
        onClickOutside() {
            if (this.profileDropdownOpened) {
                this.setProfileDropdown(false);
            }
        },
        onOnlineStatusChange(val) {
            this.setAwayModeAction({ is_away: !val, minutes: 15 });
        },
        onAwayEditSaveClick() {
            if (this.isEditMode) {
                this.form.minutes = Number(this.form.minutes);
                delete this.form.away_mode_turned_on_at;

                this.async = true;

                this.setAwayModeAction({ ...this.form }).then(() => {
                    this.async = false;
                    this.isEditMode = false;
                });
            } else {
                this.isEditMode = true;
            }
        },

        updateOfflineTrackerTicker() {
            if (!this.isOnline) {
                this.interval = setInterval(() => {
                    this.forceUpdate = this.forceUpdate + 1;
                }, 60 * 1000);
            } else {
                clearInterval(this.interval);
            }
        },

        awayForNextNMinutesText() {
            const awayModeSettings = this.user?.settings?.away_mode;
            if (awayModeSettings !== null) {
                let awayModeActiveUntil = moment(awayModeSettings.away_mode_turned_on_at).add(awayModeSettings.minutes, 'minutes').unix();
                let nowDiff = awayModeActiveUntil - moment().unix();

                let minutesLeft = Math.ceil(nowDiff / 60);

                return `You will be away for ${minutesLeft} minute(s)`;
            }

            return '';
        },
        logOut
    },
    mounted() {
        this.popupItem = this.$el.parentElement;
        this.updateOfflineTrackerTicker();
    },
    computed: {
        ...mapGetters([
            'profileDropdownOpened',
            'impersonatedUser',
            'isImpersonationActive',
            'me',
        ]),
        minutesOptions() {
            return [15, 30, 60];
        },
        isOnline() {
            return (this.user?.settings?.away_mode?.is_away || false) === false;
        },
        user() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.me;
        },
        profilePictureUrl() {
            if (this.me.profile_picture) {
                return `${storage_url}${this.me.profile_picture.content_url}`;
            }

            return null;
        },
    },
    watch: {
        isOnline: function (newOnline) {
            if (newOnline === false) {
                this.form = cloneDeep(this.user?.settings?.away_mode || {});
            }

            this.updateOfflineTrackerTicker();
        },
        profileDropdownOpened: function (newOpened) {
            if (newOpened === true) {
                this.form = cloneDeep(this.user?.settings?.away_mode || {});
                this.isEditMode = false;
            }
        }
    },
};
</script>

<style lang="scss">
.profile-dropdown-container {
    .profile-dropdown-content {
        .away-mode {
            .online-away-switch-container {
                &.away {
                    .el-switch {
                        .el-switch__core {
                            background: #FA5D5D;
                            border: 1px solid #FA5D5D;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style scoped lang="scss">
.profile-dropdown-container {
    position: absolute;
    width: 300px;
    max-height: 0px;
    overflow: hidden;
    z-index: 10000;
    top: 44px;
    right: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    transition: all 250ms ease-in-out;
    cursor: auto;

    .arrow-container {
        height: 8px;
        position: relative;

        .arrow-up {
            position: absolute;
            left: calc(100% - 28px);
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;

            border-bottom: 8px solid #181B29;
        }
    }

    .profile-dropdown-content {
        background: #181B29;
        height: 100%;
        display: flex;
        flex-direction: column;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .away-mode {
            display: flex;
            flex-direction: column;
            max-height: fit-content;
            padding: 32px 24px 24px 24px;
            flex: 1;

            .editstatusbtn-container {
                display: flex;
                justify-content: flex-end;
                margin-top: 8px;

                .editstatus-button {
                    background: transparent;
                    border-color: #E5E5EA;
                    color: #B8C5D3;

                    &:hover {
                        border-color: #05c696;
                        color: #05c696;
                    }
                }
            }

            .online-away-switch-container {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                &.online {
                    span {
                        color: #05c696;
                    }
                }

                &.away {
                    span {
                        color: #FA5D5D;
                    }
                }

                span {
                    font-size: 14px;
                    margin-right: 22px;
                    font-weight: bold;
                }
            }
        }

        .log-out-btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #545F69;
            align-self: center;
            justify-self: flex-end;
            padding-bottom: 14px;
            padding-top: 10px;
            font-size: 14px;
            transition: all 250ms ease-in-out;
            cursor: pointer;

            &:hover {
                background: #242738;
            }
        }
    }

    &.open {
        max-height: 365px;
    }
}
</style>
