import { apiCall, endpoint, inbox, leads, property_interest } from '../apiCall';

export async function fetchLeadsPage(page = 1, searchText = null, filters = null) {
    let url = new URL(inbox);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', 30);

    if (filters?.property) {
        url.searchParams.append('property', filters.property);
    }

    if (searchText !== null) {
        url.searchParams.append('search', searchText);
    }

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function getInterestsForLead(leadId) {
    let url = new URL(`${leads}/${leadId}/interests?per_page=30&page=1`);

    return apiCall({
        url: url.href,
        method: 'GET',
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function setInterestCollapsed(interestId, collapsed) {
    let url = new URL(`${property_interest}/${interestId}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: {
            collapsed,
        },
    });
}

export async function setInterestArchived(interestId, archived) {
    let url = new URL(`${property_interest}/${interestId}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: {
            archived,
        },
    });
}

export async function fetchArchivedLeadsPage(page = 1, searchText = null, filters = null) {
    let url = new URL(inbox);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', 30);
    url.searchParams.append('archived', true);

    if (filters?.property) {
        url.searchParams.append('property', filters.property);
    }

    if (searchText !== null) {
        url.searchParams.append('search', searchText);
    }

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function editLead(id, data) {
    let url = new URL(`${leads}/${id}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: data,
    }).then(r => r.json());
}

export async function archiveLead(leadId) {
    let url = new URL(`${leads}/${leadId}/archive`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        jsonData: {},
    });
}

export async function unarchiveLead(leadId) {
    let url = new URL(`${leads}/${leadId}/unarchive`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        jsonData: {},
    });
}

export async function markLeadAsUnread(leadId) {
    let url = new URL(`${leads}/${leadId}/mark_as_unread`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        jsonData: {},
    });
}

export async function markLeadAsImportant(leadId) {
    let url = new URL(`${leads}/${leadId}/is_important/toggle`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        jsonData: {},
    });
}

export async function addManualLead(data) {
    let url = new URL(`${leads}`);

    return apiCall({
        url: url.href,
        method: 'POST',
        jsonData: data,
    }).then(r => r.json());
}

export async function optOutLead(leadId) {
    let url = new URL(`${leads}/${leadId}/manual_opt_out`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        jsonData: {},
    }).then(r => r.json());
}

export async function sendLeadDetailsWithProperty({ lead_phone, property_id }) {
    let url = new URL(`${endpoint}/api/public/leads/send_details`);

    return apiCall({
        url: url.href,
        method: 'POST',
        jsonData: {
            lead_phone,
            property_id,
        },
    }).then(r => r.json());
}
