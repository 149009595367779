<template>
    <div class="add-new-property">
        <div class="add-search-container">
            <el-button class="add-new-property-btn" @click="addPropertyFormOpened = !addPropertyFormOpened">
                ADD NEW PROPERTY
            </el-button>
            <search-bar @change="onSearchChange"/>
        </div>
        <div class="add-property-form" :class="{collapsed: !addPropertyFormOpened}">
            <div class="step-1" :class="{active: true}">
                <div class="step-1-content">
                    <div class="input-container">
                        <p class="title">URL</p>
                        <el-input v-model="url" placeholder="Paste URL here"/>
                    </div>

                    <el-button class="input-aligned-btn"
                               style="width: 70px; max-width: 70px;"
                               v-if="!generateDataAsync"
                               @click="generateData"
                               type="text">
                        NEXT
                    </el-button>

                    <div class="generate-async-container" v-else>
                        <vue-loaders-ball-clip-rotate color="#13A68A"/>
                    </div>
                </div>
                <div class="explanation-div">
                    <div class="connector-line-half-1">
                        <span v-if="step === 1" class="explanation">PASTE YOUR LISTING URL HERE</span>
                    </div>
                    <div class="connector-line-half-2"/>
                </div>
            </div>

            <div class="step-2" :class="{active: step === 2}">
                <div class="step-2-content">
                    <div class="input-container">
                        <p class="title">Address</p>
                        <el-input v-model="propertyForm.name" placeholder="Type Name here"/>
                    </div>
                    <div class="input-container">
                        <p class="title">Keywords</p>
                        <el-select v-model="propertyForm.keyword_id" placeholder="Keyword">
                            <el-option v-for="keyword in availableKeywords" :label="keyword.name" :value="keyword.id"
                                       :key="keyword.id">
                                {{ keyword.name }}
                            </el-option>
                        </el-select>
                    </div>

                    <div class="input-container" v-if="isCompanyAdmin && !isImpersonationActive">
                        <p class="title">Property Agent</p>
                        <el-select v-model="propertyForm.agent_id" placeholder="User" v-if="!usersAsync">
                            <el-option :label="user | mergeName" :value="user.id" :key="user.id" v-for="user in allUsers">
                                {{ user | mergeName }}
                            </el-option>
                        </el-select>

                        <div v-else class="async-container">
                            <vue-loaders-line-scale/>
                        </div>
                    </div>

                    <el-button :loading="async"
                               @click="addListing"
                               style="max-width: 150px; min-width: 150px;"
                               class="btn add-listing-btn input-aligned-btn">
                        ADD LISTING
                    </el-button>
                </div>

                <div class="explanation-div" style="margin-right: 65px">
                    <div class="connector-line-half-1">
                        <span v-if="step === 2" class="explanation">YOU CAN CHANGE ADDRESS AND/OR KEYWORD BEFORE ADDING LISTING.</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="errors-container">
            <div v-for="error in errors" :key="error" class="error"> {{ error }}</div>
            <div class="error" v-if="urlError !== false">{{ urlError }}</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ADD_NEW_PROPERTY, SEARCH_PROPERTIES_ACTION } from '@/store/actions/properties';
import { generateDataForPropertyUrl } from '@/api/properties/properties';
import { FETCH_ALL_USERS_FOR_COMPANY } from '@/store/actions/users';
import { validURL } from '@/utils/utils';
import SearchBar from '@/components/SearchBar/SearchBar';
import { throttle } from 'lodash';

function getDefaultData() {
    return {
        addPropertyFormOpened: false,
        step: 1,
        urlError: false,
        url: '',
        propertyForm: {
            name: '',
            keyword_id: '',
            description: null,
            price: null,
            currency: null,
            image: null,
            url: null,
        },
        generateDataAsync: false,
    };
}

export default {
    name: 'AddNewPropertyTab',
    components: { SearchBar },
    data: getDefaultData,
    computed: {
        ...mapGetters([
            'isCompanyAdmin',
            'hasUsers',
            'allUsers',
            'companyId',
            'availableKeywords',
            'isImpersonationActive',
        ]),
        async() {
            return this.$store.state.properties.addAsync;
        },
        errors() {
            return this.$store.state.properties.errors;
        },
        usersAsync() {
            return this.$store.state.users.async;
        },
    },
    mounted() {
        if (!this.hasUsers && this.isCompanyAdmin) {
            this.fetchAllUsersForCompany(this.companyId);
        }
    },
    methods: {
        onSearchChange: throttle(function (text) {
            this.searchProperties({ searchText: text, isArchive: false });
        }, 750),
        ...mapActions({
            addNewProperty: ADD_NEW_PROPERTY,
            fetchAllUsersForCompany: FETCH_ALL_USERS_FOR_COMPANY,
            searchProperties: SEARCH_PROPERTIES_ACTION,
        }),
        async addListing() {
            const success = await this.addNewProperty({
                url: this.url,
                ...this.propertyForm,
            })

            if (success) {
                Object.assign(this.$data, getDefaultData());
            }
        },
        async generateData() {
            if (this.url.length === 0) {
                this.urlError = 'The URL Field is required';
                return;
            }

            if (!validURL(this.url)) {
                this.urlError = 'The URL is not valid';
                return;
            }

            this.generateDataAsync = true;

            try {
                const data = await generateDataForPropertyUrl(this.url);

                if (!data.name) {
                    this.$toast.error('Error. Please try again', {
                        position: 'top-right',
                    });

                    this.generateDataAsync = false;

                    return;
                }

                const transformedData = {
                    name: data.name || '',
                    description: data['og:description'] || null,
                    image: data['og:image'] || null,
                    price: data['product:price:amount'] || null,
                    currency: data['product:price:currency'] || null,
                    url: data['og:url'] || this.url || null,
                };

                this.propertyForm = transformedData;

                this.step = 2;
            } catch (ex) {
                if (ex.json) {
                    const errorRes = await ex.json();

                    if (errorRes.detail === "ERR_FAILED_TO_GENERATE_PROPERTY_DATA") {
                        this.step = 2;
                        this.urlError = 'There was an error while generating data for this property. You can continue manually.';
                        this.propertyForm = {
                            name: '',
                            description: null,
                            image: null,
                            price: null,
                            currency: null,
                            url: this.url || null,
                        }
                    } else {
                        this.urlError = 'Unknown Error';
                    }
                } else {
                    this.urlError = 'Unknown Error';
                }
            }

            this.generateDataAsync = false;
        },
    },

    watch: {
        url: function (newUrl, oldUrl) {
            if (oldUrl.length === 0 && this.url.length > 3) {
                this.generateData();
            }
        },
    },
};
</script>

<style lang="scss">
.add-new-property {
    .add-search-container {
        .search-bar {
            .search-button {
                .icon {

                    font-size: 16px;
                    color: #ffffff;
                }
            }
        }
    }
}
</style>

<style scoped lang="scss">

.add-new-property {
    flex: 1;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: #1E2234;


    .add-search-container {
        height: 46px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .search-bar {
            padding: 0px;
        }


    }

    .add-listing-btn,
    .add-new-property-btn {
        background-color: transparent;
        border: 1px solid #13A68A;


        &:focus {
            color: #15d0a2;
        }
    }

    .generate-async-container {
        margin-top: 21px;
        max-width: 70px;
        min-width: 70px;
        display: flex;
        align-self: center;
        justify-content: center;
    }

    .add-property-form {
        max-height: 150px;
        transition: all 300ms ease-in-out;
        margin-top: 22px;
        display: flex;

        .input-container {
            .title {
                color: #13A68A;
            }
        }

        &.collapsed {
            max-height: 0px;
            overflow: hidden;
        }

        .step-1-content {
            opacity: 0.3;
        }

        .step-2 {
            opacity: 0.3;
        }

        .step-1,
        .step-2 {
            pointer-events: none;
            transition: all 300ms ease-in-out;

            &.active {
                opacity: 1;
                pointer-events: initial;
            }
        }


        .step-1 {
            width: 25%;

            &.active {
                .step-1-content {
                    opacity: 1;
                }

                .explanation-div {
                    .connector-line-half-1 {
                        opacity: 1;
                    }

                    .connector-line-half-2 {
                        opacity: 0.3;
                    }
                }
            }

            .explanation-div {
                .connector-line-half-1 {
                    opacity: 0.3;
                }

                .connector-line-half-2 {
                    width: 35px;
                    border-right: unset;
                    border-bottom-right-radius: 0px;
                }
            }
        }

        .step-2 {
            width: 75%;

            .explanation-div {
                .connector-line-half-1 {
                    border-left: unset;
                    border-bottom-left-radius: 0px;
                }
            }
        }

        .explanation-div {
            height: 72px;
            display: flex;
            flex-direction: row;

            .explanation {
                color: #13A68A;
                font-weight: 500;
                font-size: 13px;
                position: absolute;
                bottom: -25px;
                left: 0;
                right: 0;
                text-align: center;
            }

            .connector-line-half-2,
            .connector-line-half-1 {
                height: 32px;
                margin-top: 12px;
                transition: all 300ms ease-in-out;
                position: relative;

                border-bottom: 1px solid #13A68A;
                border-left: 1px solid #13A68A;
                border-right: 1px solid #13A68A;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
            }

            .connector-line-half-1 {
                flex: 1;
            }

            .connector-line-half-2 {
                width: 90px;
            }
        }

        .step-2-content,
        .step-1-content {
            transition: all 300ms ease-in-out;
            justify-content: space-between;
            display: flex;
            flex-direction: row;

            .input-container {
                flex: 1;
            }
        }
    }

    .input-aligned-btn {
        margin-top: 21px;
        min-width: 70px;
        max-width: 180px;

        &.is-loading {
            &:before {
                background-color: transparent;
            }
        }
    }

    .input-container {
        margin-left: 1%;
        margin-right: 1%;

        .title {
            font-size: 12px;
            color: #748AA1;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 6px;
            letter-spacing: 1px;
        }

        .el-select {
            width: 100%;
        }
    }

    .errors-container {
        .error {
            font-size: 14px;
            color: indianred;
        }
    }
}

</style>
