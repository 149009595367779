<template>
    <div class="header">
        <div class="left">
            <img style="width: 140px" v-if="lightMode" src="../../assets/img/logoRE_light.svg"/>
            <img style="height: 48px" v-else src="../../assets/img/logoRE_dark.svg"/>
        </div>
        <div class="right">
            <profile-dropdown/>
        </div>
    </div>
</template>

<script>
import ProfileDropdown from './components/ProfileSidebar/ProfileSidebar';

export default {
    name: 'Header',
    components: { ProfileDropdown },
    computed: {
        lightMode() {
            return this.$store.state.preferences.isLightMode;
        },
    },
};
</script>

<style lang="scss">

.theme-light {
    div.header {
        background-color: #F4F7FC;
        border-bottom: 1px solid #E5E9F2;
    }
}

.theme-dark {
    div.header {
        background-color: #12131F;
    }
}
</style>

<style lang="scss">

.header {
    padding-left: 21px;
    padding-right: 16px;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    transition: background-color 300ms ease-in-out;
}

</style>
