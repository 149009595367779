<template>
    <div class="menu-item-group">
        <div class="title-container" @click="toggleCollapsed">
            <i class="icon" v-bind:class="icon"/>
            <span class="title" v-if="!menuCollapsed">{{ name }}</span>
        </div>
        <div class="items-container">
            <menu-item v-for="item in items"
                       :selected="item.link === activeItemPath"
                       :link="item.link"
                       :icon="item.icon"
                       :meta="item.meta"
                       :menuCollapsed="menuCollapsed"
                       :rightSideNumber="item.rightSideNumber"
                       :key="item.link"
                       :name="item.name"/>
        </div>
    </div>
</template>

<script>
import MenuItem from '../MenuItem/MenuItem';
import { SET_MENU_IS_COLLAPSED } from '@/store/actions/menu';
import { mapMutations } from 'vuex';

export default {
    name: 'MenuItemGroup',
    components: { MenuItem },
    computed: {
        activeItemPath() {
            return this.$store.state.route.path;
        },
    },
    methods: {
        ...mapMutations({
            setCollapsed: SET_MENU_IS_COLLAPSED,
        }),
        toggleCollapsed() {
            this.setCollapsed(!this.menuCollapsed);
        },
    },
    props: {
        name: String,
        icon: String,
        items: Array,
        menuCollapsed: Boolean,
    },
};
</script>

<style scoped lang="scss">

.menu-item-group {
    .title-container {
        height: 51px;
        display: flex;
        align-items: center;
        padding-left: 21px;

        .icon {
            color: #748AA1;
            font-size: 17px;
        }

        .title {
            padding-left: 8px;
            color: #748AA1;
            font-weight: 500;
            font-size: 15px;
        }
    }
}
</style>
