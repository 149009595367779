import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login/Login';
import AddNewListing from '../views/AddNewProperty/AddNewProperty';
import MyLeads from '../views/MyLeads/MyLeads';
import Companies from '../views/Companies/Companies';
import Users from '../views/Users/Users';
import Settings from '../views/Settings/Settings';
import UserSettings from '@/views/UserSettings/UserSettings';
import ArchivedProperties from '@/views/ArchivedProperties/ArchivedProperties';
import Keywords from "@/views/Keywords/Keywords";
import BugRoute from '@/views/BugRoute/BugRoute';
import LeadLanding from "@/views/LeadLanding/LeadLanding";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'MyLeads',
        component: MyLeads,
        meta: {
            transitionName: 'slide',
        },
    }, {
        path: '/login',
        name: 'Login',
        components: {
            login: Login,
        },
        meta: {
            transitionName: 'slide',
        },
    }, {
        path: '/listings',
        name: 'Listings',
        component: AddNewListing,
        meta: {
            transitionName: 'slide',
        },
    }, {
        path: '/archived-listings',
        name: 'Archived Listings',
        component: ArchivedProperties,
        meta: {
            transitionName: 'slide',
        },
    }, {
        path: '/companies',
        name: 'Companies',
        component: Companies,
        meta: {
            transitionName: 'slide',
            requires: {
                requiresSuperAdmin: true,
            },
        },
    }, {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            transitionName: 'slide',
            requires: {
                requiresSuperAdmin: true,
            },
        },
    }, {
        path: '/company-users',
        name: 'CompanyUsers',
        component: Users,
        meta: {
            transitionName: 'slide',
            requires: {
                requiresCompanyAdmin: true,
            },
        },
    }, {
        path: '/keywords',
        name: 'Keywords',
        component: Keywords,
        meta: {
            transitionName: 'slide',
            requires: {
                requiresSuperAdmin: true,
            },
        },
    }, {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            transitionName: 'slide',
            requires: {
                requiresCompanyAdmin: true,
            },
        },
    }, {
        path: '/user-settings',
        name: 'UserSettings',
        component: UserSettings,
        meta: {
            transitionName: 'slide',
        },
    }, {
        path: '/landing/property/:shortId',
        name: 'LeadLanding',
        components: {
            landing: LeadLanding
        },
        meta: {
            transitionName: 'slide',
        },
    }, {
        path: '/landing/keyword/:keyword',
        name: 'LeadLanding',
        components: {
            landing: LeadLanding
        },
        meta: {
            transitionName: 'slide',
        },
    },
    {
        path: '/test-sentry',
        name: 'TestSentry',
        component: BugRoute,
        meta: {
            transitionName: 'slide',
        },
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
