<template>
    <span>
        <el-button v-tooltip="'Generate QR code'"
                   class="open-button"
                   type="text"
                   @click.stop="visible = true">
            <i class="icon el-icon-full-screen"/>
        </el-button>

        <el-dialog :append-to-body="true"
                   :modal-append-to-body="true"
                   class="qr-generator-dialog-content"
                   title="QR Generator"
                   :visible.sync="visible">

            <div class="qr-generator-dialog">
                <canvas id="qr-canvas" v-if="visible"></canvas>
            </div>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
            </div>

            <span slot="footer">
                    <el-button type="text" class="close-btn" @click="visible = false">Close</el-button>
                    <el-button :loading="async"
                               type="text"
                               @click="onCopy">
                        Copy
                    </el-button>
                    <el-button :loading="async"
                               class="download-btn"
                               type="text"
                               @click="onDownload">
                        Download
                    </el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>

import QRCode from 'qrcode';
import Vue from "vue";

const getDefaultProperty = () => ({
    id: '',
    name: '',
    keyword: '',
    url: '',
});

export default {
    name: 'QrGeneratorDialog',
    components: {},
    data() {
        return {
            visible: false,
        };
    },
    props: {
        property: {
            type: Object,
            default: getDefaultProperty(),
        },
    },
    methods: {
        onDownload() {
            const canvas = document.getElementById('qr-canvas')

            if (canvas) {
                const dataUrl = canvas.toDataURL();
                const a = document.createElement("a");
                a.href = dataUrl;
                a.download = this.qrText;
                a.click();
            }
        },
        onCopy() {
            try {

                const canvas = document.getElementById('qr-canvas')

                canvas.toBlob(blob => {
                    if (blob) {
                        if (window.ClipboardItem) {
                            const item = new window.ClipboardItem({ "image/png": blob });
                            navigator.clipboard.write([item]);
                        } else {
                            Vue.$toast.open({
                                message: "This browser does not support ClipboardAPI for writing images to clipboard.",
                                type: 'error',
                                position: 'top-right',
                            });
                        }
                    } else {
                        Vue.$toast.open({
                            message: "Cannot copy image.",
                            type: 'error',
                            position: 'top-right',
                        });
                    }
                })

            } catch (error) {
                console.error(error);
            }
        },
    },
    computed: {
        errors() {
            return this.$store.state.properties.editErrors;
        },
        async() {
            return this.$store.state.properties.editAsync;
        },
        isSuperAdmin() {
            return this.$store.getters.isSuperAdmin;
        },
        me() {
            return this.$store.getters.me;
        },
        user() {
            if (this.isImpersonationActive) {
                return this.impersonatedUser;
            }

            return this.me;
        },
        qrText() {
            let text = `sms:${this.property?.keyword?.name}`;

            const companyPhone = this.user?.company?.phone;

            if (companyPhone) {
                text += (':' + companyPhone);
            }

            return text;
        }
    },
    watch: {
        visible: function (val) {
            if (val === true) {
                this.$nextTick(() => {

                    const canvas = document.getElementById('qr-canvas')

                    QRCode.toCanvas(canvas, this.qrText, { width: 100, height: 100 }, function (error) {
                        if (error) console.error(error)
                    })
                })
            }

        }
    },
    mounted() {

    },
};
</script>

<style lang="scss">

.qr-generator-dialog {
    display: flex;
}
</style>

<style lang="scss" scoped>

.qr-generator-dialog-content {
    text-align: left !important;

    &::v-deep .el-dialog__footer {
        display: flex;

        > span {
            display: flex;
            width: 100%;
        }
    }

    .close-btn {
        margin-right: auto;
        color: #b5b7ba;
        transition: all 300ms ease-in-out;

        &:hover {
            color: #13A68A;
        }
    }

    .copy-btn {
        margin-right: 18px;
    }

    #qr-canvas {
        margin: auto;
    }
}

.open-button {
    color: #748AA1;
    font-size: 16px;

    .icon {
        font-size: 20px;
        transition: all 300ms ease;

        &:hover {
            color: #13A68A;
        }
    }

    &:hover {
        color: #748AA1;
    }
}

</style>
