import Vue from 'vue';
import Vuex from 'vuex';
import menu from './modules/menu';
import preferences from './modules/preferences';
import login from './modules/login';
import leads from './modules/leads';
import properties from './modules/properties';
import companies from './modules/companies';
import messenger from './modules/messenger';
import notifications from './modules/notifications';
import users from './modules/users';
import ui from './modules/ui';
import company_settings from './modules/settings/company_settings';
import app from './modules/app';
import templates from '@/store/modules/templates';
import keywords from "@/store/modules/keywords";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        companies,
        menu,
        preferences,
        login,
        templates,
        leads,
        properties,
        messenger,
        notifications,
        users,
        ui,
        company_settings,
        keywords
    },
});
