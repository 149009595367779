<template>
    <div class="select-container">
        <label>
            <span v-if="showLabel" :class="{required: required}">{{ name }}</span>
            <el-select v-model="inputVal"
                       :disabled="disabled"
                       :popper-class="poperClass"
                       :placeholder="placeholder || name">
                <slot></slot>
            </el-select>
        </label>
    </div>
</template>

<script>
export default {
    name: 'LabeledSelect',
    props: {
        name: String,
        showLabel: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: [String, Number],
        placeholder: String,
        poperClass: String
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style lang="scss">
.select-container {
    padding-top: 10px;
    padding-bottom: 10px;

    label {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        letter-spacing: 1px;
        color: #748aa1;
        text-transform: uppercase;

        span {
            &.required:after {
                content: " *";
                color: indianred;
            }
        }

        .el-input {
            margin-top: 6px;

            input {
                border-radius: 4px;
                height: 100%;
                background-color: #12131F !important;
                font-size: 14px;
                line-height: 20px;
                border: 2px solid #3d414b;
                color: #E5E9F2;
                padding: 12px 14px;
                outline: unset;
                transition: all 300ms ease-in-out;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    border: 2px solid #3d414b;
                    -webkit-text-fill-color: #E5E9F2;
                    -webkit-box-shadow: 0 0 0px 1000px #282E36 inset;
                    transition: background-color 5000s ease-in-out 0s;
                }


                &::placeholder {
                    color: #748AA1;
                }

                &:focus,
                &:active,
                &:hover {
                    border: 2px solid #13A68A;
                }
            }
        }
    }
}

.el-select-dropdown {

    .el-select-dropdown__item.selected {
        color: #13A68A;
    }
}

</style>
