<template>
    <span>
        <el-button @click="visible = true"
                   :disabled="editDisabled"
                   type="text"
                   v-tooltip="'Edit Keyword'"
                   style="margin-left: 8px">
            <i class="icon-actions_edit" style="font-size: 16px"/>
        </el-button>

        <el-dialog title="Edit Keyword"
                   :visible="visible"
                   @close="visible=false">
            <div class="edit-keyword-form" v-if="!fetchAsync">
                <labeled-input name="Keyword" v-model="form.name"/>
            </div>
            <div class="async-container" v-else>
                <vue-loaders-line-scale/>
            </div>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
            </div>

            <span slot="footer">
                <el-button type="text" @click="visible = false">Close</el-button>
                <el-button :loading="submitAsync"
                           type="text"
                           @click="onSubmit">
                    Submit
                </el-button>
            </span>
        </el-dialog>
    </span>
</template>

<script>
import { mapActions } from 'vuex';
import { EDIT_KEYWORD_ACTION } from '@/store/actions/keywords';
import LabeledInput from "@/components/LabeledInput/LabeledInput";
import { cloneDeep } from "lodash";
import { KEYWORD_STATUS_OPTIONS } from "@/store/modules/keywords";

export default {
    components: { LabeledInput },
    name: 'EditKeyword',
    props: {
        companyId: String,
        keyword: Object,
    },
    data() {
        return {
            visible: false,
            form: cloneDeep(this.keyword)
        };
    },
    computed: {
        submitAsync() {
            return this.$store.state.keywords.editAsync;
        },
        fetchAsync() {
            return this.$store.state.users.async;
        },
        errors() {
            return this.$store.state.keywords.editErrors;
        },
        editDisabled() {
            return this.keyword.status === KEYWORD_STATUS_OPTIONS.USED;
        }
    },
    methods: {
        ...mapActions({
            editKeywordAction: EDIT_KEYWORD_ACTION,
        }),
        onSubmit() {
            this.editKeywordAction({ id: this.keyword.id, name: this.form.name }).then((r) => {
                if (r) {
                    this.visible = false;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.async-container {
    display: flex;
    justify-content: center;
}
</style>
