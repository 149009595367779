export const MESSAGE_STATUS_UPDATED = 'SOCKET_message_status_updated';
export const INBOUND_MESSAGE = 'SOCKET_inbound_message';
export const OUTBOUND_MESSAGE = 'SOCKET_outbound_message';
export const LEAD_UPDATED = 'SOCKET_lead_updated';
export const LEAD_CREATED = 'SOCKET_lead_created';
export const LEAD_ARCHIVED = 'SOCKET_lead_archived';
export const LEAD_UNARCHIVED = 'SOCKET_lead_unarchived';
export const PROPERTY_INTEREST_UPDATED = 'SOCKET_property_interest_updated';
export const PROPERTY_INTEREST_CREATED = 'SOCKET_property_interest_created';
export const PROPERTY_ARCHIVED = 'SOCKET_property_archived';
export const PROPERTY_DELETED = 'SOCKET_property_deleted';
export const PROPERTY_UNARCHIVED = 'SOCKET_property_unarchived';
export const PROPERTY_AGENT_CHANGED = 'SOCKET_property_agent_changed'
export const BROWSER_NOTIFICATION = 'SOCKET_browser_notification';
export const AGENT_UPDATED = 'SOCKET_agent_updated';