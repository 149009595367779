<template>
    <div class="users-companies-list">
        <search-bar/>
        <el-tabs v-model="activeTab" @tab-click="handleTabSelect" v-if="!companiesAsync">
            <el-tab-pane label="Companies" name="user-companies-list-tab">
                <div class="users-companies-list-scroll">
                    <users-companies-list-item :key="company.id"
                                               @click="selectCompany"
                                               :selected="company.id === selectedCompanyId"
                                               :company="company"
                                               v-for="company in allCompanies"/>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="async-container" v-else>
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { FETCH_ALL_COMPANIES_ACTION, SET_SELECTED_COMPANY } from '../../../../store/actions/companies';
import { mapActions, mapMutations } from 'vuex';
import UsersCompaniesListItem from './UsersCompaniesListItem/UsersCompaniesListItem';

export default {
    name: 'UsersCompaniesList',
    components: { UsersCompaniesListItem, SearchBar },
    data() {
        return {
            activeTab: 'user-companies-list-tab',
        };
    },
    mounted() {
        if (!this.hasCompanies) {
            this.fetchCompanies();
        }
    },
    methods: {
        ...mapMutations({
            selectCompany: SET_SELECTED_COMPANY
        }),
        ...mapActions({
            fetchCompanies: FETCH_ALL_COMPANIES_ACTION,
        }),
        handleTabSelect() {

        },
    },
    computed: {
        companiesAsync() {
            return this.$store.state.companies.async;
        },
        allCompanies() {
            return this.$store.getters.allCompanies;
        },
        selectedCompanyId() {
            return this.$store.state.companies.selectedCompanyId;
        },
        hasCompanies() {
            return this.$store.getters.hasCompanies;
        },
    },
};
</script>

<style lang="scss" scoped>

.users-companies-list {
    width: 33%;
    min-width: 335px;
    display: flex;
    flex-direction: column;
    background-color: #12131F;

    #pane-user-companies-list-tab {
        min-height: 0;
        overflow: auto;
    }

    .users-companies-list-scroll {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

</style>
