<template>
    <div class="multi-details">
        <span class="title">Send message to selected customers</span>
        <div class="customers-list">
            <div class="customer"
                 v-for="lead in multiSelectedLeads"
                 :key="lead.id">
                <span
                    v-if="Boolean(lead.first_name) ||  Boolean(lead.middle_name) || Boolean(lead.last_name)">{{
                        lead | mergeName
                    }}</span>
                <span v-else>
                    {{ lead.phone | phone }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MultiDetails',
    computed: {
        ...mapGetters([
            'multiSelectedLeads',
        ]),
    },
};
</script>

<style scoped lang="scss">
.multi-details {
    color: #FFFFFF;
    width: 45%;
    height: 100%;
    background-color: #1E2234;
    display: flex;
    flex-direction: column;
    padding: 34px 36px;

    .title {
        font-size: 16px;
        color: #007AFF;
    }

    .customers-list {
        margin-top: 42px;
        overflow: auto;

        .customer {

        }
    }
}
</style>
