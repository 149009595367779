<template>
    <div class="app-layout">
        <Header/>
        <div class="main-content" :class="{blurred: isBlurred}">
            <Menu/>
            <transition mode="out-in"
                        :name="transitionName">
                <router-view/>
            </transition>
        </div>
    </div>
</template>

<script>
import Header from '../Header/Header';
import Menu from '../Menu/Menu';
import { userIsCompanyAdmin, userIsSuperAdmin } from '@/utils/utils';
import every from 'lodash/every';
import { FETCH_FRONTEND_REVISION_ACTION } from '@/store/actions/app';
import { mapActions } from 'vuex';
import { FETCH_LIST_FOR_IMPERSONATION } from '@/store/actions/login';

const DEFAULT_TRANSITION = 'fade';

export default {
    name: 'Layout',
    components: { Header, Menu },
    data() {
        return {
            transitionName: DEFAULT_TRANSITION,
        };
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            let transitionName = to.meta.transitionName || from.meta.transitionName;

            if (transitionName === 'slide') {
                const toDepth = to.path.split('/').length;
                const fromDepth = from.path.split('/').length;
                transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
            }

            this.transitionName = transitionName || DEFAULT_TRANSITION;

            let checks = [];

            if (to.meta.requires) {
                // we have checks to do before we switch
                const {
                    requiresSuperAdmin,
                    requiresCompanyAdmin,
                } = to.meta.requires;

                if (requiresSuperAdmin) {
                    checks.push(userIsSuperAdmin(this.user));
                }

                if (requiresCompanyAdmin) {
                    checks.push(userIsCompanyAdmin(this.user));
                }
            }

            if (every(checks)) {
                next();
            }
        });
    },
    methods: {
        ...mapActions({
            fetchFrontendRevisionAction: FETCH_FRONTEND_REVISION_ACTION,
            fetchListForImpersonation: FETCH_LIST_FOR_IMPERSONATION,
        }),
    },
    mounted() {
        this.fetchFrontendRevisionAction();
        if (userIsCompanyAdmin(this.user)) {
            this.fetchListForImpersonation();
        }
    },
    computed: {
        isBlurred() {
            return this.$store.state.ui.isSidebarOpened;
        },
        user() {
            return this.$store.state.login.user;
        },
    },
};
</script>

<style scoped lang="scss">

.theme-dark {
    div.app-layout {
        background-color: #1E2234;
    }
}

.theme-light {
    div.app-layout {
        background-color: #fff;
    }
}

div.app-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: background-color 300ms ease-in-out;
}

div.main-content {
    flex: 1 1 0;
    display: flex;
    min-height: 0;
    transition: all 300ms ease-in-out;

    &.blurred {
        opacity: 0.7;
        filter: blur(3px);
    }
}

</style>

