<template>
    <div class="page login">
        <div class="login-container">
            <img class="logo" v-if="lightMode" src="../../assets/img/logoRE_light.svg"/>
            <img class="logo" v-else src="../../assets/img/logoRE_dark.svg"/>

            <p class="welcome-back">Welcome Back!</p>
            <p class="sign-in-to-continue">Sign in to continue</p>

            <div class="inputs">
                <login-input v-model="email"
                             v-on:keyup.native.enter="submit"
                             name="Email"
                             autofill="username"
                             type="email"/>
                <login-input v-model="password"
                             v-on:keyup.native.enter="submit"
                             style="margin-top: 24px;"
                             autofill="password"
                             name="Password"
                             type="password"/>
            </div>

            <div class="errors">
                <p class="error" :key="error" v-for="error in errors">{{ error }}</p>
            </div>

            <p @click="forgotPassword" class="forgot-password">Forgot password?</p>
            <el-button :loading="async" class="login-btn" @click="submit">
                LOGIN
            </el-button>
        </div>
    </div>
</template>

<script>
import LoginInput from './components/LoginInput/LoginInput';
import { mapActions } from 'vuex';
import { PERFORM_LOGIN_ACTION } from '../../store/actions/login';

export default {
    name: 'Login',
    components: { LoginInput },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            email: '',
            password: '',
        };
    },
    computed: {
        errors() {
            return this.$store.state.login.errors;
        },

        async() {
            return this.$store.state.login.async;
        },
        lightMode() {
            return this.$store.state.preferences.isLightMode;
        },
    },
    methods: {
        ...mapActions({
            performLogin: PERFORM_LOGIN_ACTION,
        }),

        submit() {
            if (this.async) return;

            this.performLogin({
                email: this.email,
                password: this.password,
            });
        },

        forgotPassword() {
            alert('forgot password');
        },

        facebookLogin() {
            alert('login-facebook');
        },

        googleLogin() {
            alert('login Google');
        },
    },
};
</script>

<style scoped lang="scss">
.theme-dark {
    .login {
        color: #F4F7FC;
    }
}

.login {
    align-items: center;
    justify-content: center;
    position: relative;

    .login-container {

        .logo {
            margin-top: -100;
            margin-bottom: 26px;
            width: 160px;
        }

        .welcome-back {
            font-size: 22px;
            font-weight: 500;
        }

        .sign-in-to-continue {
            font-size: 14px;
            margin-top: 12px;
        }

        .or-use-email {
            margin-top: 24px;
            font-size: 12px;
            color: #748aa1;
        }

        .inputs {
            margin-top: 35px;

            .input-container {
                width: 325px;
            }
        }

        .forgot-password {
            font-size: 14px;
            line-height: 22px;
            color: #B8C5D3;
            margin-top: 20px;
            cursor: pointer;
        }

        .login-btn {
            padding: 15px 35px;
            color: #ffffff;
            background-color: #13A68A;
            border-radius: 12px;
            outline: none;
            font-weight: 500;
            border: 1px solid #13A68A;
            cursor: pointer;
            margin-top: 35px;

            &:hover {
                background-color: #05c696;
                border: 1px solid #05c696
            }
        }

        .errors {
            .error {
                color: #F64B5D;
                font-size: 14px;
                margin-top: 12px;
            }
        }
    }
}
</style>
