<template>
    <div class="list-container">
        <div class="all-leads-tab">
            <lead-list-item v-for="lead in allLeads"
                            @on-click="onClickListItem"
                            :lead="lead"
                            :key="lead.id"/>

            <div class="nothing-here" v-if="!hasLeads">
                <span>Nothing Here</span>
            </div>
        </div>
    </div>
</template>

<script>
import LeadListItem from '../components/LeadListItem/LeadListItem';
import { mapActions, mapGetters } from 'vuex';
import { SELECT_LEAD_ACTION } from '@/store/actions/leads';

export default {
    name: 'AllLeadsTab',
    components: { LeadListItem },
    methods: {
        ...mapActions({
            selectLead: SELECT_LEAD_ACTION,
        }),
        onClickListItem(leadId) {
            this.selectLead(leadId);
        },
    },
    computed: {
        ...mapGetters([
            'hasLeads',
            'allLeads',
        ]),
    },
};
</script>

<style scoped lang="scss">
.all-leads-tab {
    color: #FFFFFF;

    .nothing-here {
        font-size: 15px;
        color: #4c545d;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
