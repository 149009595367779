<template>
    <div class="add-edit-user-dialog">
        <el-dialog :title="title" :visible="visible" @close="closeModal">
            <div class="add-edit-user-form">
                <labeled-input required name="Name" v-model="form.name"/>
                <labeled-input autofill="off" required name="Password" type="password" v-model="form.password"/>
                <labeled-input required name="Email" type="email" v-model="form.email"/>
                <labeled-input name="Phone" type="tel" v-model="form.phone"/>
                <labeled-select v-if="isSuperAdmin" name="Role" required="true" v-model="form.user_role">
                    <el-option :key="role"
                               :label="role | role"
                               v-for="role in ROLES"
                               :value="role">
                        {{ role | role }}
                    </el-option>
                </labeled-select>
            </div>

            <div class="errors-container" style="margin-top: 24px;">
                <p class="error" v-for="error in errors" :key="error">{{ error }}</p>
            </div>

            <span slot="footer">
                <el-button type="text" @click="$emit('update:visible', false)">Close</el-button>
                <el-button :loading="async"
                           type="text"
                           @click="onSubmit">
                    Submit
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import cloneDeep from 'lodash/cloneDeep';
import LabeledInput from '../../../../components/LabeledInput/LabeledInput';
import { difference, getFullNameFromParts, splitNameIntoParts } from '@/utils/utils';
import { ADD_NEW_USER_ACTION, EDIT_USER_ACTION } from '@/store/actions/users';
import { mapActions } from 'vuex';
import LabeledSelect from '@/components/LabeledSelect/LabeledSelect';
import { ROLES } from '@/utils/consts';

function getDefaultUser() {
    return {
        name: '',
        first_name: '',
        middle_name: null,
        last_name: '',
        company: null,
        company_id: null,
        is_active: true,
        email: '',
        phone: null,
        user_role: "ROLE_USER",
        password: '',
    };
}

function transformName(user) {
    user.name = getFullNameFromParts(user);

    return user;
}

export default {
    name: 'AddEditUserDialog',
    components: { LabeledSelect, LabeledInput },
    data() {
        return {
            form: transformName(cloneDeep(this.user)),
        };
    },
    watch: {
        visible(newVisible) {
            if (newVisible === true) {
                this.form = transformName(cloneDeep(this.user));
            }
        },
    },
    props: {
        visible: Boolean,
        user: {
            type: Object,
            default: getDefaultUser,
        },
    },
    methods: {
        ...mapActions({
            addNewUser: ADD_NEW_USER_ACTION,
            editUser: EDIT_USER_ACTION,
        }),

        closeModal() {
            this.$emit('update:visible', false);
        },

        onSubmit() {
            let submitData = cloneDeep(this.form);

            submitData = { ...submitData, ...splitNameIntoParts(this.form.name) };

            delete submitData.name;

            if (this.isEditMode) {
                this.editUser({ id: this.user.id, ...difference(submitData, this.user) }).then(() => {
                    this.$nextTick(function () {
                        this.form = transformName(cloneDeep(this.user));
                        this.closeModal();
                    });
                });
            } else {
                submitData.company_id = this.selectedCompanyId;
                submitData.company = this.selectedCompanyId;

                this.addNewUser({ user: submitData }).then(() => {
                    this.form = getDefaultUser();
                    this.closeModal();
                });
            }
        },
    },
    computed: {
        title() {
            if (this.isEditMode) {
                return 'Edit User';
            } else {
                return 'Add User';
            }
        },
        ROLES() {
            return ROLES;
        },
        allCompanies() {
            return this.$store.getters.allCompanies;
        },
        selectedCompanyId() {
            return this.$store.getters.selectedCompanyId;
        },
        isSuperAdmin() {
            return this.$store.getters.isSuperAdmin;
        },
        isEditMode() {
            return Boolean(this.user.id);
        },
        errors() {
            return this.$store.state.users.addEditErrors;
        },
        async() {
            return this.$store.state.users.addEditAsync;
        },
    },
};
</script>

<style lang="scss" scoped>
.add-edit-user-dialog {
}
</style>
