export const FETCH_ALL_USERS_FOR_COMPANY = 'FETCH_ALL_USERS_FOR_COMPANY';
export const FETCH_USERS_FOR_COMPANY_PAGE = 'FETCH_USERS_FOR_COMPANY_PAGE';
export const SET_USERS_ASYNC = 'SET_USERS_ASYNC';
export const SET_USERS_ERRORS = 'SET_USERS_ERRORS';
export const SET_USERS = 'SET_USERS';
export const APPEND_USERS_PAGE = 'APPEND_USERS_PAGE';
export const RESET_USERS_STATE = 'RESET_USERS_STATE';
export const ENABLE_DISABLE_USER_ACTION = 'ENABLE_DISABLE_USER_ACTION';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_NEW_USER_ACTION = 'ADD_NEW_USER_ACTION';
export const EDIT_USER_ACTION = 'EDIT_USER_ACTION';
export const SET_ADD_EDIT_USER_ASYNC = 'SET_ADD_EDIT_USER_ASYNC';
export const SET_ADD_EDIT_USER_ERRORS = 'SET_ADD_EDIT_USER_ERRORS';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const CHANGE_USER_PROFILE_PICTURE = 'CHANGE_USER_PROFILE_PICTURE';
export const FETCH_ALL_USERS_ACTION = 'FETCH_ALL_USERS_ACTION';
export const FETCH_USERS_PAGE_ACTION = 'FETCH_USERS_PAGE_ACTION';
export const SET_AWAY_MODE_ACTION = 'SET_AWAY_MODE_ACTION';