<template>
    <div class="lead-list-header">
        <div class="multiselect-checkbox">
            <el-checkbox v-tooltip="'Multiple select'" :value="isLeadsMultiselectActive"
                         @change="handleMultiselectCheckbox"/>

            <div @click="handleSelectAll" class="select-all-btn" v-if="isLeadsMultiselectActive">
                Select All
            </div>
        </div>

        <div class="right">
            <lead-list-header-filters :is-archived="isArchived"/>

            <div class="lead-multiselect-actions" v-if="isLeadsMultiselectActive">
                <lead-multi-select-actions :is-archived="isArchived"/>
            </div>
            <div class="lead-multiselect-spacefiller" v-else/>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { SET_LEADS_MULTI_SELECT, SET_MULTISELECT_ALL } from '@/store/actions/leads';
import LeadMultiSelectActions
    from '@/views/MyLeads/components/LeadsList/components/LeadListHeader/LeadMultiSelectActions';
import LeadListHeaderFilters
    from '@/views/MyLeads/components/LeadsList/components/LeadListHeader/LeadListHeaderFilters.vue';

export default {
    name: 'LeadListHeader',
    components: { LeadListHeaderFilters, LeadMultiSelectActions },
    props: {
        isArchived: {
            type: Boolean,
            default: false,
        },
        isImportant: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapMutations({
            setLeadsMultiselect: SET_LEADS_MULTI_SELECT,
            setMultiselectAll: SET_MULTISELECT_ALL,
        }),
        handleMultiselectCheckbox(val) {
            this.setLeadsMultiselect(val);
        },
        handleSelectAll() {
            this.setMultiselectAll({ select: true, ids: this.ids });
        },
    },
    computed: {
        ...mapGetters([
            'isLeadsMultiselectActive',
            'importantLeads',
            'archivedLeads',
            'allLeads',
        ]),
        ids() {
            if (this.isArchived) {
                return this.archivedLeads.map(lead => lead.id);
            } else {
                if (this.isImportant) {
                    return this.importantLeads.map(lead => lead.id);
                } else {
                    return this.allLeads.map(lead => lead.id);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.lead-list-header {
    height: 54px;
    background: #181B29;
    display: flex;
    align-items: center;
    padding: 0px 16px 0px 36px;
    justify-content: space-between;

    .right {
        display: flex;

        .lead-multiselect-spacefiller {
            min-width: 23px;
            max-width: 23px;
        }
    }

    .multiselect-checkbox {
        display: flex;

        .select-all-btn {
            cursor: pointer;
            color: #007AFF;
            margin-left: 32px;
            user-select: none;
        }

        i {
            padding-left: 8px;
            color: #F4F7FC;
            font-size: 8px;
            opacity: 0.5;
        }
    }

}
</style>
