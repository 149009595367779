<template>
    <div class="leads-list">
        <div class="leads-list-header">
            <!--            <div v-tooltip="'Add Manual Lead'" class="new-btn">-->
            <!--                <i class="icon icon-close" style="transform: rotate(45deg)"/>-->
            <!--            </div>-->

            <add-manual-lead-modal/>

            <search-bar @change="onSearchChange"/>
        </div>
        <el-tabs :stretch="true" v-model="activeTab" @tab-click="handleTabSelect" v-if="!leadsAsync">
            <el-tab-pane label="All Messages" name="all-leads">
                <lead-list-header/>
                <all-leads-tab/>
            </el-tab-pane>

            <el-tab-pane name="important">
                <span slot="label">
                    <i class="icon icon-star_filed"/> Important
                </span>
                <lead-list-header :is-important="true"/>
                <important-tab/>
            </el-tab-pane>

            <el-tab-pane label="Archived" name="archived">
                <lead-list-header :is-archived="true"/>
                <archived-tab/>
            </el-tab-pane>
        </el-tabs>
        <div class="async-container" v-else>
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>

import SearchBar from '../../../../components/SearchBar/SearchBar';
import AllLeadsTab from './tabs/AllLeads';
import ImportantTab from './tabs/Important';
import {
    FETCH_ALL_ARCHIVED_LEADS_ACTION,
    FETCH_ALL_LEADS_ACTION, MULTI_SELECT_LEAD,
    SEARCH_LEADS_ACTION, SELECT_LEAD,
    SET_LEADS_MULTI_SELECT,
} from '@/store/actions/leads';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ArchivedTab from '@/views/MyLeads/components/LeadsList/tabs/Archived';

import { throttle } from 'lodash';
import AddManualLeadModal from '@/views/MyLeads/components/LeadsList/components/AddManualLeadModal/AddManualLeadModal';
import LeadListHeader from '@/views/MyLeads/components/LeadsList/components/LeadListHeader/LeadListHeader';

export default {
    name: 'LeadsList',
    components: { LeadListHeader, AddManualLeadModal, ArchivedTab, ImportantTab, AllLeadsTab, SearchBar },
    data() {
        return {
            activeTab: 'all-leads',
        };
    },
    mounted() {
        if (!this.hasLeads) {
            this.fetchAllLeads();
        }
    },
    computed: {
        ...mapGetters([
            'hasLeads',
        ]),
        leadsAsync() {
            return this.$store.state.leads.async;
        },
        impersonate() {
            return this.$store.state.login.impersonate;
        },
    },
    methods: {
        onWindowFocused() {
            this.fetchAllLeads();
        },
        ...mapMutations({
            setLeadsMultiselect: SET_LEADS_MULTI_SELECT,
            selectLead: SELECT_LEAD,
            multiSelectLead: MULTI_SELECT_LEAD,
        }),
        ...mapActions({
            fetchAllLeads: FETCH_ALL_LEADS_ACTION,
            fetchAllArchivedLeads: FETCH_ALL_ARCHIVED_LEADS_ACTION,
            searchLeads: SEARCH_LEADS_ACTION,
        }),
        onSearchChange: throttle(function (text) {
            this.searchLeads({ searchText: text, isArchived: this.activeTab === 'archived' });
        }, 750),
        handleTabSelect(tab) {
            this.setLeadsMultiselect(false);
            this.selectLead("");
            this.multiSelectLead([]);

            if (tab.name === 'archived' && !this.archivedAsync) {
                this.fetchAllArchivedLeads();
            }
        },
    },
    watch: {
        impersonate() {
            this.fetchAllLeads();
        },
    },
};

</script>

<style lang="scss">
.leads-list {
    display: flex;
    flex-direction: column;

    .el-tabs__header {
        margin: unset;
    }

    .list-container {
        overflow-y: auto;
        flex: 1;
    }

    #pane-archived,
    #pane-important,
    #pane-all-leads {
        flex-direction: column;
    }
}
</style>

<style scoped lang="scss">

.theme-light {
    .leads-list {
        background-color: #ffffff;
    }
}

.leads-list {
    .leads-list-header {
        .search-bar {
            padding-bottom: 0px;
            padding-top: 0px;
        }
    }
}

.leads-list {
    width: 33%;
    background-color: #12131F;

    .leads-list-header {
        padding-left: 24px;
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .async-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
}

</style>
