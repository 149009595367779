<template>
    <div class="archived-properties-table-container">
        <div v-if="!async" class="table-container">
            <custom-table :data="properties"
                          class="archived-properties-table"
                          :fields="fields">
                <template v-slot:status="props">
                    <div class="status-container">
                        <property-status-dot :status="getActivePropertyStatus(props.item.status)"/>
                    </div>
                </template>
                <template v-slot:note="props">
                    <div class="note-container">
                        <div class="note">{{ props.item.note }}</div>
                    </div>
                </template>
                <template v-slot:createdat="props">
                    <span>{{ props.item.created_at | moment('MM-DD-YYYY') }}</span>
                </template>
                <template v-slot:actions="props">

                    <unarchive-property-dialog :property="props.item"/>

                    <el-button @click.stop="deleteProperty(props.item)"
                               v-tooltip.top-end="'Delete Property'"
                               type="text"
                               class="delete-property-btn"
                               style="margin-left: 16px;">
                        <i class="icon icon-actions_delete"/>
                    </el-button>

                </template>
            </custom-table>
        </div>
        <div v-else class="async-container">
            <vue-loaders-line-scale/>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import CustomTable from '@/components/CustomTable/CustomTable';
import { mapActions, mapGetters } from 'vuex';
import {
    DELETE_PROPERTY,
    FETCH_ALL_ARCHIVED_PROPERTIES_ACTION,
} from '@/store/actions/properties';
import PropertyStatusDot from '@/components/PropertyStatusDot/PropertyStatusDot';
import UnarchivePropertyDialog from '@/views/ArchivedProperties/UnarchivePropertyDialog/UnarchivePropertyDialog';
import { FETCH_ALL_KEYWORDS_ACTION } from '@/store/actions/keywords';
import { getActivePropertyStatus } from "@/utils/utils";

function getFields(props) {
    const { isCompanyAdmin } = props;
    return [{
        name: 'created_at',
        title: 'Date Created',
        slot: 'createdat',
        width: '12%',
        sort: (a, b, direction) => {
            const dateA = new moment(a.created_at || 0).unix();
            const dateB = new moment(b.created_at || 0).unix();

            if (direction === 'up') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        },
    }, {
        name: 'status',
        title: 'Status',
        slot: 'status',
        width: '12%',
    }, {
        name: 'name',
        title: 'Address',
    }, {
        name: 'keyword',
        title: 'Keyword',
        accessor: 'keyword.name',
        width: '20%',
        sort: (a, b, direction) => {
            const aVal = a.keyword.toLowerCase();
            const bVal = b.keyword.toLowerCase();
            if (direction === 'up') {
                if (aVal < bVal)
                    return -1;
                if (aVal > bVal)
                    return 1;
            } else {
                if (aVal > bVal)
                    return -1;
                if (aVal < bVal)
                    return 1;
            }
            return 0;
        },
    }, {
        name: 'agent',
        title: 'Agent',
        slot: 'agent',
        width: '10%',
        disabled: !isCompanyAdmin,
    }, {
        name: 'note',
        title: 'Property Notes',
        slot: 'note',
    }, {
        name: 'actions',
        title: 'Actions',
        slot: 'actions',
        width: '10%',
        rowClass: 'actions',
        headerClass: 'actions',
    }];
}

export default {
    name: 'ArchivedPropertiesTable',
    components: { UnarchivePropertyDialog, PropertyStatusDot, CustomTable },
    data() {
        return {
            fields: getFields({ isCompanyAdmin: this.isCompanyAdmin }),
        };
    },
    watch: {
        isCompanyAdmin: function (isCompanyAdmin) {
            this.fields = getFields({ isCompanyAdmin: isCompanyAdmin });
        },
        impersonatedUser: function (oldImpersonate, newImpersonate) {
            if (oldImpersonate?.id !== newImpersonate?.id) {
                this.init();
            }
        },
    },
    methods: {
        ...mapActions({
            fetchAllArchivedProperties: FETCH_ALL_ARCHIVED_PROPERTIES_ACTION,
            deletePropertyAction: DELETE_PROPERTY,
            fetchAllKeywords: FETCH_ALL_KEYWORDS_ACTION,
        }),

        async deleteProperty(property) {
            const { value: accept } = await this.$swal.fire({
                title: 'Are you sure?',
                input: 'checkbox',
                text: 'Are you sure you want to delete this property permanently ?',
                icon: 'warning',
                inputValue: 0,
                inputPlaceholder:
                    'I understand that actions can\'t be undone?',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#F64B5D',
                cancelButtonColor: '#d8d8d8',
                confirmButtonText: 'Confirm',
                inputValidator: (result) => {
                    return !result && 'You need to check the disclaimer checkbox';
                },
            });

            if (accept) {
                this.deletePropertyAction(property.id).then(() => {
                    this.$swal({
                        text: 'Success',
                        icon: 'success',
                    });
                })
                    .catch(() => {
                        this.$swal({
                            text: 'Error',
                            icon: 'error',
                        });
                    });
            }
        },
        init() {
            this.fetchAllArchivedProperties();
            this.fetchAllKeywords();
        },
        getActivePropertyStatus
    },

    computed: {
        ...mapGetters({
            hasProperties: 'hasArchivedProperties',
            properties: 'archivedProperties',
            isCompanyAdmin: 'isCompanyAdmin',
            isImpersonationActive: 'isImpersonationActive',
            impersonatedUser: 'impersonatedUser',
        }),
        properties() {
            return this.$store.getters.archivedProperties;
        },
        async() {
            return this.$store.state.properties.archivedPropertiesAsync;
        },
    },
    mounted() {
        this.fields = getFields({ isCompanyAdmin: this.isCompanyAdmin });

        this.init();
    },
};
</script>

<style lang="scss">
.archived-properties-table-container {
    .archived-properties-table {
        .table-header-item-actions {
            justify-content: flex-end;
            display: flex;
        }

        .delete-property-btn {
            .icon {
                color: #748AA1;

                &:hover {
                    color: #FA5D5D;
                }
            }
        }
    }

    .status-container {
        .property-status-dot {
            .dot {
                font-size: 30px;
            }

            .status {
                margin-left: 5px;
                color: #b5b7ba;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.archived-properties-table-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow: hidden;

    .table-container {
        display: flex;
        flex: 1;
        overflow: hidden;
    }


    .icon {
        font-size: 20px;
        transition: all 300ms ease-in-out;

        &:hover {
            color: #13A68A;
        }
    }

    .note-container {
        padding-top: 8px;
        padding-bottom: 8px;

        .note {
            line-clamp: 2;
            white-space: normal;
            color: #ffffff;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            /* Needed to make it work */
            overflow: hidden;
        }
    }

    .status-container {
        display: flex;
        align-items: center;

    }
}
</style>
