import { companies, apiCall } from '../apiCall';

export async function fetchCompaniesPage(page = 0) {
    let url = new URL(companies);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', 30);

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function fetchCompanyById(companyId) {
    let url = new URL(`${companies}/${companyId}`);

    return apiCall({
        url: url.href,
        headers: {
            'accept': 'application/ld+json',
        },
    }).then(r => r.json());
}

export async function addNewCompany(company) {
    let url = new URL(companies);

    return apiCall({
        url: url.href,
        method: 'POST',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: company,
    }).then(r => r.json());
}

export async function deleteCompany(companyId) {
    let url = new URL(`${companies}/${companyId}`);

    return apiCall({
        url: url.href,
        method: 'DELETE',
        headers: {
            'accept': 'application/ld+json',
        },
    });
}

export async function editCompany(companyId, data) {
    let url = new URL(`${companies}/${companyId}`);

    return apiCall({
        url: url.href,
        method: 'PUT',
        headers: {
            'accept': 'application/ld+json',
        },
        jsonData: data,
    }).then(r => r.json());
}
