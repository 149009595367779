var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","name":_vm.isOutgoing ? 'slide-msg-left' : 'slide-msg-right'}},[_c('div',{staticClass:"message-container",class:{
        incoming: _vm.isIncoming,
        outgoing: _vm.isOutgoing,
        system: _vm.isSystem
    }},[(_vm.isIncoming)?_c('div',{staticClass:"message-creator"},[_vm._v(" "+_vm._s(_vm._f("mergeName")(_vm.selectedLead))+" ")]):_vm._e(),(_vm.isOutgoing)?_c('div',{staticClass:"message-creator"},[_vm._v(" "+_vm._s(_vm._f("mergeName")(_vm.messageAgent))+" ")]):_vm._e(),(_vm.isSystem)?_c('div',{staticClass:"message-creator"},[_vm._v(" SYSTEM ")]):_vm._e(),_c('div',{staticClass:"message-bubble",class:{
            incoming: _vm.isIncoming,
            outgoing: _vm.isOutgoing,
            error: _vm.isError,
            system: _vm.isSystem,
            'has-text': _vm.hasText,
            'has-attachment': _vm.hasAttachment
        }},[(_vm.hasAttachment)?_c('div',{staticClass:"attachment-preview"},[(_vm.isImgAttach)?_c('div',{staticClass:"image-preview"},[_c('img',{directives:[{name:"viewer",rawName:"v-viewer",value:({rotatable: false, scalable: false}),expression:"{rotatable: false, scalable: false}"}],attrs:{"src":_vm.attachUrl}})]):_vm._e(),(_vm.isVideoAttach)?_c('div',{staticClass:"video-preview"},[_c('video',{attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.attachUrl}})])]):_vm._e(),(_vm.isPdfAttach)?_c('div',{staticClass:"pdf-preview"},[_c('a',{staticClass:"pdf-icon",attrs:{"href":_vm.attachUrl,"target":"_blank"}},[_c('i',{staticClass:"icon icon-pdf_light"})]),_c('div',{staticClass:"attachment-url"},[_c('p',[_vm._v(_vm._s(_vm.attachmentFilename))])])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"message-content"},[_c('span',{directives:[{name:"linkified",rawName:"v-linkified"}]},[_vm._v(_vm._s(_vm.message.text))])])]),_c('div',{staticClass:"message-metadata-container"},[(_vm.shouldShowStatus)?_c('span',{staticClass:"message-status",class:{error: _vm.isError}},[_vm._v(" "+_vm._s(_vm.message.status)+" ")]):_vm._e(),(_vm.shouldShowStatus)?_c('span',{staticClass:"message-dash"},[_vm._v(" - ")]):_vm._e(),_c('span',{staticClass:"message-sent-at"},[_vm._v(" "+_vm._s(_vm._f("date_short_if_today")(_vm.message.created_at))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }