// import router from '../router';

import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { STORAGE_KEYS } from '@/utils/consts';
import { tryToParseImpersonationFromLocalStorage } from '@/utils/utils';
import * as Sentry from '@sentry/vue';

export const storage_url = process.env.VUE_APP_STORAGE_URL || 'http://localhost:8001';
export const endpoint = process.env.VUE_APP_ENDPOINT || 'http://localhost:8000';
export const ws_endpoint = process.env.VUE_APP_WS_ENDPOINT || 'https://ws-texting.trinitydev.biz';

export const login = `${endpoint}/api/login`;
export const me = `${endpoint}/api/me`;
export const properties = `${endpoint}/api/properties`;
export const leads = `${endpoint}/api/leads`;
export const property_interest = `${endpoint}/api/property_interests`;
export const inbox = `${endpoint}/api/inbox`;
export const companies = `${endpoint}/api/companies`;
export const agencySettings = `${endpoint}/api/agency_settings`;
export const users = `${endpoint}/api/users`;
export const message_templates = `${endpoint}/api/message_templates`;
export const impersonate_list = `${endpoint}/api/impersonate/list`;
export const keywords = `${endpoint}/api/keywords`;
export const notifications = `${endpoint}/api/notifications`;

export function handleErrors(response) {
    if (response.ok || response.status === 204) {
        return response;
    } else {
        throw response;
    }
}

export function apiCall(params = {
    url: '',
}, allowImpersonation = true) {
    const originalParams = cloneDeep(params);

    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

    const defaultParams = {
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
        body: null,
    };

    if (params.body) {
        delete defaultParams.headers['content-type'];
    }

    if (params.jsonData) {
        params.body = JSON.stringify(params.jsonData);
        delete params.jsonData;
    }

    if (token) {
        defaultParams.headers['Authorization'] = `Bearer ${token}`;
    }

    const actualParams = merge(defaultParams, params);

    const impersonate = tryToParseImpersonationFromLocalStorage();
    if (allowImpersonation && impersonate !== null) {
        if (!(params.url instanceof URL)) {
            params.url = new URL(params.url);
        }
        params.url.searchParams.append('impersonate', impersonate.email);
    }

    return new Promise((resolve, reject) => {
        return fetch(params.url, actualParams)
            .then(handleErrors)
            .then(resolve)
            .catch(async ex => {
                console.log(ex);

                if (ex.status === 401) {
                    if (ex.json) {
                        const message = await ex.json().then(data => {
                            return data.message;
                        });

                        if (message === 'Invalid credentials.') {
                            // this is login, propagate this error up
                            reject(message);
                        } else if (message === 'Expired JWT Token') {
                            // we should probs refresh token here
                            const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
                            if (refreshToken) {
                                // proceed
                                let data = new FormData();
                                data.append('refresh_token', refreshToken);
                                return fetch(endpoint + '/api/token/refresh', {
                                    method: 'post',
                                    body: data,
                                }).then(handleErrors)
                                    .then(r => r.json())
                                    .then(r => {
                                        localStorage.setItem(STORAGE_KEYS.TOKEN, r.token);
                                        localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, r.refresh_token);
                                        //redo api call
                                        apiCall(originalParams)
                                            .then(resolve);
                                    })
                                    .catch(() => {
                                        logOut();
                                    });
                            } else {
                                logOut();
                            }
                        } else if (message === 'An authentication exception occurred.') {
                            // USER PROBABLY GOT DISABLED
                            reject('User disabled.');
                        }
                    }
                }

                reject(ex);
            });
    });
}

export function logOut() {
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.IMPERSONATE);

    Sentry.setContext("user", null);
    Sentry.setContext("impersonate", null);

    window.location.reload();
}
