<template>
    <div class="input-container">
        <label>
            {{name}}
            <input :name="name"
                   v-model="inputVal"
                   :id="name"
                   :type="type"
                   :autofill="autofill"
                   :placeholder="name"/>
        </label>
    </div>
</template>

<script>
export default {
    name: 'LoginInput',
    props: {
        name: String,
        type: String,
        value: String,
        autofill: String
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style scoped lang="scss">
.input-container {
    label {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        letter-spacing: 1px;
        color: #748aa1;
        text-transform: uppercase;

        input {
            border-radius: 4px;
            margin-top: 6px;
            background-color: #1E2234 !important;
            font-size: 14px;
            line-height: 20px;
            border: 2px solid #3d414b;
            color: #E5E9F2;
            padding: 12px 14px;
            outline: unset;

            &:focus,
            &:active,
            &:-webkit-autofill:focus,
            &:hover {
                border: 2px solid #748AA1;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border: 2px solid #3d414b;
                -webkit-text-fill-color: #E5E9F2;
                -webkit-box-shadow: 0 0 0px 1000px #1E2234 inset;
                transition: background-color 5000s ease-in-out 0s;
            }

            &::placeholder {
                color: #748AA1;
            }
        }
    }
}
</style>
